import React from 'react';
import LoginService from '../../Service/LoginService';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { setAccountLogin, setProfileImg, showLoading, hideLoading } from '../../Redux/Action';
import { setUsersCookies } from '../../Service/CookieService';
import AccountService from '../../Service/AccountService';
import ForgetPassword from './ForgetPassword';
import queryString from 'query-string';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Account: '',
            Password: '',
            show: false,
            resetPassword: false,
            showResetPassword: [false, false],
            resetPassword1: '',
            resetPassword1Confirm: '',
            resetToken: ''
        }
    }

    componentDidMount() {
        this.props.dispatch(hideLoading());
        this.UsernameEl.focus();
        document.getElementById('modal-background-app').className = '';
        console.log(this.props);
        if (this.props.location) {
            var search = queryString.parse(this.props.location.search);
            if (search && search.resettoken) {
                console.log(search.resettoken);
                this.setState({ resetPassword: true, resetToken: search.resettoken });
            }
        }
    }

    handleKeyPress = (e) => {
        if (e.charCode === 13) {
            this.onLogin();
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value.trim()
        });
    }

    hideModal = (callback) => {
        (this.state.show === false) ?
            this.setState({
                show: true
            })
            : this.setState({
                show: false
            }, () => {
                if (typeof callback === 'function')
                    callback()
            });
    }

    forgetPassword = (Email) => {
        return AccountService.forgetPassword(Email);
    }

    onLogin = () => {
        if (this.state.Account === '' || this.state.Password === '') {
            Swal.fire({
                title: 'กรุณากรอก Account และ Password',
                type: 'warning'
            });
        }
        else {
            this.props.dispatch(showLoading());
            LoginService.Login(this.state.Account, this.state.Password)
                .then((result) => {
                    this.props.dispatch(hideLoading());
                    console.log(result)
                    if (result.errors) {
                        if (!result.errors[0]) return Promise.reject();
                        Swal.fire({
                            title: 'เข้าสู่ระบบไม่สำเร็จ',
                            type: 'error',
                            text: result.errors[0].Message
                        });
                        return false;
                    }
                    result = result.data.Login;
                    setUsersCookies(result.Email, result.Username, result.AccountID, result.Token);
                    this.props.dispatch(setAccountLogin({
                        Email: result.Email,
                        Username: result.Username,
                        AccountID: result.AccountID,
                        RoleID: result.RoleID,
                        Token: result.Token
                    }));
                    return result.AccountID;
                })
                .then((AccountID) => {
                    if (!AccountID) return;
                    AccountService.getProfileAccount(AccountID)
                        .then(resultProfile => {
                            if (resultProfile.errors) {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: resultProfile.errors[0].Message,
                                    type: 'error'
                                });
                                return false;
                            }
                            resultProfile = resultProfile.data.GetProfileAccount;
                            localStorage.setItem('profileImg', resultProfile.FileData)
                            this.props.dispatch(setProfileImg({
                                data: resultProfile.FileData
                            }));
                            return AccountID;
                        })
                })
                .then(AccountID => {
                    this.props.setDataLoaded(true);
                    this.props.getNotification(AccountID);
                })
                .catch((error) => {
                    this.props.dispatch(hideLoading());
                    console.log(error);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    });
                })
        }
    }

    showResetPassword = (index) => {
        var newShowPassword = [...this.state.showResetPassword];
        newShowPassword[index] = !newShowPassword[index];
        this.setState({ showResetPassword: newShowPassword });
    }

    checkResetPassword = () => {
        var title = '';
        var text = '';
        var type = null;
        if (this.state.resetPassword1 === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกรหัสผ่านใหม่';
            type = 'warning';
        }
        else if (this.state.resetPassword1Confirm === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกยืนยันรหัสผ่านใหม่';
            type = 'warning';
        }
        else if (this.state.resetPassword1.length < 8) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'รหัสผ่านต้องมีความยาวมากกว่า 8 ตัวอักษร';
            type = 'warning';
        }
        else if (this.state.resetPassword1 !== this.state.resetPassword1Confirm) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณายืนยันรหัสผ่านใหม่ให้ถูกต้อง';
            type = 'warning';
        }
        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    resetPassword = () => {
        if (this.checkResetPassword()) {
            this.props.dispatch(showLoading());
            AccountService.resetPassword(this.state.resetToken, this.state.resetPassword1)
                .then(result => {
                    this.props.dispatch(hideLoading());
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        });
                        return;
                    }
                    Swal.fire({
                        title: 'รีเซ็ตรหัสผ่านสำเร็จ',
                        text: 'ระบบได้ทำการรีเซ็ตรหัสผ่านให้กับท่านแล้ว กรุณาล็อคอินด้วยรหัสผ่านใหม่',
                        type: 'success'
                    })
                        .then(() => {
                            this.setState({
                                resetPassword: false,
                                showResetPassword: [false, false],
                                resetPassword1: '',
                                resetPassword1Confirm: '',
                                resetToken: ''
                            })
                        })
                })
                .catch(e => {
                    this.props.dispatch(hideLoading());
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    })
                })
        }
    }

    render() {
        return (
            <>
                <div id='login-parent-div'>
                    <div id='login-div' onKeyPress={this.handleKeyPress}>
                        <div>
                            <div className='form-group'>
                                <img src='/assets/logo-2.png' alt='login-logo' />

                                <div className={'Evaluation-div ' + ((this.state.resetPassword) ? '' : 'Evaluation-div-disable')}>

                                    <label className='form-label mt-2'>รีเซ็ตรหัสผ่าน</label>
                                    <div className='d-flex'>
                                        <div className='flex-fill'>
                                            <input
                                                value={this.state.resetPassword1}
                                                className='form-control'
                                                name='resetPassword1'
                                                placeholder='รหัสผ่านใหม่'
                                                onChange={e => this.onChange(e)}
                                                type={(this.state.showResetPassword[0]) ? 'text' : 'password'}
                                            />
                                        </div>
                                        <button
                                            className='btn btn-outline-secondary eye-button'
                                            onClick={() => this.showResetPassword(0)}
                                        >
                                            <i className={'fa fa-eye' + ((this.state.showResetPassword[0]) ? '-slash' : '')}></i>
                                        </button>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <div className='flex-fill'>
                                            <input
                                                value={this.state.resetPassword1Confirm}
                                                className='form-control'
                                                name='resetPassword1Confirm'
                                                placeholder='ยืนยันรหัสผ่านใหม่'
                                                onChange={e => this.onChange(e)}
                                                type={(this.state.showResetPassword[1]) ? 'text' : 'password'}
                                            />
                                        </div>
                                        <button
                                            className='btn btn-outline-secondary eye-button'
                                            onClick={() => this.showResetPassword(1)}
                                        >
                                            <i className={'fa fa-eye' + ((this.state.showResetPassword[1]) ? '-slash' : '')}></i>
                                        </button>
                                    </div>

                                    <button className='mt-3 btn btn-primary' onClick={this.resetPassword}>ยืนยัน</button>
                                </div>

                                <div className={'long-transition Evaluation-div ' + (!(this.state.resetPassword) ? '' : 'Evaluation-div-disable')}>
                                    <label className='form-label mt-2'>เข้าสู่ระบบ</label>
                                    <input className='form-control mt-2' placeholder="Username" name='Account' onChange={this.onChange} value={this.state.Account} ref={e => this.UsernameEl = e} />
                                    <input type='password' className='form-control mt-2' placeholder="Password" name='Password' onChange={this.onChange} value={this.state.Password} />
                                    <small className="form-text text-muted" onClick={this.hideModal} style={{ cursor: 'pointer' }}>
                                        ลืมรหัสผ่าน
                                            </small>
                                    <button type='button' className='btn btn-primary mt-2' onClick={this.onLogin}>เข้าสู่ระบบ</button>
                                </div>

                            </div>
                        </div>

                        <div className='text-center mt-4 text-secondary'>
                            <span style={{ fontSize: '0.63rem' }}>
                                © COPYRIGHT 2020 BY OFFICE OF THE NATIONAL WATER RESOURCE (ONWR)
                        </span>
                        </div>
                    </div>
                </div>
                <ForgetPassword
                    show={this.state.show}
                    hide={this.hideModal}
                    forgetPassword={this.forgetPassword}
                />
            </>
        );
    }
}

export default connect(null)(Login);