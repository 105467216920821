import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import Collapse from 'react-bootstrap/Collapse';
import DragandDropFile from './DragandDropFile';
import EvaluationManageEvaluateModal from './EvaluationManage_EvaluateModal';
import Pdf from './pdf';
import { checkMonth } from '../../../Utilities/Date';
import FroalaEditor from '../../../Utilities/FroalaEditor';
import RemarkRejectModal from './RemarkReject_modal';

class EvaluationResultModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            KPIName: '',
            KPIID: null,
            Description: '',
            Year: 0,
            Month: '',
            Status: 2,
            KPIMain: {},
            EvaluationCriteria: {},
            EvaluationType: {},
            Form: {
                Sections: []
            },
            Policy: {},
            Strategy: {},
            Schedule: {
                Period: []
            },
            KPIPersons: [],
            KPIOrganizations: [],
            Files: [],
            DelFiles: [],
            FormObjectResult: {
                KPIID: null,
                FormObjectItemResults: []
            },
            ProjectKPI: [],
            CanReport: false,
            currentSection: 0,
            currentSubSection: null,
            canUpdate: false,
            showPdf: false,
            RemarkReject: null,
            showRemarkReject: false
        }
    }

    componentDidMount() {
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onHideModal = () => {
        var title = 'ยกเลิกการรายงาน';
        var text = 'ต้องการยกเลิกการรายงานหรือไม่';
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then((res) => {
                if (res.value) {
                    this.props.hide(this.resetState);
                }
            })
    }

    resetState = () => {
        this.setState({
            KPIName: '',
            KPIID: null,
            Description: '',
            Year: 0,
            Month: '',
            Status: 2,
            KPIMain: {},
            EvaluationCriteria: {},
            EvaluationType: {},
            Form: {
                Sections: []
            },
            Policy: {},
            Strategy: {},
            Schedule: {
                Period: []
            },
            KPIPersons: [],
            KPIOrganizations: [],
            Files: [],
            DelFiles: [],
            FormObjectResult: {
                KPIID: null,
                FormObjectItemResults: []
            },
            ProjectKPI: [],
            CanReport: false,
            currentSection: 0,
            currentSubSection: null,
            canUpdate: false,
            showPdf: false,
            RemarkReject: null,
            showRemarkReject: false
        });
    }

    onEnterModal = (Month, Mode) => {
        console.log(Month)
        if (this.props.currentKPIID !== null) {
            this.props.getEvaluationResultDetail(this.props.currentKPIID, Month, Mode)
                .then(result => {
                    console.log(result)
                    if (result.errors) {
                        this.setState({ canUpdate: true });
                        return;
                    }

                    result = result.data.GetEvaluationResultDetail;
                    this.setState({
                        KPIName: result.KPIName,
                        KPIID: result.KPIID,
                        Description: result.Description,
                        Year: result.Year,
                        Month: result.Month,
                        Status: result.Status,
                        KPIMain: result.KPIMain,
                        EvaluationCriteria: { ...result.EvaluationCriteria },
                        EvaluationType: { ...result.EvaluationType },
                        Form: { ...result.Form },
                        Policy: { ...result.Policy },
                        Strategy: { ...result.Strategy },
                        KPIPersons: [...result.KPIPersons],
                        KPIOrganizations: [...result.KPIOrganizations],
                        Schedule: { ...result.Schedule },
                        FormObjectResult: {
                            KPIID: result.KPIID,
                            FormObjectItemResults: []
                        },
                        ProjectKPI: (result.ProjectKPI) ? [...result.ProjectKPI] : [],
                        Files: (result.KPIFiles) ? [...result.KPIFiles] : [],
                        DelFiles: [],
                        CanReport: result.CanReport,
                        canUpdate: true,
                        currentSection: 0,
                        currentSubSection: null,
                        RemarkReject: result.RemarkReject
                    }, () => {
                        this.setTextareaHeight();
                    })
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    });
                    this.setState({ canUpdate: true }, () => {
                        this.props.hide(this.resetState);
                    })
                })
        }
    }

    setTextareaHeight = () => {
        setTimeout(() => {
            var textareas = document.getElementsByTagName('textarea');
            for (var i = 0; i < textareas.length; i++) {
                var obj = textareas[i];
                obj.style.height = 'auto';
                console.log(obj.style.height);
                console.log(obj.scrollHeight);
                obj.style.height = obj.scrollHeight + 'px';
            }
        }, 100);
    }

    checkUpdate = (skipCheck) => {
        var title = 'เกิดข้อผิดพลาด';
        var text = 'เกิดข้อผิดพลาด';
        var type = null;
        if (
            this.state.FormObjectResult &&
            this.state.FormObjectResult.FormObjectItemResults &&
            this.state.FormObjectResult.FormObjectItemResults.length === 0 &&
            this.state.Status === 2 &&
            this.state.Files.length > 0
        ) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกผลการดำเนินงาน';
            type = 'warning';
        }
        if (type === null && this.state.Files.length > 0) {
            let maxSize = 500 * 1024 * 1024;
            let allFileSize = 0;
            for (let index = 0; index < this.state.Files.length; index++) {
                const element = this.state.Files[index];
                if (element.FileData) {
                    allFileSize += element.FileData.size || 0;
                    if (element.FileData.size > maxSize || allFileSize > maxSize) {
                        console.log('onSave', this.state.Files);
                        title = 'ไม่สามารถทำรายการได้';
                        text = 'ขนาดไฟล์ใหญ่เกิน 500MB';
                        type = 'warning';
                        break;
                    }
                }
            }
        }
        if (type === null) {
            console.log(skipCheck);
            if (skipCheck) return Promise.resolve({ value: true });
            else
                return Swal.fire({
                    title: 'ส่งรายงาน',
                    text: 'ท่านต้องการส่งรายงานนี้หรือไม่ ?',
                    type: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'ยกเลิก',
                    confirmButtonText: 'ตกลง',
                    reverseButtons: true
                });
            //return Promise.resolve(true);
        }
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return Promise.resolve(false);
        }

    }

    onSave = (skipCheck) => {
        this.checkUpdate(skipCheck).then(res => {
            if (!res.value) return
            this.props.updateEvaluationResult(
                this.state.KPIID,
                this.state.FormObjectResult.FormObjectItemResults,
                this.state.Month,
                this.state.Files.filter(x => (!x.FileID)),
                this.state.Files.filter(x => (x.FileID)),
                this.state.DelFiles,
                this.state.Form.FormID
            )
                .then(result => {
                    console.log(result);
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        })
                    }
                    else {
                        Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            type: 'success'
                        })
                        this.onEnterModal();
                    }
                })
                .catch(e => {
                    console.log(e);
                    let Type = e.Message && e.Description ? 'warning' : 'error';
                    let Message = e.Message || 'เกิดข้อผิดพลาด';
                    let Description = e.Description || 'เกิดข้อผิดพลาดระหว่างเรียกข้อมูล';
                    Swal.fire({
                        title: Message,
                        text: Description,
                        type: Type
                    })
                })
        })
            .catch(e => {
                console.log(e);
            })
    }

    approveEvaluationResult = (status) => {
        var title = (status === 0) ? 'ไม่ยืนยันผลการรายงาน' : 'ยืนยันผลการรายงาน';
        var text = 'ต้องการ' + title + 'หรือไม่';
        Swal.fire({
            title: title,
            text: text,
            type: (status === 0) ? 'error' : 'success',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then((res) => {
                if (status === 0 && res.value) {
                    // return Swal.fire({
                    //     title: 'เหตุผลที่ข้อมูลไม่สมบูรณ์',
                    //     input: 'textarea',
                    //     inputValue: this.state.RemarkReject,
                    //     allowOutsideClick: true,
                    //     showCancelButton: true,
                    //     confirmButtonText: 'ตกลง',
                    //     cancelButtonText: 'ยกเลิก',
                    //     reverseButtons: true,
                    //     inputAttributes: {
                    //         autocapitalize: 'off',
                    //         autocorrect: 'off'
                    //     }
                    // })
                    //     .then(res2 => {
                    //         console.log(res2);
                    //         return res2;
                    //     })
                    this.showRemarkRejectModal();
                    return {};
                }
                else return res;
            })
            .then(res => {
                //console.log(res);
                if (res.value || res.value === '') {
                    console.log(this.state.Form.FormID, this.state.KPIID, this.state.Month, status)
                    this.props.approveEvaluationResult(this.state.Form.FormID, this.state.KPIID, this.state.Month, status, res.value)
                        .then(result => {
                            console.log(result);
                            if (result.errors) {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: result.errors[0].Message,
                                    type: 'error'
                                })
                            }
                            else {
                                Swal.fire({
                                    title: 'บันทึกข้อมูลสำเร็จ',
                                    type: 'success'
                                });
                                this.onEnterModal();
                            }
                        })
                        .catch(e => {
                            console.log(e);
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'เกิดข้อผิดพลาดระหว่างเรียกข้อมูล',
                                type: 'error'
                            })
                        });
                }
            })
    }

    approveEvaluationResultReject = (status, RemarkReject) => {
        console.log(this.state.Form.FormID, this.state.KPIID, this.state.Month, status)
        return this.props.approveEvaluationResult(this.state.Form.FormID, this.state.KPIID, this.state.Month, status, RemarkReject)
            .then(result => {
                console.log(result);
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: result.errors[0].Message,
                        type: 'error'
                    })
                }
                else {
                    Swal.fire({
                        title: 'บันทึกข้อมูลสำเร็จ',
                        type: 'success'
                    });
                    this.onEnterModal();
                }
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างเรียกข้อมูล',
                    type: 'error'
                })
            });
    }

    EvaluateResult = (score) => {
        console.log(score)
        if (this.state.EvaluationCriteria.Type === 2 || (!score && score !== 0)) return;
        switch (this.state.EvaluationType.EvaluationTypeID) {
            case 6:
            case 3: {
                for (var i = 0; i < this.state.Form.Sections.length; i++) {
                    for (var j = 0; j < this.state.Form.Sections[i].FormObjects.length; j++)
                        if (
                            this.state.Form.Sections[i].FormObjects[j].FormTypeID === 2 ||
                            this.state.Form.Sections[i].FormObjects[j].FormTypeID === 3
                        ) {
                            var newFormObject = this.state.Form.Sections[i].FormObjects[j];
                            newFormObject.FormObjectItems[0].ResultValue = score;
                            this.updateFormObjectResult(newFormObject.FormObjectID, newFormObject.FormObjectItems[0], true);
                            return;
                        }
                }
            }
            default: return;
        }
    }

    getFormResult = (score) => {
        if (!score && score !== 0) return null;
        var EvaluationCriteria = this.state.EvaluationCriteria;

        // VeryHigh 6
        if (EvaluationCriteria.VeryHigh && EvaluationCriteria.VeryHigh.Target <= score) {
            return 6;
        }
        // High 4
        if (EvaluationCriteria.High && EvaluationCriteria.High.Target <= score) {
            return 4;
        }
        // Mid 3
        if (EvaluationCriteria.Mid && EvaluationCriteria.Mid.Target <= score) {
            return 3;
        }
        // Low 2
        if (EvaluationCriteria.Low && EvaluationCriteria.Low.Target <= score) {
            return 2;
        }
        // VeryLow 5
        if (EvaluationCriteria.VeryLow && EvaluationCriteria.VeryLow.Target <= score) {
            return 5;
        }
        // notPass 1
        if (score === 0) {
            return 1;
        }
    }

    getFormtoEvaluate = () => {
        if (this.state.EvaluationCriteria.Type === 2) return;
        switch (this.state.EvaluationType.EvaluationTypeID) {
            case 6:
            case 3: {
                for (var i = 0; i < this.state.Form.Sections.length; i++) {
                    for (var j = 0; j < this.state.Form.Sections[i].FormObjects.length; j++)
                        if (this.state.Form.Sections[i].FormObjects[j].FormTypeID === 2)
                            return ({
                                type: this.getFormResult(this.state.Form.Sections[i].FormObjects[j].FormObjectItems[0].ResultValue),
                                el: (<>
                                    <table className='table mb-0 table-custom table-striped table-bordered table-hover'>
                                        <thead align='center'>
                                            <tr>
                                                <th style={{ minWidth: '180px' }}>ตัวชี้วัด</th>
                                                <th style={{ minWidth: '150px' }}>องค์ประกอบ</th>
                                                <th>ค่าน้ำหนัก</th>
                                                <th style={{ minWidth: '100px' }}>เป้าหมาย</th>
                                                <th style={{ minWidth: '300px' }}><i className='text-input mr-1'>*</i>ผลการดำเนินงาน</th>
                                                <th>คะแนน</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.KPIName}</td>
                                                <td>{this.state.KPIMain.KPIMainName}</td>
                                                <td>{this.state.EvaluationCriteria.Weight}</td>
                                                <td>
                                                    {
                                                        (this.state.EvaluationCriteria.Type === 1) ?
                                                            this.state.EvaluationCriteria.Low.Description :
                                                            this.state.EvaluationCriteria.Low.Target
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        (this.state.EvaluationCriteria.Type === 1) ?
                                                            (this.state.Form.Sections[i].FormObjects[j].FormObjectItems[0].ResultText || '')
                                                            : (this.state.Form.Sections[i].FormObjects[j].FormObjectItems[0].ResultValue || 0)
                                                    }
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </>)
                            });
                        else if (this.state.Form.Sections[i].FormObjects[j].FormTypeID === 3)
                            return ({
                                type: this.getFormResult(this.state.Form.Sections[i].FormObjects[j].FormObjectItems[0].ResultValue),
                                el: (<>
                                    <table className='table mb-0 table-custom table-striped table-bordered table-hover'>
                                        <thead align='center'>
                                            <tr>
                                                <th rowSpan='2' style={{ minWidth: '180px' }}>ตัวชี้วัด</th>
                                                <th rowSpan='2' style={{ minWidth: '150px' }}>องค์ประกอบ</th>
                                                <th rowSpan='2'>ค่าน้ำหนัก</th>
                                                <th colSpan='3'>เป้าหมาย</th>
                                                <th rowSpan='2' style={{ minWidth: '300px' }}><i className='text-input mr-1'>*</i>ผลการดำเนินงาน</th>
                                                <th rowSpan='2'>คะแนน</th>
                                            </tr>
                                            <tr>
                                                <th className='align-middle' style={{ minWidth: '100px' }}>
                                                    ขั้นต้น
                                                    {(this.state.EvaluationCriteria.Low.Result) ? ('(' + this.state.EvaluationCriteria.Low.Result + ')') : ''}
                                                </th>
                                                <th className='align-middle' style={{ minWidth: '100px' }}>
                                                    มาตรฐาน
                                                    {(this.state.EvaluationCriteria.Mid.Result) ? ('(' + this.state.EvaluationCriteria.Mid.Result + ')') : ''}
                                                </th>
                                                <th className='align-middle' style={{ minWidth: '100px' }}>
                                                    ขั้นสูง
                                                    {(this.state.EvaluationCriteria.High.Result) ? ('(' + this.state.EvaluationCriteria.High.Result + ')') : ''}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.KPIName}</td>
                                                <td>{this.state.KPIMain.KPIMainName}</td>
                                                <td>{this.state.EvaluationCriteria.Weight}</td>
                                                <td>
                                                    {
                                                        (this.state.EvaluationCriteria.Type === 1) ?
                                                            this.state.EvaluationCriteria.Low.Description :
                                                            this.state.EvaluationCriteria.Low.Target
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        (this.state.EvaluationCriteria.Type === 1) ?
                                                            this.state.EvaluationCriteria.Mid.Description :
                                                            this.state.EvaluationCriteria.Mid.Target
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        (this.state.EvaluationCriteria.Type === 1) ?
                                                            this.state.EvaluationCriteria.High.Description :
                                                            this.state.EvaluationCriteria.High.Target
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        (this.state.EvaluationCriteria.Type === 1) ?
                                                            (this.state.Form.Sections[i].FormObjects[j].FormObjectItems[0].ResultText || '')
                                                            : (this.state.Form.Sections[i].FormObjects[j].FormObjectItems[0].ResultValue || 0)
                                                    }
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </>)
                            });
                }
            }
            default: return null;
        }
    }

    checkStepProgressbar = (index) => {
        if (this.state.currentSection === index) {
            return 'is-active content' + (index + 1);
        }
        else return 'content' + (index + 1);
    }

    checkStatus = (status) => {
        switch (status) {
            case 1: {
                return (
                    <span className='badge badge-sm badge-success ml-2'>รายงานเรียบร้อย</span>
                );
            }
            case 2: {
                return (
                    <span className='badge badge-sm badge-danger ml-2'>ยังไม่รายงาน</span>
                );
            }
            case 3: {
                return (
                    <span className='badge badge-sm badge-warning ml-2'>รอการอนุมัติ</span>
                );
            }
            case 4: {
                return (
                    <span className='badge badge-sm badge-rejected ml-2'>รายงานไม่เรียบร้อย</span>
                );
            }
            default: return null;
        }
    }

    managecurrentSection = (index) => {
        if (this.state.currentSection === index)
            return;
        this.setState({
            currentSection: index,
            currentSubSection: null
        }, () => {
            if (index === 0) this.setTextareaHeight();
        });
    }

    managecurrentSubSection = (index) => {
        if (this.state.currentSubSection === index) {
            this.setState({ currentSubSection: null });
            return;
        }
        this.setState({ currentSubSection: index }, () => {
            this.setTextareaHeight();
        });
    }

    toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve({
                FileName: file.name,
                Description: '',
                FileData: reader.result,
                //FileData: { file },
                //File: { file }
            });
            reader.onerror = error => reject(error);
        })
    }

    addFile = (file) => {
        return {
            FileName: file.name,
            Description: '',
            FileData: file
        }
    }

    handleFileChoose = (e) => {
        var files = e.target.files;
        var fileList = [...this.state.Files];
        for (var i = 0; i < files.length; i++) {
            if (!files[i].name) return;
            // promiseList.push(this.toBase64(files[i])
            //     .then((File) => {
            //         fileList.push(File);
            //     })
            // )
            fileList.push(this.addFile(files[i]))
        }
        // Promise.all(promiseList)
        //     .then(() => {
        //         console.log(fileList);
        //         this.setState({
        //             Files: fileList
        //         });
        //     })
        console.log(fileList);
        this.setState({
            Files: fileList
        });
    }

    handleDrop = (files) => {
        var fileList = [...this.state.Files];
        for (var i = 0; i < files.length; i++) {
            if (!files[i].name) return
            // promiseList.push(this.toBase64(files[i])
            //     .then((File) => {
            //         console.log(fileList);
            //         fileList.push(File);
            //     })
            // )
            fileList.push(this.addFile(files[i]))
        }
        // Promise.all(promiseList)
        //     .then(() => {
        //         this.setState({
        //             Files: fileList
        //         });
        //     })
        console.log(fileList);
        this.setState({
            Files: fileList
        });
    }

    handleRemoveFile = (index) => {
        var fileList = [...this.state.Files];
        var newDelFiles = [...this.state.DelFiles];
        if (fileList[index].FileID)
            newDelFiles.push({ FileID: (fileList[index].FileID), FileName: (fileList[index].FileName) });
        fileList.splice(index, 1);
        this.setState({
            Files: fileList,
            DelFiles: newDelFiles
        }, () => {
            console.log(this.state.DelFiles);
        });
    }

    getFile = (FileName) => {
        this.props.getFile(this.state.KPIID, this.state.Month, FileName);
    }

    hideModal = (callback) => {
        this.setState({ show: false }, () => {
            if (typeof callback === 'function')
                callback()
        });
    }

    showModal = () => {
        switch (this.state.EvaluationType.EvaluationTypeID) {
            case 1:
            case 2:
            case 4:
            case 5:
            case 6:
            case 3: {
                if (
                    !this.state.EvaluationCriteria.Low.Result ||
                    !this.state.EvaluationCriteria.Mid.Result ||
                    !this.state.EvaluationCriteria.High.Result
                ) {
                    Swal.fire({
                        title: 'ตัวชี้วัดนี้ไม่สามารถคำนวณผลได้',
                        text: 'ตัวชี้วัดนี้ไม่สามารถคำนวณผลได้ เนื่องจากเกณฑ์การประเมินไม่ครบถ้วน',
                        type: 'error'
                    });
                }
                else
                    this.setState({ show: true });
            }
            default: return;
        }
    }

    // onClickFile = (file) => {
    //     var data = file.FileData;
    //     var contentType = (data.split(':')[1]).split(';')[0];
    //     console.log(contentType);
    //     var byteCharacters = atob(data.substr(`data:${contentType};base64,`.length));
    //     var byteNumbers = new Array(byteCharacters.length);
    //     for (var i = 0; i < byteCharacters.length; i++) {
    //         byteNumbers[i] = byteCharacters.charCodeAt(i);
    //     }
    //     var byteArray = new Uint8Array(byteNumbers);
    //     var fileData = new Blob([byteArray], { type: contentType + ';base64' });
    //     var tempUrl = window.URL.createObjectURL(fileData);
    //     var tempLink = document.createElement('a');
    //     tempLink.href = tempUrl;
    //     tempLink.setAttribute('download', file.FileName);
    //     tempLink.click();
    // }

    //     <button
    //     onClick={
    //         () => {
    //             axios.post('http://localhost/getFile', { responseType: 'blob' })
    //                 .then(res => {
    //                     var name = decodeURI(res.headers['content-disposition']);
    //                     var startFileNameIndex = name.indexOf('"') + 1
    //                     var endFileNameIndex = name.lastIndexOf('"')
    //                     name = name.substring(startFileNameIndex, endFileNameIndex)
    //                     const url = window.URL.createObjectURL(new Blob([res.data]));
    //                     const link = document.createElement('a');
    //                     link.href = url;
    //                     link.setAttribute('download', name);
    //                     document.body.appendChild(link);
    //                     link.click();
    //                 })
    //         }
    //     }
    //     style={{ position: 'absolute', zIndex: '1000' }}
    //     >
    //      click jao
    //     </button>

    onChangeFileDescription = (value, index) => {
        var fileList = [...this.state.Files];
        var newFile = { ...fileList[index] };
        newFile.Description = value;
        fileList[index] = newFile;
        console.log(newFile);
        this.setState({ Files: fileList });
    }

    createNewFormObjectItemResult = (FormObjectID, FormObjectItem) => {
        return {
            FormID: this.state.Form.FormID,
            FormObjectItemResultID: FormObjectItem.FormObjectItemResultID,
            FormObjectItemID: FormObjectItem.FormObjectItemID,
            FormObjectID: FormObjectID,
            ResultText: FormObjectItem.ResultText,
            ResultValue: FormObjectItem.ResultValue,
            Remark: FormObjectItem.Remark
        }
    }

    updateFormObjectResult = (FormObjectID, FormObjectItem, report) => {
        var newFormObjectResult = { ...this.state.FormObjectResult };
        var newFormObjectItemResults = [...newFormObjectResult.FormObjectItemResults];
        var index = newFormObjectItemResults.findIndex(x => x.FormObjectItemID === FormObjectItem.FormObjectItemID);
        if (index >= 0) {
            newFormObjectItemResults[index] = this.createNewFormObjectItemResult(FormObjectID, FormObjectItem);
        }
        else {
            newFormObjectItemResults.push(this.createNewFormObjectItemResult(FormObjectID, FormObjectItem));
        }
        newFormObjectResult.FormObjectItemResults = newFormObjectItemResults;
        this.setState({ FormObjectResult: newFormObjectResult }, () => {
            console.log(this.state.FormObjectResult)
            if (report) this.onSave(true);
        });
    }

    onChangeContent = (Type, value, ObjectType, index, itemIndex) => {
        if (ObjectType) {
            switch (ObjectType) {
                case 'FormObject': {
                    var newForm = { ...this.state.Form }
                    var newSections = [...newForm.Sections];
                    var newSection = { ...newSections[this.state.currentSection - 1] };
                    var newFormObjects = [...newSection.FormObjects];
                    var newFormObject = { ...newFormObjects[index] };

                    newFormObjects[index] = newFormObject;
                    newSection.FormObjects = newFormObjects;
                    newSections[this.state.currentSection - 1] = newSection;
                    newForm.Sections = newSections;

                    newFormObject[Type] = value;
                    this.setState({ Form: newForm });
                    return;
                }
                case 'FormObjectItems': {
                    var newForm = { ...this.state.Form }
                    var newSections = [...newForm.Sections];
                    var newSection = { ...newSections[this.state.currentSection - 1] };
                    var newFormObjects = [...newSection.FormObjects];
                    var newFormObject = { ...newFormObjects[index] };
                    var newFormObjectItems = [...newFormObject.FormObjectItems];
                    var newFormObjectItem;

                    if (itemIndex) {
                        newFormObjectItem = { ...newFormObjectItems[itemIndex] };
                        newFormObjectItems[itemIndex] = newFormObjectItem;
                    }
                    else {
                        newFormObjectItem = { ...newFormObjectItems[0] };
                        newFormObjectItems[0] = newFormObjectItem;
                    }

                    if (newFormObjectItem[Type] === value) return;

                    newFormObject.FormObjectItems = newFormObjectItems;
                    newFormObjects[index] = newFormObject;
                    newSection.FormObjects = newFormObjects;
                    newSections[this.state.currentSection - 1] = newSection;
                    newForm.Sections = newSections;

                    newFormObjectItem[Type] = value;
                    if (Type === 'ResultValue' && newFormObject.FormTypeID === 6) {
                        newFormObjectItem.Remark = null;
                    }

                    console.log(newForm);
                    this.setState({ Form: newForm }, () => {
                        this.updateFormObjectResult(newFormObject.FormObjectID, newFormObjectItem);
                    });
                    return;
                }
                default: return null;
            }
        }
        else {
            this.setState({ [Type]: value });
        }
    }

    renderModalBody = (currentSection) => {
        if (currentSection === 0) { return this.renderSection1(); }
        else {
            return this.state.Form.Sections[currentSection - 1].FormObjects.map((obj, index) => {
                return (
                    <div className='KPI-modal-card' key={'EvaluationResult-modal-FormObject' + index}>
                        <div className='KPI-modal-card-title' onClick={() => this.managecurrentSubSection(index)}>
                            {(obj.FormTypeID !== 1) ? <i className='text-input mr-1'>*</i> : null}
                            {obj.Header}
                            <div className='float-right position-relative'>
                                <i className={'text-secondary fa fa-chevron-left pr-1 ' + ((this.state.currentSubSection === index) ? 'hide' : '')} />
                                <i className={'text-secondary fa fa-chevron-down ' + ((this.state.currentSubSection === index) ? '' : 'hide')} />
                            </div>
                        </div>
                        <Collapse in={(this.state.currentSubSection === index)}>
                            <div>
                                <div className='p-3 overflow-div'>
                                    {this.renderFormObjectContent(obj, index)}
                                    {
                                        (obj.Footer === '') ?
                                            null :
                                            <div className='mt-3'>
                                                <textarea
                                                    className='form-control'
                                                    value={(obj.Footer || '')}
                                                    disabled
                                                    style={{ resize: 'none', border: 'none', overflow: 'hidden', backgroundColor: 'white' }}
                                                />
                                            </div>
                                    }
                                </div>
                            </div>
                        </Collapse>
                    </div>
                );
            })
        }
    }

    renderSection1 = () => {
        return (
            <div>
                {
                    (this.state.EvaluationType.EvaluationTypeID !== 3 && this.state.EvaluationType.EvaluationTypeID !== 6) ?
                        (
                            (this.state.EvaluationType.EvaluationTypeID === 1 || this.state.EvaluationType.EvaluationTypeID === 2) ?

                                <>
                                    <div className='form-group font-title'>
                                        {this.state.Strategy.StrategyName}
                                    </div>
                                    <hr />
                                    <div className='form-group font-title'>
                                        {this.state.KPIMain.KPIMainName}
                                        {
                                            (this.state.KPIMain.Remark && this.state.KPIMain.Remark !== '') ?
                                                <span className='font-description'>{':' + this.state.KPIMain.Remark}</span>
                                                : null
                                        }
                                    </div>
                                    <hr />
                                </>
                                : null
                        )
                        :
                        <>
                            <div className='form-group font-title'>
                                {this.state.Policy.PolicyName}
                                {
                                    (this.state.Policy.Remark && this.state.Policy.Remark !== '') ?
                                        <span className='font-description'>{':' + this.state.Policy.Remark}</span>
                                        : null
                                }
                            </div>
                            <hr />
                            <div className='form-group font-title'>
                                {this.state.KPIMain.KPIMainName}
                                {
                                    (this.state.KPIMain.Remark && this.state.KPIMain.Remark !== '') ?
                                        <span className='font-description'>{':' + this.state.KPIMain.Remark}</span>
                                        : null
                                }
                            </div>
                            <hr />
                        </>
                }
                <div className='form-group font-title'>
                    {this.state.KPIName}
                </div>
                <hr />
                <div className='row'>
                    <div className='col-12 col-lg-6'>
                        <div className='form-group'>
                            <label className='form-label'>ผู้กำกับดูแลตัวชี้วัด :</label>
                            <div className='pl-2'>
                                {this.renderKPIPerson(1, this.state.KPIPersons, this.state.KPIOrganizations)}
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <div className='form-group'>
                            <label className='form-label'>ผู้จัดเก็บข้อมูล :</label>
                            <div className='pl-2'>
                                {this.renderKPIPerson(2, this.state.KPIPersons, this.state.KPIOrganizations)}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='form-group'>
                    <label className='form-label'>คำอธิบาย :</label>
                    <textarea
                        className='form-control font-description'
                        value={(this.state.Description || '')}
                        disabled
                        style={{ resize: 'none', border: 'none', overflow: 'hidden', backgroundColor: 'white' }}
                    />
                </div>
            </div >
        );
    }

    renderKPIPerson = (type, KPIPersons, KPIOrg) => {
        var el = [];
        KPIPersons.map((obj, index) => {
            if (obj.PersonStatus === type)
                el.push(
                    <div key={'EvaluationManage-KPIPersons-type' + type + '/' + index}>
                        {obj.PersonName}
                    </div>
                )
            return null;
        });
        KPIOrg.map((obj, index) => {
            if (obj.OrganizationStatus === type)
                el.push(
                    <div key={'EvaluationManage-KPIOrgs-type' + type + '/' + index}>
                        {obj.OrganizationName}
                    </div>
                )
            return null;
        });
        return el;
    }

    renderFormObjectContent = (object, FormIndex) => {
        switch (object.FormTypeID) {
            case 1: {
                return (
                    <>
                        <div
                            className='pl-2 fr-view'
                            dangerouslySetInnerHTML={{ __html: object.Content }}
                        />
                    </>
                )
            }
            case 2: {
                var innerTable = null
                if (this.state.EvaluationType.EvaluationTypeID === 5) {
                    innerTable = (
                        <>
                            <thead align='center'>
                                <tr>
                                    <th>ตัวชี้วัด</th>
                                    <th style={{ minWidth: '350px' }}><i className='text-input mr-1'>*</i>ผลการดำเนินงาน</th>
                                    <th>คะแนน</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.state.KPIName}</td>
                                    <td>
                                        <textarea
                                            className='form-control'
                                            value={(object.FormObjectItems[0].ResultText || '')}
                                            onChange={e => this.onChangeContent('ResultText', e.target.value, 'FormObjectItems', FormIndex)}
                                            rows={3}
                                        />
                                    </td>
                                    <td>
                                        {
                                            (this.state.Status === 1 && this.state.EvaluationCriteria) ?
                                                (object.FormObjectItems[0].ResultValue || 0)
                                                : null
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </>
                    )
                }
                else {
                    innerTable = (
                        <>
                            <thead align='center'>
                                <tr>
                                    <th>ตัวชี้วัด</th>
                                    <th>
                                        {
                                            (
                                                this.state.EvaluationType.EvaluationTypeID === 1 ||
                                                this.state.EvaluationType.EvaluationTypeID === 2
                                            ) ?
                                                'ยุทธศาสตร์' : 'องค์ประกอบ'
                                        }
                                    </th>
                                    <th>ค่าน้ำหนัก</th>
                                    <th style={{ minWidth: '100px' }}>เป้าหมาย</th>
                                    <th style={{ minWidth: '350px' }}><i className='text-input mr-1'>*</i>ผลการดำเนินงาน</th>
                                    {
                                        (this.state.EvaluationCriteria.Type === 1) ?
                                            null :
                                            <th>หน่วย</th>
                                    }
                                    <th>คะแนน</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.state.KPIName}</td>
                                    <td>
                                        {
                                            (
                                                this.state.EvaluationType.EvaluationTypeID === 1 ||
                                                this.state.EvaluationType.EvaluationTypeID === 2
                                            ) ?
                                                this.state.Strategy.StrategyName :
                                                this.state.Policy.PolicyName
                                        }
                                    </td>
                                    <td>{this.state.EvaluationCriteria.Weight}</td>
                                    <td>
                                        {
                                            (this.state.EvaluationCriteria.Type === 1) ?
                                                this.state.EvaluationCriteria.Low.Description :
                                                this.state.EvaluationCriteria.Low.Target
                                        }
                                    </td>
                                    <td>
                                        {/* <textarea
                                        className='form-control'
                                        value={
                                            (this.state.EvaluationCriteria.Type === 1) ?
                                                (object.FormObjectItems[0].ResultText || '')
                                                : (object.FormObjectItems[0].ResultValue || 0)
                                        }
                                        onChange={e =>
                                            (this.state.EvaluationCriteria.Type === 1) ?
                                                this.onChangeContent('ResultText', e.target.value, 'FormObjectItems', FormIndex)
                                                : this.onChangeContent('ResultValue', (e.target.value.replace(/^0+(?!\.|$)/, '') || 0), 'FormObjectItems', FormIndex)
                                        }
                                        onBlur={(this.state.EvaluationCriteria.Type === 1) ?
                                            null
                                            : (e) => this.onChangeContent('ResultValue', (parseFloat(e.target.value) || 0), 'FormObjectItems', FormIndex)
                                        }
                                        rows={3}
                                    /> */}
                                        {
                                            (this.state.EvaluationCriteria.Type === 1) ?
                                                <textarea
                                                    className='form-control'
                                                    value={(object.FormObjectItems[0].ResultText || '')}
                                                    onChange={e => this.onChangeContent('ResultText', e.target.value, 'FormObjectItems', FormIndex)}
                                                    rows={3}
                                                />
                                                :
                                                <input
                                                    className='form-control'
                                                    value={(object.FormObjectItems[0].ResultValue || 0)}
                                                    onChange={e => this.onChangeContent('ResultValue', (e.target.value.replace(/^0+(?!\.|$)/, '') || 0), 'FormObjectItems', FormIndex)}
                                                    onBlur={e => this.onChangeContent('ResultValue', (parseFloat(e.target.value) || 0), 'FormObjectItems', FormIndex)}
                                                />
                                        }
                                    </td>
                                    {
                                        (this.state.EvaluationCriteria.Type === 1) ?
                                            null :
                                            <td>
                                                {(this.state.EvaluationCriteria.Unit || '')}
                                            </td>
                                    }
                                    <td>
                                        {
                                            (this.state.Status === 1 && this.state.EvaluationCriteria) ?
                                                (object.FormObjectItems[0].ResultValue || 0)
                                                : null
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </>
                    )
                }
                return (
                    <>
                        <table className='table mb-0 table-custom table-striped table-bordered table-hover'>
                            {innerTable}
                        </table>
                    </>
                )
            }
            case 3: {
                var innerTable = null;
                if (this.state.EvaluationType.EvaluationTypeID === 5) {
                    innerTable = (
                        <>
                            <thead align='center'>
                                <tr>
                                    <th>ตัวชี้วัด</th>
                                    <th style={{ minWidth: '350px' }}><i className='text-input mr-1'>*</i>ผลการดำเนินงาน</th>
                                    <th>คะแนน</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.state.KPIName}</td>
                                    <td>
                                        <textarea
                                            className='form-control'
                                            value={(object.FormObjectItems[0].ResultText || '')}
                                            onChange={e => this.onChangeContent('ResultText', e.target.value, 'FormObjectItems', FormIndex)}
                                            rows={3}
                                        />
                                    </td>
                                    <td>
                                        {
                                            (this.state.Status === 1 && this.state.EvaluationCriteria) ?
                                                (object.FormObjectItems[0].ResultValue || 0)
                                                : null
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </>
                    )
                }
                else {
                    innerTable = (
                        <>
                            <thead align='center'>
                                <tr>
                                    <th rowSpan='2'>ตัวชี้วัด</th>
                                    <th rowSpan='2'>
                                        {
                                            (
                                                this.state.EvaluationType.EvaluationTypeID === 1 ||
                                                this.state.EvaluationType.EvaluationTypeID === 2
                                            ) ?
                                                'ยุทธศาสตร์' : 'องค์ประกอบ'
                                        }
                                    </th>
                                    <th rowSpan='2'>ค่าน้ำหนัก</th>
                                    <th colSpan='3'>เป้าหมาย</th>
                                    <th rowSpan='2' style={{ minWidth: '350px' }}><i className='text-input mr-1'>*</i>ผลการดำเนินงาน</th>
                                    {
                                        (this.state.EvaluationCriteria.Type === 1) ?
                                            null :
                                            <th rowSpan='2'>หน่วย</th>
                                    }
                                    <th rowSpan='2'>คะแนน</th>
                                </tr>
                                <tr>
                                    <th className='align-middle' style={{ minWidth: '100px' }}>
                                        ขั้นต้น
                                        {(this.state.EvaluationCriteria.Low.Result) ? ('(' + this.state.EvaluationCriteria.Low.Result + ')') : ''}
                                    </th>
                                    <th className='align-middle' style={{ minWidth: '100px' }}>
                                        มาตรฐาน
                                        {(this.state.EvaluationCriteria.Mid.Result) ? ('(' + this.state.EvaluationCriteria.Mid.Result + ')') : ''}
                                    </th>
                                    <th className='align-middle' style={{ minWidth: '100px' }}>
                                        ขั้นสูง
                                        {(this.state.EvaluationCriteria.High.Result) ? ('(' + this.state.EvaluationCriteria.High.Result + ')') : ''}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.state.KPIName}</td>
                                    <td>
                                        {
                                            (
                                                this.state.EvaluationType.EvaluationTypeID === 1 ||
                                                this.state.EvaluationType.EvaluationTypeID === 2
                                            ) ?
                                                this.state.Strategy.StrategyName :
                                                this.state.Policy.PolicyName
                                        }
                                    </td>
                                    <td>{this.state.EvaluationCriteria.Weight}</td>
                                    <td>
                                        {
                                            (this.state.EvaluationCriteria.Type === 1) ?
                                                this.state.EvaluationCriteria.Low.Description :
                                                this.state.EvaluationCriteria.Low.Target
                                        }
                                    </td>
                                    <td>
                                        {
                                            (this.state.EvaluationCriteria.Type === 1) ?
                                                this.state.EvaluationCriteria.Mid.Description :
                                                this.state.EvaluationCriteria.Mid.Target
                                        }
                                    </td>
                                    <td>
                                        {
                                            (this.state.EvaluationCriteria.Type === 1) ?
                                                this.state.EvaluationCriteria.High.Description :
                                                this.state.EvaluationCriteria.High.Target
                                        }
                                    </td>
                                    <td>
                                        {
                                            (this.state.EvaluationCriteria.Type === 1) ?
                                                <textarea
                                                    className='form-control'
                                                    value={(object.FormObjectItems[0].ResultText || '')}
                                                    onChange={e => this.onChangeContent('ResultText', e.target.value, 'FormObjectItems', FormIndex)}
                                                    rows={3}
                                                />
                                                :
                                                <input
                                                    className='form-control'
                                                    value={(object.FormObjectItems[0].ResultValue || 0)}
                                                    onChange={e => this.onChangeContent('ResultValue', (e.target.value.replace(/^0+(?!\.|$)/, '') || 0), 'FormObjectItems', FormIndex)}
                                                    onBlur={e => this.onChangeContent('ResultValue', (parseFloat(e.target.value) || 0), 'FormObjectItems', FormIndex)}
                                                />
                                        }
                                    </td>
                                    {
                                        (this.state.EvaluationCriteria.Type === 1) ?
                                            null :
                                            <td>
                                                {(this.state.EvaluationCriteria.Unit || '')}
                                            </td>
                                    }
                                    <td>
                                        {
                                            (this.state.Status === 1 && this.state.EvaluationCriteria) ?
                                                (object.FormObjectItems[0].ResultValue || 0)
                                                : null
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </>
                    )
                }
                return (
                    <>
                        <table className='table mb-0 table-custom table-striped table-bordered table-hover'>
                            {innerTable}
                        </table>
                    </>
                );
            }
            case 4: {
                return (
                    <FroalaEditor
                        model={object.FormObjectItems[0].ResultText}
                        onModelChange={this.onChangeContent}
                        onchange1={'ResultText'}
                        onchange2={'FormObjectItems'}
                        onchange3={FormIndex}
                    />
                );
            }
            case 5: {
                return (
                    <>
                        <table className='table mb-0 table-custom table-striped table-bordered table-hover'>
                            <thead align='center'>
                                <tr>
                                    <th>โครงการ</th>
                                    <th><i className='text-input mr-1'>*</i>ผลการดำเนินงาน</th>
                                    <th>ปัญหาอุปสรรค</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    object.FormObjectItems.map((obj, index) => {
                                        return (
                                            <tr key={'EvaluationResult-modal-FormObject' + FormIndex + 'item' + index}>
                                                <td>{obj.FormObjectItemName}</td>
                                                <td>
                                                    <input
                                                        className='form-control'
                                                        value={(obj.ResultText || '')}
                                                        onChange={e => this.onChangeContent('ResultText', e.target.value, 'FormObjectItems', FormIndex, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='form-control'
                                                        value={(obj.Remark || '')}
                                                        onChange={e => this.onChangeContent('Remark', e.target.value, 'FormObjectItems', FormIndex, index)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                );
            }
            case 6: {
                return (
                    <>
                        <div>
                            {object.Content}
                        </div>
                        <table className='table mb-0 table-custom table-striped table-bordered table-hover'>
                            <thead align='center'>
                                <tr>
                                    <th>ประเด็นการประเมิน</th>
                                    <th>คะแนน</th>
                                    <th><i className='text-input mr-1'>*</i>มี</th>
                                    <th><i className='text-input mr-1'>*</i>ไม่มี</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    object.FormObjectItems.map((obj, index) => {
                                        return (
                                            <React.Fragment key={'EvaluationResult-modal-FormObject' + FormIndex + 'item' + index}>
                                                <tr>
                                                    <td>{(index + 1) + '. ' + obj.FormObjectItemName}</td>
                                                    <td>{obj.TargetValue}</td>
                                                    <td align='center'>
                                                        <div style={{ maxWidth: '20px' }}>
                                                            <input
                                                                type='checkbox'
                                                                className='form-control'
                                                                value={(obj.ResultValue || '')}
                                                                onClick={e => this.onChangeContent('ResultValue', 0, 'FormObjectItems', FormIndex, index)}
                                                                checked={(obj.ResultValue === 0) ? true : false}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </td>
                                                    <td align='center'>
                                                        <div style={{ maxWidth: '20px' }}>
                                                            <input
                                                                type='checkbox'
                                                                className='form-control'
                                                                value={(obj.ResultValue || '')}
                                                                onClick={e => this.onChangeContent('ResultValue', 1, 'FormObjectItems', FormIndex, index)}
                                                                checked={(obj.ResultValue === 1) ? true : false}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                {
                                                    (obj.ResultValue === 1) ?
                                                        <tr>
                                                            <td colSpan={4}>
                                                                <div>
                                                                    <label className='form-label'>หมายเหตุ :</label>
                                                                    <textarea
                                                                        className='form-control'
                                                                        onChange={e => this.onChangeContent('Remark', e.target.value, 'FormObjectItems', FormIndex, index)}
                                                                        value={(obj.Remark || '')}
                                                                        rows={2}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        : null
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                );
            }
            case 7: {
                return (<>
                    <div>
                        {object.Content}
                    </div>
                    <table className='table mb-0 table-custom table-striped table-bordered table-hover'>
                        <thead align='center'>
                            <tr>
                                <th style={{ minWidth: '450px', maxWidth: '750px' }}>โครงการ</th>
                                <th style={{ minWidth: '120px' }}>ทั้งหมด</th>
                                <th style={{ minWidth: '150px' }}><i className='text-input mr-1'>*</i>ผลการดำเนินการ</th>
                                <th style={{ minWidth: '120px' }}>ส่วนต่าง</th>
                                <th style={{ minWidth: '120px' }}>ร้อยละ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                object.FormObjectItems.map((obj, index) => {
                                    return (
                                        <tr key={'EvaluationResult-modal-FormObject' + FormIndex + 'item' + index}>
                                            <td>{obj.FormObjectItemName}</td>
                                            <td>{obj.TargetValue}</td>
                                            <td>
                                                <input
                                                    className='form-control'
                                                    value={(obj.ResultValue || 0)}
                                                    type='number'
                                                    onChange={e => {
                                                        this.onChangeContent('ResultValue', (e.target.value.replace(/^0+(?!\.|$)/, '') || 0), 'FormObjectItems', FormIndex, index)
                                                    }
                                                    }
                                                    onBlur={e => {
                                                        var value = (e.target.value || 0);
                                                        value = (value < 0) ? 0 : value;
                                                        this.onChangeContent('ResultValue', (parseFloat(value) || 0), 'FormObjectItems', FormIndex, index)
                                                    }
                                                    }
                                                />
                                            </td>
                                            <td>
                                                {
                                                    (obj.ResultValue && typeof obj.ResultValue == 'number') ?
                                                        (obj.TargetValue - obj.ResultValue) : ''
                                                }
                                            </td>
                                            <td>
                                                {
                                                    (obj.ResultValue && typeof obj.ResultValue == 'number') ?
                                                        (this.checkInfinity(parseFloat(((obj.TargetValue - obj.ResultValue) / obj.TargetValue) * 100).toFixed(3))) : ''
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </>);
            }
            case 8: {
                return (
                    <div>
                        <DragandDropFile
                            handleDrop={this.handleDrop.bind(this)}
                            handleFileChoose={this.handleFileChoose.bind(this)}
                            files={this.state.Files}
                            handleRemoveFile={this.handleRemoveFile.bind(this)}
                            onChangeFileDescription={this.onChangeFileDescription}
                            getFile={this.getFile}
                        //onClickFile={this.onClickFile}
                        />
                    </div>
                );
            }
            default: return null;
        }
    }

    checkInfinity = (value) => {
        return (isFinite(value)) ? value : null
    }

    openPdf = () => {
        this.setState({ showPdf: true });
    }

    closePdf = () => {
        this.setState({ showPdf: false });
    }

    showRemarkReject = () => {
        Swal.fire({
            title: 'เหตุผลที่ข้อมูลไม่สมบูรณ์',
            html: "<div class='p-2 fr-view overflow-div'>" + this.state.RemarkReject + "</div>",
            confirmButtonText: 'ตกลง',
            onOpen: () => {
                var obj = document.getElementById('swal-textarea');
                if (obj) {
                    obj.style.height = 'auto';
                    obj.style.height = obj.scrollHeight + 'px';
                }
            }
        })

    }

    showRemarkRejectModal = () => {
        this.setState({ showRemarkReject: true });
    }

    hideRemarkRejectModal = (callback) => {
        this.setState({ showRemarkReject: false }, () => {
            if (callback && typeof callback === 'function')
                callback();
        });
    }

    getDefaultRemarkReject = () => {
        return this.state.RemarkReject;
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='xl' onEnter={() => this.onEnterModal()} enforceFocus={false}>
                    <Modal.Header closeButton>
                        <Modal.Title className='d-flex align-items-center'>
                            รายงานผล
                            <div className='ml-2 d-inline-block'>
                                <select
                                    className='form-control'
                                    name='Month'
                                    value={this.state.Month}
                                    onChange={e => {
                                        this.onEnterModal(e.target.value, this.state.Schedule.Mode)
                                        this.onChangeContent(e.target.name, e.target.value)
                                    }}
                                >
                                    {
                                        this.state.Schedule.Period.map((obj, index) => {
                                            return (
                                                <option
                                                    key={'EvaluationResult-period' + index}
                                                    value={obj.Month}
                                                >
                                                    {
                                                        (obj.Month !== null) ?
                                                            'ปี ' + (parseInt(obj.Month.split('-')[0]) + 543) + ' เดือน ' + checkMonth(obj.Month.split('-')[1])
                                                            : null
                                                    }
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            {this.checkStatus(this.state.Status)}
                        </Modal.Title>
                        {
                            (this.state.RemarkReject) ?
                                <div className='text-hover ml-3 p-3 p-lg-0'>
                                    <i
                                        className='fa fa-info-circle fa-2x text-input ml-1'
                                        onClick={this.showRemarkReject}
                                    />
                                    <div><span>เหตุผลที่ข้อมูลไม่สมบูรณ์</span></div>
                                </div>
                                : null
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className='status-bar-wrapper'>
                                <ul className="status-bar form-manage-modal-status-bar ">
                                    <li className={this.checkStepProgressbar(0)}>
                                        <div className='status-bar-event-catcher first-catcher' onClick={() => this.managecurrentSection(0)} />
                                        <span className='status-bar-text'>
                                            รายละเอียด
                                        </span>
                                    </li>
                                    {
                                        (this.state.Form.Sections) ?
                                            this.state.Form.Sections.map((obj, index) => {
                                                return (
                                                    <li key={'EvaluationResult-modal-section-li' + index} className={this.checkStepProgressbar(index + 1)}>
                                                        <div
                                                            className={'status-bar-event-catcher '
                                                                + ((index === this.state.Form.Sections.length - 1) ? 'last-catcher' : '')
                                                            }
                                                            onClick={() => this.managecurrentSection(index + 1)}
                                                        />
                                                        <span className='status-bar-text'>
                                                            {obj.SectionName}
                                                        </span>
                                                    </li>
                                                );
                                            })
                                            : null
                                    }
                                </ul>
                            </div>
                        </div>

                        <hr />
                        <div style={{ height: '65vh', overflowY: 'auto', overflowX: 'hidden' }} className='pl-2 pr-2'>
                            {this.renderModalBody(this.state.currentSection)}
                        </div>
                    </Modal.Body>

                    <Modal.Footer>

                        <div className='float-left d-flex flex-fill align-items-center'>
                            {
                                (this.state.Status !== 2 && (this.props.roleID === 1 || this.props.roleID === 2)) ?
                                    <div className='mr-3'>
                                        <button
                                            className={'mr-2 mb-2 mb-lg-0 btn btn-outline-' + ((this.state.Status === 2) ? 'secondary' : 'danger')}
                                            onClick={() => this.approveEvaluationResult(0)}
                                            disabled={(this.state.Status === 2)}
                                        >
                                            ข้อมูลไม่สมบูรณ์
                                        </button>
                                        <button
                                            className={'btn btn-outline-' + ((this.state.Status === 2) ? 'secondary' : 'primary')}
                                            onClick={() => this.approveEvaluationResult(1)}
                                            disabled={(this.state.Status === 2)}
                                        >
                                            ยืนยันการประเมิน
                                        </button>
                                    </div>
                                    : null
                            }
                            {
                                (this.state.EvaluationCriteria.Type === 1 && (this.props.roleID === 1 || this.props.roleID === 2)) ?
                                    <button className='btn btn-outline-primary' onClick={this.showModal}>สรุปผลการประเมิน</button>

                                    : null
                            }
                            <button
                                onClick={this.openPdf}
                                className='btn btn-outline-success ml-3'
                            >
                                export PDF
                            </button>
                        </div>

                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2 mb-2 mb-lg-0' onClick={this.onHideModal}>ยกเลิก</button>
                            <button
                                className={'btn btn-outline-' + ((!(this.state.CanReport) || (this.state.Status === 1)) ? 'secondary' : 'primary')}
                                onClick={() => this.onSave()}
                                disabled={!(this.state.CanReport) || (this.state.Status === 1)}
                            >
                                รายงาน
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {
                    (this.props.roleID === 1 || this.props.roleID === 2) ?
                        <EvaluationManageEvaluateModal
                            show={this.state.show}
                            hide={this.hideModal}
                            EvaluationCriteria={this.state.EvaluationCriteria}
                            getFormtoEvaluate={this.getFormtoEvaluate}
                            EvaluationTypeID={this.state.EvaluationType.EvaluationTypeID}
                            EvaluateResult={this.EvaluateResult}
                        />
                        : null
                }

                {
                    (this.state.showPdf) ?
                        <Pdf
                            currentKPIID={this.props.currentKPIID}
                            Month={this.state.Month}
                            Mode={this.state.Schedule.Mode}
                            closeWindowPortal={this.closePdf}
                            getEvaluationResultDetail={this.props.getEvaluationResultDetail}
                        />
                        : null
                }

                <RemarkRejectModal
                    show={this.state.showRemarkReject}
                    hide={this.hideRemarkRejectModal}
                    approveEvaluationResultReject={this.approveEvaluationResultReject}
                    getDefaultRemarkReject={this.getDefaultRemarkReject}
                />
            </>
        );
    }
}

export default EvaluationResultModal;