import React from 'react';
import Modal from './FormManage_components/FormManage_modal';
import FormManageTable from './FormManage_components/FormManage_table';
import FormService from '../../Service/FormService';
import { connect } from 'react-redux';
import { showLoading, hideLoading } from '../../Redux/Action';
import Swal from 'sweetalert2';
import { renderYear } from '../../Utilities/Date';
import queryString from 'query-string';

class FormManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            searchYear: 0,
            searchProfileName: '',
            searchProfileDescription: '',
            searched: {
                isSearched: false,
                searchedYear: 0,
                searchedProfileDescription: null,
                searchedProfileName: null
            },
            data: [],
            dataCount: 1,
            currentFormID: null,
            updateData: false,
            currentPage: 1,
            limit: 5
        }
    }

    componentDidMount() {
        this.searchForm('', '', null, 0, 5);
        if (this.props.location) {
            var search = queryString.parse(this.props.location.search);
            if (search && search.create === 'true') {
                this.setState({ show: true });
            }
        }
    }

    searchForm = (FormName, Remark, Year, Offset, Limit) => {
        this.props.dispatch(showLoading());
        var searchService = FormService.searchForm(FormName, Remark, Year, Offset, Limit)
        searchService
            .then(result => {
                console.log(result)
                this.props.dispatch(hideLoading());
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: result.errors[0].Message,
                        type: 'error'
                    });
                    return;
                }
                result = result.data.SearchForm;
                this.setState({ data: result.ListForm, dataCount: result.Count });
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                console.log(err)
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            })
    }

    getYear = () => {
        return renderYear('FormManage');
    }

    getFormDetailZ = (index) => {
        this.props.dispatch(showLoading());
        return FormService.getFormDetailZ(index)
            .then(result => {
                this.props.dispatch(hideLoading());
                return result;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    getFormDetailCopy = (index) => {
        //this.props.dispatch(showLoading());
        return FormService.getFormDetailCopy(index)
            .then(result => {
                //this.props.dispatch(hideLoading());
                return result;
            })
            .catch(err => {
                //this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    getFormType = () => {
        return FormService.getFormType();
    }

    updateForm = (FormID, FormName, Remark, Sections, DelSections) => {
        console.log(Sections);
        this.props.dispatch(showLoading());
        return FormService.updateFormZ(FormID, FormName, Remark, Sections)
            .then(res => {
                this.searchFormAfterSetState();
                if (!res.errors) {
                    if (DelSections.length > 0)
                        return FormService.deleteFormSectionsZ(FormID, DelSections)
                            .then(result => {
                                console.log(result)
                                this.props.dispatch(hideLoading());
                                return result;
                            })
                            .catch(err => {
                                this.props.dispatch(hideLoading());
                                console.log(err);
                                return Promise.reject(err);
                            })
                }
                this.props.dispatch(hideLoading());
                return res;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    createForm = (FormName, Remark, Sections) => {
        this.props.dispatch(showLoading());
        return FormService.createForm(FormName, Remark, Sections)
            .then(res => {
                console.log(res);
                this.props.dispatch(hideLoading());
                this.searchFormAfterSetState();
                return res;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    deleteForm = (FormID) => {
        Swal.fire({
            title: 'ลบฟอร์มการประเมิน',
            text: 'ต้องการลบฟอร์มการประเมินนี้หรือไม่ ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value)
                    FormService.deleteFormZ(FormID)
                        .then(result => {
                            if (result.errors) {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: result.errors[0].Message,
                                    type: 'error'
                                })
                                return;
                            }
                            Swal.fire({
                                title: 'ลบข้อมูลสำเร็จ',
                                type: 'success'
                            })
                            this.searchFormAfterSetState();
                        })
                        .catch(err => {
                            console.log(err);
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                                type: 'error'
                            });
                        });
            });
    }

    hideModal = (callback) => {
        (this.state.show === false) ?
            this.setState({ show: true })
            : this.setState({ show: false, currentFormID: null }, () => {
                if (typeof callback === 'function')
                    callback()
            });
    }

    changeCurrentFormID = (index) => {
        this.setState({
            currentFormID: index,
            show: true
        })
    }

    onChangeSearch = (name, value) => {
        this.setState({ [name]: value })
    }

    searchFormAfterSetState = () => {
        if (this.state.searched.isSearched === true) {
            this.searchForm(
                this.state.searched.searchedProfileName,
                this.state.searched.searchedProfileDescription,
                this.state.searched.searchedYear,
                (this.state.currentPage - 1) * this.state.limit,
                this.state.limit
            )
        }
        else {
            this.searchForm(
                null,
                null,
                null,
                (this.state.currentPage - 1) * this.state.limit,
                this.state.limit
            )
        }
    }

    clearSearch = () => {
        this.setState({
            searchProfileName: '',
            searchProfileDescription: '',
            searchedYear: 0,
            currentPage: 1,
            searched: {
                isSearched: false,
                searchedProfileName: null,
                searchedProfileDescription: null,
                searchedYear: 0
            }
        });
        this.searchForm(null, null, null, 0, this.state.limit);
    }

    onChangeTableState = (name, value, value2) => {
        if (name === '')
            this.setState({
                currentPage: value,
                limit: value2
            }, () => {
                this.searchFormAfterSetState()
            });
        else
            this.setState({ [name]: value }, () => {
                this.searchFormAfterSetState()
            });
    }

    clickSearch = () => {
        if (this.state.searchProfileName === '' && this.state.searchYear === 0)
            this.setState({
                currentPage: 1,
                searched: {
                    isSearched: false,
                    searchedProfileName: null,
                    searchedYear: 0
                }
            }, () => {
                this.searchForm(null, null, null, 0, this.state.limit);
            })
        else
            this.setState({
                currentPage: 1,
                //searchProfileName: '',
                //searchYear: 0,
                //searchProfileDescription: '',
                searched: {
                    isSearched: true,
                    searchedProfileName: this.state.searchProfileName,
                    searchedProfileDescription: this.state.searchProfileDescription,
                    searchedYear: this.state.searchYear
                }
            }, () => {
                this.searchForm(
                    this.state.searched.searchedProfileName,
                    this.state.searched.searchedProfileDescription,
                    this.state.searched.searchedYear,
                    0,
                    this.state.limit);
            });
    }

    render() {
        return (
            <>
                <div className='custom-block mb-4'>
                    <div className='row align-items-end'>
                        <div className='form-group col-12 col-lg-6'>
                            <label className='form-label'>ปีงบประมาณ</label>
                            <select className='form-control' value={this.state.searchYear} name='searchYear' onChange={e => this.onChangeSearch(e.target.name, parseInt(e.target.value))}>
                                <option value={0}>กรุณาเลือก</option>
                                {this.getYear()}
                            </select>
                        </div>
                        <div className='form-group col-12 col-lg-6'>
                            <label className='form-label'>ชื่อโปรไฟล์</label>
                            <input className='form-control' type='text' placeholder='ชื่อโปรไฟล์' value={this.state.searchProfileName} name='searchProfileName' onChange={e => this.onChangeSearch(e.target.name, e.target.value)} />
                        </div>
                        {/* <div className='form-group col-12 col-lg-4 col-xl-4'>
                            <label className='form-label'>คำอธิบาย</label>
                            <input className='form-control' type='text' placeholder='คำอธิบาย' value={this.state.searchProfileDescription} name='searchProfileDescription' onChange={e => this.onChangeSearch(e.target.name, e.target.value)} />
                        </div> */}
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='mr-2 btn btn-outline-danger' onClick={this.clearSearch}>ยกเลิก</button>
                        <button type='button' className='btn btn-outline-primary' onClick={this.clickSearch}>ค้นหา</button>
                    </div>
                </div>

                <div className='custom-block mb-4'>
                    <FormManageTable
                        clickAddForm={this.hideModal}
                        clickDeleteForm={this.deleteForm}
                        data={this.state.data}
                        dataCount={this.state.dataCount}
                        searchForm={this.searchForm}
                        changeCurrentFormID={this.changeCurrentFormID}
                        updateData={this.state.updateData}
                        onChangeTableState={this.onChangeTableState}
                        currentPage={this.state.currentPage}
                        limit={this.state.limit}
                        searched={this.state.searched}
                    />
                </div>
                <Modal
                    show={this.state.show}
                    hide={this.hideModal}
                    currentFormID={this.state.currentFormID}
                    getFormDetailZ={this.getFormDetailZ}
                    updateForm={this.updateForm}
                    createForm={this.createForm}
                    getFormType={this.getFormType}
                    getFormDetailCopy={this.getFormDetailCopy}
                />
            </>
        );
    }
}

export default connect(null)(FormManage);