import React from 'react';
import Collapse from 'react-bootstrap/Collapse'
import { NavLink, Link } from 'react-router-dom';
import VersionModal from './Version_modal';
import AccountService from '../../Service/AccountService';
import Swal from 'sweetalert2';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: [true, true, true, true],
            showVersion: false,
            arrow: ['fa-angle-double-down', 'fa-angle-double-down', 'fa-angle-double-down', 'fa-angle-double-down'],
            Version: []
        }
    }

    componentDidMount() {
        console.log(this.props)
        this.getVersion();
    }

    showCollapse = (index) => {
        var newshow = [...this.state.show];
        (this.state.show[index] === true) ?
            newshow[index] = false : newshow[index] = true;
        this.setState({ show: newshow });
        this.setArrowClass(index);
    }

    setArrowClass = (index) => {
        var newarrow = [...this.state.arrow];
        (newarrow[index] === 'fa-angle-left') ? newarrow[index] = 'fa-angle-double-down' : newarrow[index] = 'fa-angle-left';
        this.setState({ arrow: newarrow });
    }

    checkActiveMainMenu = (name) => {
        var className = 'main-menu';
        if (this.props.location.pathname.includes(name))
            className += ' active';
        return className;
    }

    showVersion = () => {
        this.setState({ showVersion: !(this.state.showVersion) });
    }

    getVersion = () => {
        AccountService.getVersion()
            .then(res => {
                if (res.errors) {
                    console.log(res)
                    return Promise.reject(res);
                }
                console.log(res);
                this.setState({
                    Version: (res.data.GetVersion) ? res.data.GetVersion : []
                });
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            })
    }

    render() {
        return (
            <>
                <div id='sidebar' className='noselect'>
                    <Link to="/">
                        <img src='/assets/logo-2.png' id='onwr-logo' alt='onwr-logo' />
                    </Link>
                    {/* <div
                        className='sub-menu version-text'
                        style={{ margin: 'auto' }}
                        onClick={this.showVersion}
                        align='center'
                    >
                        เวอร์ชันปัจจุบัน v0.4.01
                    </div> */}
                    <div id='sidebar-menu-wrapper'>
                        <div>
                            <NavLink to='/Dashboard' className='main-menu' onClick={() => { this.showCollapse(2) }}>
                                <i className="fa fa-sliders" aria-hidden="true"></i>
                                Dashboard / รายงานสรุป
                            </NavLink>
                        </div>
                        {
                            (this.props.roleID === 1 || this.props.roleID === 2) ?
                                <div>
                                    <div className={this.checkActiveMainMenu('/PMQAKPI')} onClick={() => { this.showCollapse(1) }}>
                                        <i className="fa fa-tachometer" aria-hidden="true"></i>
                                        การจัดการตัวชี้วัด
                                    <i className={'fa ml-2 mt-1 float-right ' + this.state.arrow[1]} aria-hidden="true" onClick={() => { this.showCollapse(1) }}></i>
                                    </div>
                                    <Collapse in={this.state.show[1]}>
                                        <div className='collapse-sub-menu'>
                                            <NavLink to='/PMQAKPI/EvaluationManage' className='sub-menu'>
                                                การประเมิน

                                            </NavLink>
                                            <NavLink to='/PMQAKPI/KPIManagement' className='sub-menu'>
                                                ตัวชี้วัด
                                            </NavLink>
                                            {/* <NavLink to='/PMQAKPI/UnitManagement' className='sub-menu'>
                                        หน่วยวัดของตัวชี้วัด
                                    </NavLink> */}
                                            <NavLink to='/PMQAKPI/FormManagement' className='sub-menu'>
                                                แบบฟอร์มการประเมิน
                                            </NavLink>
                                            <NavLink to='/PMQAKPI/Schedule' className='sub-menu'>
                                                ช่วงเวลาประเมิน
                                            </NavLink>
                                            <NavLink to='/PMQAKPI/PersonManagement' className='sub-menu'>
                                                จัดการบุคคล
                                            </NavLink>
                                        </div>
                                    </Collapse>
                                </div>
                                : null
                        }

                        <div>
                            <div className={this.checkActiveMainMenu('/Evaluation/')} onClick={() => { this.showCollapse(3) }}>
                                <i className="fa fa-calendar" aria-hidden="true"></i>
                                การประเมินผลตัวชี้วัด
                            <i className={'fa ml-2 mt-1 float-right ' + this.state.arrow[3]} onClick={() => { this.showCollapse(3) }}></i>
                            </div>
                            <Collapse in={this.state.show[3]}>
                                <div className='collapse-sub-menu'>
                                    <NavLink to='/Evaluation/EvaluationResult' className='sub-menu'>
                                        {
                                            (this.props.notification) ?
                                                <i className='fa fa-circle text-danger sub-menu-notification mr-2' />
                                                : null
                                        }
                                        การส่ง / จัดการผลประเมิน
                                    </NavLink>
                                </div>
                            </Collapse>
                        </div>


                        <div>
                            <div className={this.checkActiveMainMenu('/AdminManagement')} onClick={() => { this.showCollapse(0) }}>
                                <i className="fa fa-cogs" aria-hidden="true"></i>
                                การจัดการระบบ
                                    <i className={'fa ml-2 mt-1 float-right ' + this.state.arrow[0]} onClick={() => { this.showCollapse(0) }} />
                            </div>
                            <Collapse in={this.state.show[0]}>
                                <div className='collapse-sub-menu'>
                                    {
                                        (this.props.roleID === 1 || this.props.roleID === 2) ?
                                            (
                                                <>
                                                    <NavLink to='/AdminManagement/UserManagement' className='sub-menu'>
                                                        ผู้ใช้งาน
                                            </NavLink>
                                                    <NavLink to='/AdminManagement/OrgManagement' className='sub-menu'>
                                                        หน่วยงาน
                                            </NavLink>
                                                </>
                                            )

                                            : null
                                    }
                                    {/* <NavLink to='/AdminManagement/RoleManagement' className='sub-menu'>
                                                สิทธิ์การใช้งาน
                                            </NavLink> */}
                                    <NavLink to='/AdminManagement/History' className='sub-menu'>
                                        ประวัติการใช้งาน
                                    </NavLink>
                                    <a href='https://drive.google.com/drive/folders/13qOiOqW3XDCB_sRoedw90LNnb9L9cCXL?usp=sharing' target="_blank" className='sub-menu'>
                                        คู่มือการใช้งาน
                                    </a>
                                    {
                                        (this.state.Version && this.state.Version.length) ?
                                            <div
                                                className='sub-menu version-text'
                                                style={{ cursor: 'pointer' }}
                                                onClick={this.showVersion}
                                            >
                                                ข้อมูลเวอร์ชัน
                                            </div>
                                            : null
                                    }
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    {/* {
                        (this.state.Version && this.state.Version.length) ?
                            <div
                                className='position-absolute sub-menu version-text ml-2'
                                style={{ bottom: '0', left: '0', cursor: 'pointer' }}
                                onClick={this.showVersion}
                            >
                                เวอร์ชันปัจจุบัน {'v' + this.state.Version[0].VersionName}
                            </div>
                            : null
                    } */}

                </div>
                <VersionModal
                    show={this.state.showVersion}
                    hide={this.showVersion}
                    Version={this.state.Version}
                />
            </>
        );
    }
}

export default Sidebar;