import React from 'react';

export const getDaysOfWeek = (name) => {
    var days = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์'];
    return days;
}

export const renderMonth = (name) => {
    var months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
    return (
        <>
            {
                months.map((obj, index) => {
                    return (
                        <option key={name + '-month-option' + index} value={index + 1}>
                            {obj}
                        </option>
                    )
                })
            }
        </>
    );
}

export const renderYear = (name) => {
    var Year = new Date().getFullYear() + 2;
    var elements = [];
    for (var i = Year; i > 2015; i--) {
        elements.push((
            <option value={i} key={name + '-year-option' + i}>
                {i + 543}
            </option>
        ))
    }
    return elements;
}

export const checkMonth = (month) => {
    var months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
    return months[parseInt(month) - 1];
}
