import Axios from './BaseService';
// import { client } from './GraphqlClient';
// import { gql } from 'apollo-boost';

class LoginService {

    Login(Email, Password) {
        console.log(Email, Password);

        /* --------------- REST ---------------- */

        return Axios.post('/login', {
            Email: Email,
            Password: Password
        });


        /* -------------- Axios + Graphql ------------------*/
        // const LOGIN = `
        //     query Login($Email: String!, $Password: String!) {
        //         Login(Email: $Email, Password: $Password){
        //             AccountID
        //             Username
        //             Token
        //             Email
        //             RoleID
        //         }
        //     }
        // `;

        // return Axios.post('/pmqaservice', {
        //     query: LOGIN,
        //     variables: {
        //         Email: Email,
        //         Password: Password
        //     },
        //     login: true,
        //     errorPolicy: 'all'
        // });
    }

    Logout() {
        return Axios.post('/pmqaservice', {
            query: 'mutation Logout{Logout}',
            errorPolicy: 'all'
        });
    }

    /* ----------------------- Apollo Client ------------------------ */

    // Login(Email, Password) {
    //     const LOGIN = gql`
    //         query Login($Email: String!, $Password: String!) {
    //             Login(Email: $Email, Password: $Password){
    //                 AccountID
    //             }
    //         }
    //     `
    //     return client.query({
    //         query: LOGIN,
    //         variables: {
    //             Email: Email,
    //             Password: Password
    //         },
    //         errorPolicy: 'all'
    //     })
    // }
}

export default new LoginService();