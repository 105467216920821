import cookie from 'js-cookie';

export const setUsersCookies = (Email, Username, AccountID, Token) => {
    console.log(Email, Username, AccountID, Token)
    cookie.set('email', Email, { expires: 7 });
    cookie.set('username', Username, { expires: 7 });
    cookie.set('accountID', AccountID, { expires: 7 });
    cookie.set('token', Token, { expires: 7 });
}

export const getUsersCookies = (type) => {
    return cookie.get(type);
}

export const removeAllUsersCookies = () => {
    Object.keys(cookie.get()).forEach(function (obj) {
        cookie.remove(obj);
    });
    console.log(cookie.get());
}