import React from 'react';
import { connect } from 'react-redux';
import { setAccountLogout, setNotification } from '../../Redux/Action';
import { removeAllUsersCookies } from '../../Service/CookieService'
import ReactTooltip from 'react-tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import LoginService from '../../Service/LoginService';

const locationName = {
    'Dashboard': 'Dashboard',
    'EvaluationManage': 'การประเมิน',
    'KPIManagement': 'ตัวชี้วัด',
    'UnitManagement': 'หน่วยวัดของตัวชี้วัด',
    'FormManagement': 'แบบฟอร์มการประเมิน',
    'Schedule': 'ช่วงเวลาประเมิน',
    'EvaluationResult': 'การส่ง / จัดการผลประเมิน',
    'KPIReport': 'รายงานสรุป',
    'PersonManagement': 'จัดการบุคคล',
    'UserManagement': 'ผู้ใช้งาน',
    'OrgManagement': 'หน่วยงาน',
    'SystemManagement': 'จัดการระบบ',
    'RoleManagement': 'สิทธิ์การใช้งาน',
    'History': 'ประวัติการใช้งาน',
    'UserInfo': 'ข้อมูลผู้ใช้งาน',
    'resetpassword': 'รีเซ็ตรหัสผ่าน'
}

const menuName = {
    'Home': 'หน้าหลัก',
    'PMQAKPI': 'การจัดการตัวชี้วัด',
    'Evaluation': 'การประเมินตัวชี้วัด',
    'AdminManagement': 'การจัดการระบบ'
}

class Topbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    renderLocation(pathname) {

        if (pathname === '/' || pathname === 'Dashboard') {
            return (
                <>
                    <h4>Dashboard</h4>
                    <Link to='/Dashboard' style={{ fontSize: '1rem' }}>หน้าหลัก</Link>
                    {">"}
                    <Link to='/Dashboard' style={{ fontSize: '1rem' }}> Dashboard</Link>
                </>
            );
        }
        var newpathname = pathname.substr(1).split('/')
        var elem = [];
        elem.push(
            [
                <div key='title'>
                    <h4
                        className='d-inline-block'
                        data-tip={locationName[newpathname[newpathname.length - 1]]}
                        data-for='Sidebar-tooltip'
                    >
                        {locationName[newpathname[newpathname.length - 1]]}
                    </h4>
                </div>,
                <React.Fragment key='home'><Link to='/Dashboard' style={{ fontSize: '1rem' }}>{menuName['Home']}</Link></React.Fragment>
            ]
        );
        elem.push(newpathname.map((obj, index) => {
            if (obj === '') return true;
            if (index + 1 === newpathname.length) {
                return (
                    <React.Fragment key={index}>
                        {">"} <Link to={pathname} style={{ fontSize: '1rem' }}>{locationName[obj]}</Link>
                    </React.Fragment>
                );
            }
            return (
                <React.Fragment key={index}>
                    {">"} <div className='d-inline-block mr-2' style={{ fontSize: '1rem' }}>{menuName[obj]}</div>
                </React.Fragment>
            );
        })
        )
        return elem;
    }

    showSidebar = () => {
        if (document.getElementById('sidebar').className === 'sidebar-active') {
            document.getElementById('sidebar').className = '';
        }
        else {
            document.getElementById('sidebar').className = 'sidebar-active';
            document.getElementById('modal-background-app').className = 'background-active';
        }
    }

    onLogout = () => {
        LoginService.Logout()
            .then(result => {
                console.log(result);
                this.props.dispatch(setAccountLogout());
                this.props.dispatch(setNotification(0))
                removeAllUsersCookies();
                this.props.setDataLoaded(false);
                this.props.history.push('/');
            })
            .catch(e => {
                console.log(e);
                this.props.dispatch(setAccountLogout());
                this.props.dispatch(setNotification(0))
                removeAllUsersCookies();
                this.props.setDataLoaded(false);
                this.props.history.push('/');
            })

    }

    hideModal = (callback) => {
        (this.state.show === false) ?
            this.setState({
                show: true
            })
            : this.setState({
                show: false
            }, () => {
                if (typeof callback === 'function')
                    callback()
            });
    }

    // openBase64InNewTab = (data) => {
    //     var contentType = (data.split(':')[1]).split(';')[0];
    //     console.log(contentType);
    //     var byteCharacters = atob(data.substr(`data:${contentType};base64,`.length));
    //     var byteNumbers = new Array(byteCharacters.length);
    //     for (var i = 0; i < byteCharacters.length; i++) {
    //         byteNumbers[i] = byteCharacters.charCodeAt(i);
    //     }
    //     var byteArray = new Uint8Array(byteNumbers);
    //     var file = new Blob([byteArray], { type: contentType + ';base64' });
    //     var fileURL = URL.createObjectURL(file);
    //     window.open(fileURL);
    // }

    render() {
        return (
            <>
                <div id='topbar' className='noselect'>
                    <div className='float-right pt-3 d-flex align-items-center'>
                        {
                            <Link to='/UserInfo' replace={(this.props.location.pathname === '/UserInfo')}>
                                <img
                                    src={(this.props.profileImg === '') ? '/assets/fa-user.png' : this.props.profileImg}
                                    className='border rounded-circle topbar-profile-img mr-2'
                                    alt=''
                                />
                            </Link>
                            // <img
                            //     src={this.props.profileImg}
                            //     className='border rounded-circle topbar-profile-img mr-2'
                            //     onClick={() => this.openBase64InNewTab(this.props.profileImg, 'image/png')}
                            // />
                        }

                        <div className='d-inline-block mr-2 topbar-username'>
                            {
                                (this.props.username && this.props.username !== '') ?
                                    <h5 style={{ marginBottom: '0' }}>{this.props.username}</h5>
                                    : null
                            }
                            <span>{this.props.email}</span>
                        </div>
                        <Dropdown className='d-inline-block' drop='down' >
                            <Dropdown.Toggle size='sm' />
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to='/UserInfo' replace={(this.props.location.pathname === '/UserInfo')}>ข้อมูลผู้ใช้งาน</Dropdown.Item>
                                <Dropdown.Item onClick={this.onLogout}>ออกจากระบบ</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <button className='btn btn-secondary btn-sidebar-close mr-4 mt-2 float-left' onClick={this.showSidebar}><i className='fa fa-home'></i></button>
                    <div className='d-inline-block topbar-location'>
                        {this.renderLocation(this.props.location.pathname)}
                    </div>
                </div>
                <ReactTooltip id='Sidebar-tooltip' />
            </>
        );
    }
}

export default connect(null)(Topbar);