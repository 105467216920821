import React, { Component } from 'react';
import DragandDrop from './DragandDrop';

class DragandDropFile extends Component {
    constructor(props) {
        super(props)
        this.handleDrop = this.props.handleDrop;
        this.handleFileChoose = this.props.handleFileChoose;
        this.handleRemoveFile = this.props.handleRemoveFile;
    }

    checkFileType = (type) => {
        switch (type.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif': return 'fa-file-image-o'
            case 'pptx':
            case 'ppt': return 'fa-file-powerpoint-o'
            case 'doc':
            case 'docx': return 'fa-file-word-o'
            case 'xlsx':
            case 'xls': return 'fa-file-excel-o'
            default: return 'fa-file-text-o'
        }
    }

    render() {
        return (
            <DragandDrop handleDrop={this.handleDrop}>
                <div id='drag-item-wrapper'>
                    <div className='overflow-auto pt-3' style={{ width: '100%', height: '310px' }}>
                        {this.props.files.map((obj, index) => {
                            var filetype = obj.FileName.split('.');
                            filetype = filetype[filetype.length - 1];
                            return (
                                <React.Fragment key={'draganddrop-' + index}>
                                    <div className='row col mb-2 pt-2' align='center'>
                                        <span className='badge badge-secondary float-left position-absolute ml-2'>
                                            <h5 style={{ marginBottom: '0' }}>{index + 1}</h5>
                                        </span>
                                        <div className='col-4 d-flex flex-column justify-content-center'>
                                            {/* <img src='/assets/file-icon.png' className='file-icon' alt={'img-dropdown' + index} /> */}
                                            <i className={'fa file-icon ' + this.checkFileType(filetype)} />
                                            <div className='file-name' style={{ fontSize: '14px' }}>{obj.FileName}</div>
                                        </div>
                                        <div className='col-6' align='left'>
                                            <label className='form-label'>คำอธิบาย :</label>
                                            <textarea
                                                className='file-description form-control'
                                                value={(obj.Description || '')}
                                                onChange={e => this.props.onChangeFileDescription(e.target.value, index)}
                                                rows={2}
                                            />
                                        </div>
                                        <div className='col-2 d-flex justify-content-center align-items-center' >
                                            {
                                                (obj.FileID) ?
                                                    <button className='btn btn-outline-secondary' onClick={() => this.props.getFile(obj.FileName)}>
                                                        <i className='fa fa-download'></i>
                                                    </button>
                                                    : null
                                            }
                                            <button className='btn btn-outline-danger ml-3' onClick={() => { this.handleRemoveFile(index) }}>
                                                <i className='fa fa-trash'></i>
                                            </button>
                                        </div>
                                    </div>
                                    <hr />
                                </React.Fragment>);
                        })
                        }
                    </div>
                    <div id='drag-bottom-text-wrapper'>
                        <div className='text-center'>
                            <div>ลากไฟล์ที่ต้องการอัพโหลด หรือกดปุ่มด้านล่าง</div>
                            <label htmlFor="kpireport-modal-files" className="btn btn-outline-secondary">เลือกไฟล์</label>
                            <input type='file' id='kpireport-modal-files' multiple onChange={this.handleFileChoose.bind(this)} style={{ display: 'none' }}></input>

                        </div>
                    </div>
                </div>
            </DragandDrop>
        )
    }
}
export default DragandDropFile