
import Axios from './BaseService';

class FormService {

    searchForm(FormName, Remark, Year, Offset, Limit) {
        FormName = (!FormName) ? null : FormName.trim();
        Remark = (!Remark) ? null : Remark.trim();
        Year = (Year === undefined || Year === null) ? null : parseInt(Year);
        const SEARCHFORM = `
            query SearchForm($Year: Int,$FormName: String, $Remark: String, $Offset: Int, $Limit: Int) {
                SearchForm(Year:$Year,FormName: $FormName, Remark: $Remark, Offset: $Offset, Limit: $Limit){
                    ListForm{
                        FormID
                        FormName
                        Remark
                    }
                    Count
                }
            }
        `
        return Axios.post('/pmqaservice', {
            query: SEARCHFORM,
            variables: {
                FormName: FormName,
                Remark: Remark,
                Year: Year,
                Offset: parseInt(Offset),
                Limit: parseInt(Limit)
            },
            errorPolicy: 'all'
        })
    }

    searchFormKPIBound(KPIBound, FormName) {
        const SEARCHFORMKPIBOUND = `
            query SearchForm($KPIBound: Int,$FormName: String) {
                SearchForm(KPIBound: $KPIBound, FormName: $FormName, Limit: 100){
                    ListForm{
                        FormID
                        FormName
                        Remark
                    }
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: SEARCHFORMKPIBOUND,
            variables: {
                KPIBound: KPIBound,
                FormName: FormName
            }
        });
    }

    getFormDetail(FormID) {
        const GETFORMDETAIL = `
            query GetFormDetail($FormID: Int!) {
                GetFormDetail(FormID: $FormID) {
                    Sections {
                        SectionID
                        SectionName
                        FormObjects {
                            FormObjectID
                            Content
                            FormTypeID
                            Footer
                            Header
                            HasResult
                            Computable
                            FormObjectItems{
                                FormObjectItemID
                                FormObjectItemName
                                TargetText
                                TargetValue
                            }
                        }
                    }
                    Editable
                    FormID
                    FormName
                    Remark
                }
            }
        `
        return Axios.post('/pmqaservice', {
            query: GETFORMDETAIL,
            variables: {
                FormID: parseInt(FormID)
            },
            errorPolicy: 'all'
        })
    }

    getFormDetailZ(FormID) {
        const GETFORMDETAILZ = `
            query GetFormDetailZ($FormID: Int!) {
                GetFormDetailZ(FormID: $FormID) {
                    Sections {
                        SectionID
                        SectionName
                        FormObjects {
                            FormObjectID
                            Content
                            FormTypeID
                            Footer
                            Header
                            HasResult
                            Computable
                            FormObjectItems{
                                FormObjectItemID
                                FormObjectItemName
                                TargetText
                                TargetValue
                            }
                        }
                    }
                    Editable
                    FormID
                    FormName
                    Remark
                }
            }
        `
        return Axios.post('/pmqaservice', {
            query: GETFORMDETAILZ,
            variables: {
                FormID: parseInt(FormID)
            },
            errorPolicy: 'all'
        })
    }

    getFormDetailCopy(FormID) {
        const GETFORMDETAIL = `
            query GetFormDetail($FormID: Int!) {
                GetFormDetail(FormID: $FormID) {
                    Sections {
                        SectionName
                        FormObjects {
                            Content
                            FormTypeID
                            Footer
                            Header
                            HasResult
                            Computable
                            FormObjectItems{
                                FormObjectItemName
                                TargetText
                                TargetValue
                            }
                        }
                    }
                    FormID
                    FormName
                    Remark
                }
            }
        `
        return Axios.post('/pmqaservice', {
            query: GETFORMDETAIL,
            variables: {
                FormID: parseInt(FormID)
            },
            errorPolicy: 'all'
        })
    }

    updateForm(FormID, FormName, Remark, Sections, DelSections) {
        console.log({
            FormID: parseInt(FormID),
            FormName: FormName,
            Remark: Remark,
            Sections: Sections,
            DelSections: DelSections
        });
        const UPDATEFORM = `
            mutation UpdateForm($FormID: Int!, $FormName: String!, $Remark: String, $Sections: [InputSections], $DelSections: [DelSections]) {
                UpdateForm(FormID: $FormID, FormName: $FormName, Remark: $Remark, Sections: $Sections, DelSections: $DelSections) {
                    FormID
                    FormName
                    Remark
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: UPDATEFORM,
            variables: {
                FormID: parseInt(FormID),
                FormName: FormName,
                Remark: Remark,
                Sections: Sections,
                DelSections: DelSections
            },
            errorPolicy: 'all',
        });
    }

    updateFormZ(FormID, FormName, Remark, Sections, DelSections) {
        console.log({
            FormID: parseInt(FormID),
            FormName: FormName,
            Remark: Remark,
            Sections: Sections,
            DelSections: DelSections
        });
        const UPDATEFORMZ = `
            mutation UpdateFormZ($FormID: Int!, $FormName: String!, $Remark: String, $Sections: [InputSections], $DelSections: [DelSections]) {
                UpdateFormZ(FormID: $FormID, FormName: $FormName, Remark: $Remark, Sections: $Sections, DelSections: $DelSections) {
                    FormID
                    FormName
                    Remark
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: UPDATEFORMZ,
            variables: {
                FormID: parseInt(FormID),
                FormName: FormName,
                Remark: Remark,
                Sections: Sections,
                DelSections: DelSections
            },
            errorPolicy: 'all',
        });
    }

    createForm(FormName, Remark, Sections) {
        const CREATEFORM = `
            mutation CreateForm($FormName: String!, $Remark: String, $Sections: [InputSections]) {
                CreateForm(FormName: $FormName, Remark: $Remark, Sections: $Sections) {
                    FormID
                    FormName
                    Remark
                }
            }
         `;

        return Axios.post('/pmqaservice', {
            query: CREATEFORM,
            variables: {
                FormName: FormName,
                Remark: Remark,
                Sections: Sections
            },
            errorPolicy: 'all',
        });
    }

    deleteForm(FormID) {
        const DELETEFORM = `
            mutation DeleteForm($FormID: Int!){
                DeleteForm(FormID: $FormID) {
                    FormID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: DELETEFORM,
            variables: {
                FormID: parseInt(FormID)
            },
            errorPolicy: 'all'
        })
    }

    deleteFormZ(FormID) {
        const DELETEFORMZ = `
            mutation DeleteFormZ($FormID: Int!){
                DeleteFormZ(FormID: $FormID) {
                    FormID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: DELETEFORMZ,
            variables: {
                FormID: parseInt(FormID)
            },
            errorPolicy: 'all'
        })
    }

    deleteFormSections(FormID, DelSections) {
        console.log(FormID, DelSections);
        const DELETEFORMSECTIONS = `
            mutation DeleteSectionsForm($FormID: Int!, $DelSections: [DelSections]) {
                DeleteSectionsForm(FormID: $FormID, DelSections: $DelSections) {
                    FormID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: DELETEFORMSECTIONS,
            variables: {
                FormID: parseInt(FormID),
                DelSections: DelSections
            },
            errorPolicy: 'all'
        });
    }

    deleteFormSectionsZ(FormID, DelSections) {
        console.log(FormID, DelSections);
        const DELETEFORMSECTIONSZ = `
            mutation DeleteSectionsFormZ($FormID: Int!, $DelSections: [DelSections]) {
                DeleteSectionsFormZ(FormID: $FormID, DelSections: $DelSections) {
                    FormID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: DELETEFORMSECTIONSZ,
            variables: {
                FormID: parseInt(FormID),
                DelSections: DelSections
            },
            errorPolicy: 'all'
        });
    }

    getFormType() {
        const GETFORMTYPE = `
            {
                GetFormType{
                    FormTypeID
                    FormTypeName
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: GETFORMTYPE,
            errorPolicy: 'all'
        });
    }
}

export default new FormService();