import Axios from './BaseService';

class KPIService {

    searchKPI(Year, KPIName, EvaluationTypeID, OrganizationID, Offset, Limit) {
        Year = (!Year) ? null : Year;
        EvaluationTypeID = (!EvaluationTypeID) ? null : EvaluationTypeID;
        KPIName = (!KPIName) ? null : KPIName.trim();
        OrganizationID = (!OrganizationID) ? null : OrganizationID;
        const SEARCHKPI = `
            query SearchKPI($Year: Int, $KPIName: String, $EvaluationTypeID: Int, $OrganizationID: Int, $Offset: Int, $Limit: Int){
                SearchKPI(Year: $Year, KPIName: $KPIName, EvaluationTypeID: $EvaluationTypeID, OrganizationID: $OrganizationID, Offset: $Offset, Limit: $Limit){
                    ListKPI{
                        Year,
                        KPIID,
                        KPIName,
                        Description,
                        KPIMainID,
                        KPIMainName,
                        PolicyID,
                        PolicyName,
                        EvaluationTypeID,
                        EvaluationTypeName,
                        OrganizationID,
                        OrganizationName
                    }
                    Count
                }
            }
        `;
        return Axios.post('/pmqaservice', {
            query: SEARCHKPI,
            variables: {
                Year: parseInt(Year),
                KPIName: KPIName,
                EvaluationTypeID: parseInt(EvaluationTypeID),
                OrganizationID: parseInt(OrganizationID),
                Offset: parseInt(Offset),
                Limit: parseInt(Limit)
            },
            errorPolicy: 'all'
        });
    }
    searchKPIWithProject(KPIName) {
        KPIName = (!KPIName) ? null : KPIName.trim();
        const SEARCHKPI = `
            query SearchKPI($KPIName: String, $HasProject: Boolean, $Offset: Int, $Limit: Int){
                SearchKPI(KPIName: $KPIName, HasProject: $HasProject, Offset: $Offset, Limit: $Limit){
                    ListKPI{
                        Year,
                        KPIID,
                        KPIName,
                        Description,
                        KPIMainID,
                        KPIMainName,
                        PolicyID,
                        PolicyName,
                        EvaluationTypeID,
                        EvaluationTypeName,
                        OrganizationID,
                        OrganizationName
                    }
                    Count
                }
            }
        `;
        return Axios.post('/pmqaservice', {
            query: SEARCHKPI,
            variables: {
                KPIName: KPIName,
                HasProject: true,
                Offset: 0,
                Limit: 25
            },
            errorPolicy: 'all'
        });
    }

    getKPIDetail(KPIID) {
        const GETKPIDETAIL = `
        query GetKPIDetail($KPIID: Int!){
            GetKPIDetail(KPIID: $KPIID){
                KPIID
                EvaluationType{
                    EvaluationTypeID
                    EvaluationTypeName
                }
                Editable
                KPIName
                Description
                Year
                KPIMainID
                StrategyID
                ProjectKPI{
                    ProjectID
                    ProjectName
                    Lat
                    Lng
                    Organization {
                        OrganizationID
                        OrganizationName
                    }
                }
                EvaluationCriteria {
                    Weight
                    PointFull
                    PointPass
                    Unit
                    Type
                    VeryLow {
                        Target
                        Result
                        Description
                    }
                    Low {
                        Target
                        Result
                        Description
                    }
                    Mid{
                        Target
                        Result
                        Description
                    }
                    High{
                        Target
                        Result
                        Description
                    }
                    VeryHigh {
                        Target
                        Result
                        Description
                    }
                }
                HasFile
                KPIPersons {
                    PersonID
                    PersonName
                    PersonStatus
                }
                ListKPI {
                    KPIName
                    KPIID
                    EvaluationCriteria{
                        Weight
                    }
                }
                KPIOrganizations {
                    OrganizationID
                    OrganizationName
                    OrganizationStatus
                }
                EvaluationType {
                EvaluationTypeID
                    EvaluationTypeName
                    Remark
                }
                Policy{
                    PolicyID
                    PolicyName
                    Remark
                }
                KPIMain{
                    KPIMainID
                    KPIMainName
                    Remark
                }
                Form{
                    FormID
                    FormName
                    Remark
                }
            }
        }
        `;

        return Axios.post('/pmqaservice', {
            query: GETKPIDETAIL,
            variables: {
                KPIID: parseInt(KPIID)
            },
            errorPolicy: 'all'
        });
    }

    getKPIDetailZ(KPIID) {
        const GETKPIDETAILZ = `
        query GetKPIDetailZ($KPIID: Int!){
            GetKPIDetailZ(KPIID: $KPIID){
                KPIID
                EvaluationType{
                    EvaluationTypeID
                    EvaluationTypeName
                }
                Editable
                KPIName
                Description
                Year
                KPIMainID
                StrategyID
                ProjectKPI{
                    ProjectID
                    ProjectName
                    Lat
                    Lng
                    Organization {
                        OrganizationID
                        OrganizationName
                    }
                }
                EvaluationCriteria {
                    Weight
                    PointFull
                    PointPass
                    Unit
                    Type
                    VeryLow {
                        Target
                        Result
                        Description
                    }
                    Low {
                        Target
                        Result
                        Description
                    }
                    Mid{
                        Target
                        Result
                        Description
                    }
                    High{
                        Target
                        Result
                        Description
                    }
                    VeryHigh {
                        Target
                        Result
                        Description
                    }
                }
                HasFile
                KPIPersons {
                    PersonID
                    PersonName
                    PersonStatus
                }
                ListKPI {
                    KPIName
                    KPIID
                    EvaluationCriteria{
                        Weight
                    }
                }
                KPIOrganizations {
                    OrganizationID
                    OrganizationName
                    OrganizationStatus
                }
                EvaluationType {
                EvaluationTypeID
                    EvaluationTypeName
                    Remark
                }
                Policy{
                    PolicyID
                    PolicyName
                    Remark
                }
                KPIMain{
                    KPIMainID
                    KPIMainName
                    Remark
                }
                Form{
                    FormID
                    FormName
                    Remark
                }
            }
        }
        `;

        return Axios.post('/pmqaservice', {
            query: GETKPIDETAILZ,
            variables: {
                KPIID: parseInt(KPIID)
            },
            errorPolicy: 'all'
        });
    }

    searchMainKPI(Year, EvaluationTypeID, PolicyID) {
        Year = (!Year) ? null : Year;
        EvaluationTypeID = (!EvaluationTypeID) ? null : EvaluationTypeID;
        PolicyID = (!PolicyID) ? null : PolicyID;
        const SEARCHKPIMAIN = `
            query SearchKPIMain($Year: Int, $EvaluationTypeID:Int,$PolicyID: Int){
                SearchKPIMain(Year: $Year, EvaluationTypeID: $EvaluationTypeID, PolicyID: $PolicyID){
                    KPIMainID
                    KPIMainName
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: SEARCHKPIMAIN,
            variables: {
                Year: Year,
                EvaluationTypeID: EvaluationTypeID,
                PolicyID: PolicyID
            },
            errorPolicy: 'all'
        });
    }

    updateKPI(
        KPIID,
        KPIName,
        Description,
        Year,
        KPIMainID,
        EvaluationCriteria,
        HasFile,
        KPIPersons,
        KPIOrganizations,
        FormID,
        MapData,
        DelKPIPersons,
        DelKPIOrganizations,
        DelMapData
    ) {
        KPIName = (!KPIName) ? null : KPIName.trim();
        Year = (Year === null || Year === 0) ? 0 : Year;
        HasFile = (HasFile === null || HasFile === undefined) ? false : HasFile;
        KPIPersons = (KPIPersons === null || KPIPersons === undefined) ? [] : KPIPersons;
        KPIOrganizations = (KPIOrganizations === null || KPIOrganizations === undefined) ? [] : KPIOrganizations;
        if (EvaluationCriteria === null) {
            EvaluationCriteria = {
                Weight: null,
                PointFull: null,
                PointPass: null,
                VeryLow: {
                    Target: null,
                    Description: null,
                    Result: null
                },
                Low: {
                    Target: null,
                    Description: null,
                    Result: null
                },
                Mid: {
                    Target: null,
                    Description: null,
                    Result: null
                },
                High: {
                    Target: null,
                    Description: null,
                    Result: null
                },
                VeryHigh: {
                    Target: null,
                    Description: null,
                    Result: null
                }
            }
        }
        console.log({
            KPIID: KPIID,
            KPIName: KPIName,
            Description: Description,
            Year: Year,
            KPIMainID: KPIMainID,
            Weight: EvaluationCriteria.Weight,
            PointFull: EvaluationCriteria.PointFull,
            PointPass: EvaluationCriteria.PointPass,
            HasFile: HasFile,
            KPIPersons: KPIPersons,
            KPIOrganizations: KPIOrganizations,
            EvaluationCriteria: EvaluationCriteria,
            FormID: FormID,
            KPIProject: MapData,
            DelKPIPersons: DelKPIPersons,
            DelKPIOrganizations: DelKPIOrganizations,
            DelKPIProject: DelMapData
        })
        const UPDATEKPI = `
            mutation UpdateKPI(
                $KPIID: Int!,
                $KPIName: String!,
                $Description: String,
                $Year: Int,
                $KPIMainID: Int!,
                $Weight: Int,
                $PointFull: Int,
                $PointPass: Int,
                $HasFile: Boolean,
                $KPIPersons: [InputKPIPersons],
                $KPIOrganizations: [InputKPIOrganizations],
                $EvaluationCriteria: InputEvaluationCriteria
                $FormID: Int,
                $KPIProject: [InputKPIProject],
                $DelKPIPersons: [InputDelKPIPersons],
                $DelKPIOrganizations: [InputDelKPIOrganizations],
                $DelKPIProject: [InputDelKPIProject]
            ){
                UpdateKPI(
                    KPIID: $KPIID,
                    KPIName: $KPIName,
                    Description: $Description,
                    Year: $Year,
                    KPIMainID: $KPIMainID,
                    Weight: $Weight,
                    PointFull: $PointFull,
                    PointPass: $PointPass,
                    HasFile: $HasFile,
                    KPIPersons: $KPIPersons,
                    KPIOrganizations: $KPIOrganizations,
                    EvaluationCriteria: $EvaluationCriteria,
                    FormID: $FormID,
                    KPIProject: $KPIProject
                    DelKPIPersons: $DelKPIPersons,
                    DelKPIOrganizations: $DelKPIOrganizations,
                    DelKPIProject: $DelKPIProject
                ){
                    KPIID
                }
            }
        `;
        return Axios.post('/pmqaservice', {
            query: UPDATEKPI,
            variables: {
                KPIID: KPIID,
                KPIName: KPIName,
                Description: Description,
                Year: Year,
                KPIMainID: KPIMainID,
                Weight: EvaluationCriteria.Weight,
                PointFull: EvaluationCriteria.PointFull,
                PointPass: EvaluationCriteria.PointPass,
                HasFile: HasFile,
                KPIPersons: KPIPersons,
                KPIOrganizations: KPIOrganizations,
                EvaluationCriteria: EvaluationCriteria,
                FormID: FormID,
                KPIProject: MapData,
                DelKPIPersons: DelKPIPersons,
                DelKPIOrganizations: DelKPIOrganizations,
                DelKPIProject: DelMapData
            },
            errorPolicy: 'all'
        })
    }

    updateKPIZ(
        KPIID,
        KPIName,
        Description,
        Year,
        KPIMainID,
        EvaluationCriteria,
        HasFile,
        KPIPersons,
        KPIOrganizations,
        FormID,
        MapData,
        DelKPIPersons,
        DelKPIOrganizations,
        DelMapData
    ) {
        KPIName = (!KPIName) ? null : KPIName.trim();
        Year = (Year === null || Year === 0) ? 0 : Year;
        HasFile = (HasFile === null || HasFile === undefined) ? false : HasFile;
        KPIPersons = (KPIPersons === null || KPIPersons === undefined) ? [] : KPIPersons;
        KPIOrganizations = (KPIOrganizations === null || KPIOrganizations === undefined) ? [] : KPIOrganizations;
        if (EvaluationCriteria === null) {
            EvaluationCriteria = {
                Weight: null,
                PointFull: null,
                PointPass: null,
                VeryLow: {
                    Target: null,
                    Description: null,
                    Result: null
                },
                Low: {
                    Target: null,
                    Description: null,
                    Result: null
                },
                Mid: {
                    Target: null,
                    Description: null,
                    Result: null
                },
                High: {
                    Target: null,
                    Description: null,
                    Result: null
                },
                VeryHigh: {
                    Target: null,
                    Description: null,
                    Result: null
                }
            }
        }
        console.log({
            KPIID: KPIID,
            KPIName: KPIName,
            Description: Description,
            Year: Year,
            KPIMainID: KPIMainID,
            Weight: EvaluationCriteria.Weight,
            PointFull: EvaluationCriteria.PointFull,
            PointPass: EvaluationCriteria.PointPass,
            HasFile: HasFile,
            KPIPersons: KPIPersons,
            KPIOrganizations: KPIOrganizations,
            EvaluationCriteria: EvaluationCriteria,
            FormID: FormID,
            KPIProject: MapData,
            DelKPIPersons: DelKPIPersons,
            DelKPIOrganizations: DelKPIOrganizations,
            DelKPIProject: DelMapData
        })
        const UPDATEKPIZ = `
            mutation UpdateKPIZ(
                $KPIID: Int!,
                $KPIName: String!,
                $Description: String,
                $Year: Int,
                $KPIMainID: Int!,
                $Weight: Int,
                $PointFull: Int,
                $PointPass: Int,
                $HasFile: Boolean,
                $KPIPersons: [InputKPIPersons],
                $KPIOrganizations: [InputKPIOrganizations],
                $EvaluationCriteria: InputEvaluationCriteria
                $FormID: Int,
                $KPIProject: [InputKPIProject],
                $DelKPIPersons: [InputDelKPIPersons],
                $DelKPIOrganizations: [InputDelKPIOrganizations],
                $DelKPIProject: [InputDelKPIProject]
            ){
                UpdateKPIZ(
                    KPIID: $KPIID,
                    KPIName: $KPIName,
                    Description: $Description,
                    Year: $Year,
                    KPIMainID: $KPIMainID,
                    Weight: $Weight,
                    PointFull: $PointFull,
                    PointPass: $PointPass,
                    HasFile: $HasFile,
                    KPIPersons: $KPIPersons,
                    KPIOrganizations: $KPIOrganizations,
                    EvaluationCriteria: $EvaluationCriteria,
                    FormID: $FormID,
                    KPIProject: $KPIProject
                    DelKPIPersons: $DelKPIPersons,
                    DelKPIOrganizations: $DelKPIOrganizations,
                    DelKPIProject: $DelKPIProject
                ){
                    KPIID
                }
            }
        `;
        return Axios.post('/pmqaservice', {
            query: UPDATEKPIZ,
            variables: {
                KPIID: KPIID,
                KPIName: KPIName,
                Description: Description,
                Year: Year,
                KPIMainID: KPIMainID,
                Weight: EvaluationCriteria.Weight,
                PointFull: EvaluationCriteria.PointFull,
                PointPass: EvaluationCriteria.PointPass,
                HasFile: HasFile,
                KPIPersons: KPIPersons,
                KPIOrganizations: KPIOrganizations,
                EvaluationCriteria: EvaluationCriteria,
                FormID: FormID,
                KPIProject: MapData,
                DelKPIPersons: DelKPIPersons,
                DelKPIOrganizations: DelKPIOrganizations,
                DelKPIProject: DelMapData
            },
            errorPolicy: 'all'
        })
    }

    createKPI(
        KPIName,
        Description,
        Year,
        KPIMainID,
        EvaluationCriteria,
        HasFile,
        KPIPersons,
        KPIOrganizations,
        FormID,
        MapData
    ) {
        KPIName = (!KPIName) ? null : KPIName.trim();
        Year = (Year === null || Year === 0) ? 0 : Year;
        HasFile = (HasFile === null || HasFile === undefined) ? false : HasFile;
        KPIPersons = (KPIPersons === null || KPIPersons === undefined) ? [] : KPIPersons;
        KPIOrganizations = (KPIOrganizations === null || KPIOrganizations === undefined) ? [] : KPIOrganizations;
        if (EvaluationCriteria === null) {
            EvaluationCriteria = {
                Weight: null,
                PointFull: null,
                PointPass: null,
                VeryLow: {
                    Target: null,
                    Description: null,
                    Result: null
                },
                Low: {
                    Target: null,
                    Description: null,
                    Result: null
                },
                Mid: {
                    Target: null,
                    Description: null,
                    Result: null
                },
                High: {
                    Target: null,
                    Description: null,
                    Result: null
                },
                VeryHigh: {
                    Target: null,
                    Description: null,
                    Result: null
                }
            }
        }

        console.log({
            KPIName: KPIName,
            Description: Description,
            Year: Year,
            KPIMainID: KPIMainID,
            HasFile: HasFile,
            KPIPersons: KPIPersons,
            KPIOrganizations: KPIOrganizations,
            EvaluationCriteria: EvaluationCriteria,
            FormID: FormID,
            KPIProject: MapData
        })
        const CREATEKPI = `
            mutation CreateKPI(
                $KPIName: String!,
                $Description: String,
                $Year: Int,
                $KPIMainID: Int!,
                $HasFile: Boolean,
                $KPIPersons: [InputKPIPersons],
                $KPIOrganizations: [InputKPIOrganizations],
                $EvaluationCriteria: InputEvaluationCriteria,
                $FormID: Int,
                $KPIProject: [InputKPIProject]
            ){
                CreateKPI(
                    KPIName: $KPIName,
                    Description: $Description,
                    Year: $Year,
                    KPIMainID: $KPIMainID,
                    HasFile: $HasFile,
                    KPIPersons: $KPIPersons,
                    KPIOrganizations: $KPIOrganizations,
                    EvaluationCriteria: $EvaluationCriteria
                    FormID: $FormID,
                    KPIProject: $KPIProject
                ){
                    KPIID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: CREATEKPI,
            variables: {
                KPIName: KPIName,
                Description: Description,
                Year: Year,
                KPIMainID: KPIMainID,
                HasFile: HasFile,
                KPIPersons: KPIPersons,
                KPIOrganizations: KPIOrganizations,
                EvaluationCriteria: EvaluationCriteria,
                FormID: FormID,
                KPIProject: MapData
            },
            errorPolicy: 'all'
        })
    }

    deleteKPI(KPIID) {
        const DELETEKPI = `
            mutation DeleteKPI($KPIID: Int!){
                DeleteKPI(KPIID: $KPIID){
                    KPIID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: DELETEKPI,
            variables: {
                KPIID: parseInt(KPIID)
            },
            errorPolicy: 'all'
        });
    }

    deleteKPIZ(KPIID) {
        const DELETEKPIZ = `
            mutation DeleteKPIZ($KPIID: Int!){
                DeleteKPIZ(KPIID: $KPIID){
                    KPIID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: DELETEKPIZ,
            variables: {
                KPIID: parseInt(KPIID)
            },
            errorPolicy: 'all'
        });
    }

    getListKPIinKPIMain(KPIMainID) {
        const GETLISTKPIINKPIMAIN = `
            query GetListKPIinKPIMain($KPIMainID : Int!){
                GetListKPIinKPIMain(KPIMainID : $KPIMainID){
                    KPIID
                    KPIName
                    EvaluationCriteria{
                        Weight
                        Type
                    }
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: GETLISTKPIINKPIMAIN,
            variables: {
                KPIMainID: parseInt(KPIMainID)
            },
            errorPolicy: 'all'
        });
    }

    getListStrategy(Year, EvaluationTypeID) {
        Year = (Year) ? Year : null;
        EvaluationTypeID = (EvaluationTypeID) ? EvaluationTypeID : null;
        const GETLISTSTRAtEGY = `
            query GetListStrategy(
                $Year: Int
                $EvaluationTypeID: Int
            ){
                GetListStrategy(
                    Year: $Year
                    EvaluationTypeID: $EvaluationTypeID
                ){
                    StrategyID
                    StrategyName
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: GETLISTSTRAtEGY,
            variables: {
                Year: Year,
                EvaluationTypeID: EvaluationTypeID
            }
        });
    }

    getListObjective(StrategyID, Year, EvaluationTypeID) {
        StrategyID = (StrategyID) ? StrategyID : null;
        Year = (Year) ? Year : null;
        EvaluationTypeID = (EvaluationTypeID) ? EvaluationTypeID : null;
        const GETLISTOBJECTIVE = `
            query GetListObjective(
                $EvaluationTypeID: Int
                $Year: Int
                $StrategyID : Int
            ){
                GetListObjective(
                    EvaluationTypeID: $EvaluationTypeID
                    Year: $Year
                    StrategyID: $StrategyID
                ){
                    StrategyID
                    ObjectiveID
                    ObjectiveName
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: GETLISTOBJECTIVE,
            variables: {
                StrategyID: StrategyID,
                Year: Year,
                EvaluationTypeID: EvaluationTypeID
            }
        });
    }
}

export default new KPIService();