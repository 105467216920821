import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import Collapse from 'react-bootstrap/Collapse';
import FormManageCopyModal from './FormManage_copyModal';
import FormManageCopyProject from './FormManage_ProjectModal';
import FroalaEditor from '../../../Utilities/FroalaEditor';

class FormManageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showProjectModal: false,
            FormType: [],
            ProfileName: '',
            Remark: '',
            Sections: [{ SectionName: 'ขั้นตอนที่ 1', FormObjects: [] }],
            DelSections: [],
            currentSection: 0,
            currentForm: 0,
            manageSectionName: null,
            canUpdate: false,
            Editable: false
        }
    }

    componentDidMount() {
        this.props.getFormType()
            .then(res => {
                console.log(res);
                res = res.data;
                this.setState({
                    FormType: [...res.GetFormType]
                })
            })
            .catch(err => {
                console.log(err);
            });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onHideModal = () => {
        var title = (this.props.currentFormID) ? 'ยกเลิกการแก้ไขฟอร์มรายงาน' : 'ยกเลิกการเพิ่มฟอร์มรายงาน'
        var text = 'ต้องการ' + title + 'หรือไม่ ?'
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then((res) => {
                if (res.value) {
                    this.props.hide(this.resetState);
                }
            })
    }

    onEnterModal = () => {
        if (this.props.currentFormID !== null)
            this.props.getFormDetailZ(this.props.currentFormID)
                .then(result => {
                    console.log(result.data.GetFormDetailZ)
                    var data = result.data.GetFormDetailZ;
                    console.log(data);
                    // else {
                    //     /* ------------- __typename --------------- */
                    //     for (var i = 0; i < data.Sections.length; i++) {
                    //         console.log(data.Sections[i])
                    //         delete data.Sections[i].__typename;
                    //         for (var j = 0; j < data.Sections[i].FormObjects.length; j++) {
                    //             console.log(j)
                    //             delete data.Sections[i].FormObjects[j].__typename;
                    //         }
                    //     }
                    //     /* --------------------------------------- */
                    // }
                    try {
                        this.setState({
                            ProfileName: data.FormName,
                            Remark: data.Remark,
                            Sections: (data.Sections) ? [...data.Sections] : [{ SectionName: 'ขั้นตอนที่ 1', FormObjects: [] }],
                            DelSections: [],
                            canUpdate: true,
                            currentSection: 0,
                            currentForm: 0,
                            Editable: (data.Editable || false)
                        });
                    }
                    catch (e) {
                        console.log(e);
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            type: 'error'
                        })
                        this.setState({ canUpdate: true }, () => {
                            this.props.hide(this.resetState);
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    })
                    this.setState({ canUpdate: true }, () => {
                        this.props.hide(this.resetState);
                    })
                })
        else
            this.setState({
                canUpdate: true,
                Editable: true
            });
    }

    checkUpdateFormObjects = () => {
        var sections = this.state.Sections;
        for (var i = 0; i < sections.length; i++) {
            for (var j = 0; j < sections[i].FormObjects.length; j++) {
                if (sections[i].FormObjects[j].Header === '' || sections[i].FormObjects[j].Header.trim() === '') {
                    Swal.fire({
                        title: 'กรุณาตรวจสอบข้อมูล',
                        text: 'กรอกชื่อขั้นตอนย่อยในขั้นตอนที่ ' + (parseInt(i) + 1) + ' ขั้นตอนย่อยที่ ' + (parseInt(j) + 1),
                        type: 'warning',
                        showCloseButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'ไปยังขั้นตอนย่อย',
                        cancelButtonText: 'ตกลง',
                        reverseButtons: true
                    }).then(res => {
                        if (res.value) {
                            this.setState({
                                currentSection: parseInt(i),
                                currentForm: parseInt(j)
                            });
                        }
                    });
                    return false;
                }
                else if (sections[i].FormObjects[j].FormTypeID === 0) {
                    Swal.fire({
                        title: 'กรุณาตรวจสอบข้อมูล',
                        text: 'เลือกชนิดของ Content ในขั้นตอนที่ ' + (parseInt(i) + 1) + ' ขั้นตอนย่อยที่ ' + (parseInt(j) + 1),
                        type: 'warning',
                        showCloseButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'ไปยังขั้นตอนย่อย',
                        cancelButtonText: 'ตกลง',
                        reverseButtons: true
                    }).then(res => {
                        if (res.value) {
                            this.setState({
                                currentSection: parseInt(i),
                                currentForm: parseInt(j)
                            });
                        }
                    })
                    return false;
                }
            }
        }
        return true;
    }

    checkUpdate = () => {
        var title = 'เกิดข้อผิดพลาด';
        var text = 'เกิดข้อผิดพลาด';
        var type = null;
        var hasFormTypeToEvaluate = false;
        for (var i = 0; i < this.state.Sections.length; i++) {
            if (this.state.Sections[i].FormObjects.length === 0) {
                title = 'กรุณาตรวจสอบข้อมูล';
                text = 'กรุณาสร้างขั้นตอนย่อยในขั้นตอนที่ ' + (i + 1);
                type = 'warning';
                break;
            }
            for (var j = 0; j < this.state.Sections[i].FormObjects.length; j++) {
                if (this.state.Sections[i].FormObjects[j].FormTypeID === 2 || this.state.Sections[i].FormObjects[j].FormTypeID === 3) {
                    hasFormTypeToEvaluate = true;
                    break;
                }
            }
        }
        if (this.state.ProfileName === '' || this.state.ProfileName.trim() === '') {
            title = 'กรุณากรอกชื่อโปรไฟล์';
            text = 'กรุณากรอกชื่อโปรไฟล์';
            type = 'warning';
        }
        if (type === null) {
            if (!hasFormTypeToEvaluate) {
                return Swal.fire({
                    title: 'แบบฟอร์มนี้ไม่สามารถนำไปคำนวณผลได้',
                    text: 'แบบฟอร์มนี้จะไม่สามารถนำไปคำนวณผลได้เนื่องจากไม่มีฟอร์มประเภท 2 หรือ 3 ต้องการดำเนินการต่อหรือไม่ ?',
                    type: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'ยกเลิก',
                    confirmButtonText: 'ตกลง',
                    reverseButtons: true
                })
                    .then(res => {
                        console.log(res)
                        if (res.value) return Promise.resolve(true);
                        else return Promise.resolve(false)
                    })
            }
            return Promise.resolve(true);
        }
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return Promise.resolve(false);
        }
    }

    onSave = () => {
        console.log({ ...this.state.Sections, ProfileName: this.state.ProfileName, Remark: this.state.Remark });
        this.checkUpdate().then(value => {
            if (!value) return;
            if (this.checkUpdateFormObjects() === false)
                return;
            var formService = (this.props.currentFormID === null) ?
                formService = this.props.createForm(this.state.ProfileName, this.state.Remark, this.state.Sections)
                : formService = this.props.updateForm(this.props.currentFormID, this.state.ProfileName, this.state.Remark, this.state.Sections, this.state.DelSections);

            formService
                .then(result => {
                    console.log(result)
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        });
                    }
                    else {
                        Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            type: 'success'
                        })
                        if (this.props.currentFormID === null)
                            this.props.hide(this.resetState);
                        else
                            this.onEnterModal();
                    }
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างเรียกข้อมูล',
                        type: 'error'
                    })
                })
        })
            .catch(e => {
                console.log(e);
                // Swal.fire({
                //     title: 'เกิดข้อผิดพลาด',
                //     text: 'เกิดข้อผิดพลาดระหว่างเรียกข้อมูล',
                //     type: 'error'
                // });
            });
    }

    resetState = () => {
        this.setState({
            show: false,
            showProjectModal: false,
            ProfileName: '',
            Remark: '',
            Sections: [{ SectionName: 'ขั้นตอนที่ 1', FormObjects: [] }],
            currentSection: 0,
            currentForm: 0,
            manageSectionName: null,
            DelSections: [],
            canUpdate: false,
            Editable: false
        });
    }

    managecurrentSection = (index) => {
        if (this.state.currentSection === index)
            return;
        this.setState({
            currentSection: index,
            currentForm: null
        });
    }

    managecurrentForm = (index) => {
        if (this.state.currentForm === index) {
            this.setState({ currentForm: null });
            return;
        }
        this.setState({ currentForm: index });
    }

    creatFormObject = () => {
        return {
            Header: '',
            FormTypeID: 0,
            Content: '',
            Footer: '',
            //---------------------- not used -------------------------- //
            Computable: false,
            HasResult: false,
            //---------------------- not used -------------------------- //
            FormObjectItems: []
        }
    }

    createFormObjectItems = () => {
        return {
            FormObjectItemName: '',
            TargetText: '',
            TargetValue: null,
        }
    }

    addForm = () => {
        var newSections = [...this.state.Sections];
        var section = { ...newSections[this.state.currentSection] }
        var newFormObjects = [...section.FormObjects];
        if (newFormObjects.length === 6) {
            Swal.fire({
                title: 'ไม่สามารถเพิ่มขั้นตอนย่อย',
                text: 'ไม่สามารถเพิ่มขั้นตอนย่อยได้ สามารถเพิ่มขั้นตอนย่อยได้สูงสุด 6 ขั้นตอนย่อย',
                type: 'warning',
                confirmButtonText: 'ตกลง'
            })
            return;
        }
        newSections[this.state.currentSection] = section;
        section.FormObjects = newFormObjects;
        newFormObjects.push(this.creatFormObject());
        this.setState({ Sections: newSections });
    }

    createDelSection = (SectionID, SelfDelete) => {
        return {
            SectionID: SectionID,
            SelfDelete: SelfDelete,
            DelFormObjects: []
        }
    }

    createDelFormObject = (FormObjectID, SelfDelete) => {
        return {
            FormObjectID: FormObjectID,
            SelfDelete: SelfDelete,
            DelFormObjectItems: []
        }
    }

    createDelFormObjectItem = (FormObjectItemID) => {
        return {
            FormObjectItemID: FormObjectItemID
        }
    }

    removeForm = (index, FormObjectID) => {
        console.log(FormObjectID)
        Swal.fire({
            type: 'warning',
            title: 'ยืนยันการลบขั้นตอนย่อย',
            text: 'คุณต้องการลบขั้นตอนย่อยนี้หรือไม่ ?',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    var newSections = [...this.state.Sections];
                    var section = { ...newSections[this.state.currentSection] };
                    var newFormObjects = [...section.FormObjects];
                    newSections[this.state.currentSection] = section;
                    section.FormObjects = newFormObjects;
                    var newDelSections = [...this.state.DelSections];
                    //---------------------------- DelSections --------------------------//
                    if (FormObjectID) {
                        console.log('has FormObjectID');
                        var newDelSection = newDelSections.find(x => x.SectionID === section.SectionID);
                        var newDelFormObjects;
                        var newDelFormObject;
                        // ----------------------- hasDelSection ------------------------//
                        if (newDelSection) {
                            console.log('has DelSection');
                            newDelFormObjects = [...newDelSection.DelFormObjects];
                            newDelFormObject = newDelFormObjects.find(x => x.FormObjectID === FormObjectID);
                            if (newDelFormObject) {
                                newDelFormObject.SelfDelete = true;
                            }
                            else {
                                newDelFormObject = this.createDelFormObject(FormObjectID, true);
                                newDelFormObjects.push(newDelFormObject);
                            }
                            for (var i of newFormObjects[index].FormObjectItems) {
                                if (i.FormObjectItemID)
                                    newDelFormObject.DelFormObjectItems.push(this.createDelFormObjectItem(i.FormObjectItemID));
                            }
                            newDelSection.DelFormObjects = newDelFormObjects;
                        }
                        //------------------------ newDelSection ------------------------//
                        else {
                            console.log('new DelSection');
                            newDelSection = this.createDelSection(section.SectionID, false);
                            newDelFormObjects = [];
                            newDelFormObject = this.createDelFormObject(FormObjectID, true);
                            for (var i of newFormObjects[index].FormObjectItems) {
                                if (i.FormObjectItemID)
                                    newDelFormObject.DelFormObjectItems.push(this.createDelFormObjectItem(i.FormObjectItemID));
                            }

                            newDelFormObjects.push(newDelFormObject);
                            newDelSection.DelFormObjects = newDelFormObjects;
                            newDelSections.push(newDelSection);
                        }
                        console.log(newDelSections);
                    }
                    //---------------------------- DelSections --------------------------//
                    newFormObjects.splice(index, 1);

                    this.setState({
                        Sections: newSections,
                        DelSections: newDelSections,
                        currentForm: null
                    });
                }
            })
    }

    changeFormOrder = (index, type) => {
        var newSections = [...this.state.Sections];
        var section = { ...newSections[this.state.currentSection] }
        var newFormObjects = [...section.FormObjects];
        newSections[this.state.currentSection] = section;
        section.FormObjects = newFormObjects;
        var temp;
        var newcurrentForm = this.state.currentForm;
        if (type === 'up') {
            if (index === 0)
                return;
            temp = newFormObjects[index];
            newFormObjects[index] = newFormObjects[index - 1];
            newFormObjects[index - 1] = temp;
            if (newcurrentForm === index)
                newcurrentForm -= 1;
            else if (newcurrentForm + 1 === index && newcurrentForm !== null)
                newcurrentForm += 1;
        }
        else {
            if (index === newFormObjects.length - 1)
                return;
            temp = newFormObjects[index];
            newFormObjects[index] = newFormObjects[index + 1];
            newFormObjects[index + 1] = temp;
            if (newcurrentForm === index)
                newcurrentForm += 1;
            else if (newcurrentForm - 1 === index && newcurrentForm !== null)
                newcurrentForm -= 1;
        }
        this.setState({
            Sections: newSections,
            currentForm: newcurrentForm
        }, () => {
            console.log(this.state.Sections);
        });
    }

    onChangeContent = (type, value, index) => {
        console.log(value)
        var newSections = [...this.state.Sections];
        var section = { ...newSections[this.state.currentSection] };
        var newFormObjects = [...section.FormObjects];
        var formObject = { ...newFormObjects[index] };
        if (type === 'FormTypeID') {
            if (formObject[type] === parseInt(value))
                return;
            formObject[type] = parseInt(value);
            switch (formObject[type]) {
                case 0:
                case 1: {
                    formObject.HasResult = false;
                    formObject.Computable = false;
                    break;
                }
                case 2:
                case 3: {
                    formObject.HasResult = true;
                    formObject.Computable = true;
                    break;
                }
                case 4: {
                    formObject.HasResult = true;
                    formObject.Computable = false;
                    break;
                }
                case 5: {
                    formObject.HasResult = true;
                    formObject.Computable = false;
                    break;
                }
                case 6:
                case 7: {
                    formObject.HasResult = true;
                    formObject.Computable = true;
                    break;
                }
                case 8: {
                    formObject.HasResult = true;
                    formObject.Computable = false;
                    break;
                }
                default: break;
            }
            formObject.Content = '';
            formObject.FormObjectItems = [];
        }
        else {
            formObject[type] = value;
        }
        newFormObjects[index] = formObject;
        section.FormObjects = newFormObjects;
        newSections[this.state.currentSection] = section;
        this.setState({ Sections: newSections }, () => {
            console.log(this.state.Sections)
        });
    }

    onChangeContentItem = (type, value, index) => {
        // ----------- Spread to new variable ------------------ //
        var newSections = [...this.state.Sections];
        var section = { ...newSections[this.state.currentSection] };
        var newFormObjects = [...section.FormObjects];
        var newFormObject = { ...newFormObjects[this.state.currentForm] };
        var newFormObjectitems = [...newFormObject.FormObjectItems];
        var newFormObjectitem = { ...newFormObjectitems[index] };

        newSections[this.state.currentSection] = section;
        section.FormObjects = newFormObjects;
        newFormObjects[this.state.currentForm] = newFormObject;
        newFormObject.FormObjectItems = newFormObjectitems;
        newFormObjectitems[index] = newFormObjectitem;

        if (type === 'TargetValue')
            value = parseInt(value);
        newFormObjectitem[type] = value;
        this.setState({ Sections: newSections });
    }

    checkEditableContent = (FormTypeID) => {
        switch (FormTypeID) {
            case 2:
            case 3:
            case 4:
            case 8: {
                return 'FromManage-content-disabled';
            }
            default: return '';
        }
    }

    createSectionObject = (index) => {
        return {
            SectionName: 'ขั้นตอนที่ ' + index,
            FormObjects: []
        }
    }

    addSection = () => {
        if (this.state.Sections.length === 6)
            return
        var newSections = [...this.state.Sections];
        var section = this.createSectionObject((newSections.length + 1))
        newSections.push(section);
        this.setState({ Sections: newSections });
    }

    confirmRemoveSection = (index, SectionID, isSetNewState) => {
        console.log(SectionID)
        Swal.fire({
            type: 'warning',
            title: 'ยืนยันการลบขั้นตอน',
            text: 'ต้องการลบขั้นตอนนี้หรือไม่ ?',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    this.removeSection(index, SectionID, isSetNewState);
                }
            })
    }

    removeSection = (index, SectionID, isSetNewState) => {
        var newSection = [...this.state.Sections];
        var newcurrentSection = this.state.currentSection;
        var newDelSections = [...this.state.DelSections];

        //--------------------- DelSections --------------------//
        if (SectionID) {
            console.log('has SectionID');
            var newDelSection = newDelSections.find(x => x.SectionID === SectionID);
            //------------------- hasDelSection ---------------------//
            if (newDelSection) {
                newDelSection = { ...newDelSection };
                newDelSection.SelfDelete = true;
                for (var i of newDelSection.DelFormObjects) {
                    i.SelfDelete = true;
                }
            }
            //------------------- noDelSection ---------------------//
            else {
                newDelSection = this.createDelSection(SectionID, true);
                newDelSections.push(newDelSection);
            }
            for (var i of newSection[index].FormObjects) {
                if (i.FormObjectID) {
                    var newDelFormObject = this.createDelFormObject(i.FormObjectID, true);
                    for (var j of i.FormObjectItems) {
                        if (j.FormObjectItemID)
                            newDelFormObject.DelFormObjectItems.push(this.createDelFormObjectItem(j.FormObjectItemID));
                    }
                    newDelSection.DelFormObjects.push(newDelFormObject)
                }
            }
            console.log(newDelSection);
        }
        //--------------------- DelSections --------------------//
        if (isSetNewState) {
            newSection.splice(index, 1);
            if (newcurrentSection >= newSection.length)
                newcurrentSection -= 1;
            this.setState({
                currentSection: newcurrentSection,
                DelSections: newDelSections,
                Sections: newSection
            }, () => {
                console.log(this.state.DelSections)
            });
        }
    }

    addFormObjectItem = (objectName) => {
        var newSections = [...this.state.Sections];
        var section = { ...newSections[this.state.currentSection] };
        var newFormObjects = [...section.FormObjects];
        var newFormObject = { ...newFormObjects[this.state.currentForm] };
        newSections[this.state.currentSection] = section;
        section.FormObjects = newFormObjects;
        newFormObjects[this.state.currentForm] = newFormObject;
        newFormObject.FormObjectItems.push(this.createFormObjectItems());
        if (objectName) {
            newFormObject.FormObjectItems[(newFormObject.FormObjectItems.length - 1)].FormObjectItemName = objectName;
        }
        console.log(newSections);
        this.setState({ Sections: newSections });
    }

    removeFormObjectItem = (index, FormObjecItemID, skipCheck) => {
        var check = (skipCheck) ?
            Promise.resolve({ value: true })
            :
            Swal.fire({
                type: 'warning',
                title: 'ยืนยันการลบข้อมูลในขั้นตอนย่อย',
                text: 'ต้องการลบข้อมูลนี้หรือไม่ ?',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                reverseButtons: true
            });

        return check
            .then(res => {
                if (res.value) {
                    var newSections = [...this.state.Sections];
                    var section = { ...newSections[this.state.currentSection] };
                    var newFormObjects = [...section.FormObjects];
                    var newFormObject = { ...newFormObjects[this.state.currentForm] };
                    newSections[this.state.currentSection] = section;
                    section.FormObjects = newFormObjects;
                    newFormObjects[this.state.currentForm] = newFormObject;
                    var newDelSections = [...this.state.DelSections];
                    //---------------------------- DelSections --------------------------//
                    if (FormObjecItemID) {
                        var newDelSection = newDelSections.find(x => x.SectionID === section.SectionID);
                        var newDelFormObjects;
                        var newDelFormObject;
                        //------------------------ hasDelSection ---------------------------//
                        if (newDelSection) {
                            console.log('has DelSection');
                            newDelSection = { ...newDelSection };
                            newDelFormObjects = [...newDelSection.DelFormObjects];
                            newDelFormObject = newDelFormObjects.find(x => x.FormObjectID = newFormObject.FormObjectID);
                            if (newDelFormObject) {
                                newDelFormObject = { ...newDelFormObject };
                                newDelFormObject.DelFormObjectItems.push(this.createDelFormObjectItem(FormObjecItemID));
                            }
                            else {
                                newDelFormObject = this.createDelFormObject(newFormObject.FormObjectID, false);
                                newDelFormObject.DelFormObjectItems.push(this.createDelFormObjectItem(FormObjecItemID));
                                newDelFormObjects.push(newDelFormObject);
                            }
                            newDelSection.DelFormObjects = newDelFormObjects;
                        }
                        //------------------------ noDelSection ---------------------------//
                        else {
                            console.log('no DelSection');
                            newDelSection = this.createDelSection(section.SectionID, false);
                            newDelFormObject = this.createDelFormObject(newFormObject.FormObjectID, false);
                            newDelFormObject.DelFormObjectItems.push(this.createDelFormObjectItem(FormObjecItemID));
                            newDelSection.DelFormObjects.push(newDelFormObject);
                            newDelSections.push(newDelSection);
                        }
                        console.log(newDelSection);
                    }
                    //---------------------------- DelSections --------------------------//
                    newFormObject.FormObjectItems.splice(index, 1);

                    return new Promise((resolve) => {
                        this.setState({
                            Sections: newSections,
                            DelSections: newDelSections
                        }, () => {
                            resolve();
                        });
                    })
                }
            })
    }

    onClickSectionName = (index) => {
        this.setState({ manageSectionName: index });
    }

    onBlurSectionName = (e, index) => {
        if (e.target.value.length === 0) {
            var newSections = [...this.state.Sections];
            newSections[index] = { ...this.state.Sections[index] };
            newSections[index].SectionName = 'ชื่อขั้นตอน'
            this.setState({ manageSectionName: null, Sections: newSections });
            return;
        }
        this.setState({ manageSectionName: null })
    }

    onChangeSectionName = (e, index) => {
        if (e.target.value.length > 25)
            return;
        var newSection = [...this.state.Sections];
        newSection[index] = { ...this.state.Sections[index] };
        newSection[index].SectionName = e.target.value;
        this.setState({ Sections: newSection });
    }

    onKeyDownSectionName = (e, index) => {
        if (e.keyCode === 13) {
            e.target.blur();
        }
    }

    onChangeProfileName = (name, value) => {
        this.setState({ [name]: value });
    }

    checkStepProgressbar = (index) => {
        if (this.state.currentSection === index) {
            return 'is-active content' + (index + 1);
        }
        else return 'content' + (index + 1);
    }

    checkFormValid = (object) => {
        if (object === '' || object === 0)
            return 'is-invalid';
    }

    hideModal = (callback) => {
        (this.state.show === false) ?
            this.setState({ show: true })
            : this.setState({ show: false }, () => {
                if (typeof callback === 'function')
                    callback()
            });
    }

    copyForm = (FormID) => {
        return this.props.getFormDetailCopy(FormID)
            .then(result => {
                console.log(result.data.GetFormDetailZ)
                var data = result.data.GetFormDetailZ;
                console.log(data.Sections);
                this.setState({
                    Sections: (data.Sections) ? [...data.Sections] : [{ SectionName: 'ขั้นตอนที่ 1', FormObjects: [] }]
                });
                return;
            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                })
            })
    }

    hideProjectModal = (callback) => {
        (this.state.showProjectModal === false) ?
            this.setState({ showProjectModal: true })
            : this.setState({ showProjectModal: false }, () => {
                if (typeof callback === 'function')
                    callback()
            });
    }

    copyProject = async (ProjectKPI, callback) => {
        var sections = [...this.state.Sections];
        var section = { ...sections[this.state.currentSection] };
        var formObject = { ...section.FormObjects[this.state.currentForm] };
        var FormObjectItems = [...formObject.FormObjectItems];
        for (var i in FormObjectItems) {
            await this.removeFormObjectItem(0, FormObjectItems[i].FormObjectItemID, true);
        }
        for (var j of ProjectKPI) {
            console.log('bbbbbbbbbbbbbbbbbbbbbbb')
            console.log(j)
            this.addFormObjectItem(j.ProjectName);
        }
        if (callback && typeof callback === 'function')
            callback();
    }

    renderEditor = (object, index) => {
        switch (object.FormTypeID) {
            case 1: {
                return (
                    <FroalaEditor
                        model={object.Content}
                        onModelChange={this.onChangeContent}
                        onchange1={'Content'}
                        onchange2={index}
                    />
                );
            }
            case 2: {
                return (
                    <>
                        <div className='text-danger'>* ข้อมูลที่แสดงเป็นเพียงข้อมูลตัวอย่าง</div>
                        <table className='table mb-0 table-custom table-striped table-bordered table-hover'>
                            <thead align='center'>
                                <tr>
                                    <th className='align-middle'>ชื่อตัวชี้วัด</th>
                                    <th className='align-middle'>องค์ประกอบ</th>
                                    <th className='align-middle'>ค่าน้ำหนัก</th>
                                    <th className='align-middle'>เป้าหมาย</th>
                                    <th className='align-middle'>ผลการดำเนินงาน</th>
                                    <th className='align-middle'>คะแนน</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>การจัดทำแผนลุ่มน้ำ</td>
                                    <td>Functional Based</td>
                                    <td>0.5</td>
                                    <td>จัดทำแผนลุ่มน้ำ</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                );
            }
            case 3: {
                return (
                    <>
                        <div className='text-danger'>* ข้อมูลที่แสดงเป็นเพียงข้อมูลตัวอย่าง</div>
                        <table className='table mb-0 table-custom table-striped table-bordered table-hover align-middle'>
                            <thead align='center'>
                                <tr>
                                    <th rowSpan="2" className='align-middle'>ชื่อตัวชี้วัด</th>
                                    <th rowSpan="2" className='align-middle'>องค์ประกอบ</th>
                                    <th rowSpan="2" className='align-middle'>ค่าน้ำหนัก</th>
                                    <th colSpan="3" className='align-middle'>เป้าหมาย</th>
                                    <th rowSpan="2" className='align-middle'>ผลกรดำเนินงาน</th>
                                    <th rowSpan="2" className='align-middle'>คะแนน</th>
                                </tr>
                                <tr>
                                    <th className='align-middle'>ขั้นต้น</th>
                                    <th className='align-middle'>มาตรฐาน</th>
                                    <th className='align-middle'>ขั้นสูง</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>การจัดทำแผนลุ่มน้ำ</td>
                                    <td>Functional Based</td>
                                    <td>0.5</td>
                                    <td>ทำการสำรวจแหล่งน้ำ</td>
                                    <td>เตรียมการทำแผนลุ่มน้ำ</td>
                                    <td>จัดทำแผนลุ่มน้ำ</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                );
            }
            case 4: {
                return (
                    <FroalaEditor
                        model={object.Content}
                        onModelChange={this.onChangeContent}
                        onchange1={'Content'}
                        onchange2={index}
                    />
                );
            }
            case 5: {
                return (
                    <>
                        <div className='mb-2'>
                            <button className='btn btn-outline-primary mr-2' onClick={() => this.addFormObjectItem()}>เพิ่ม Row</button>
                            <button className='btn btn-outline-primary' onClick={this.hideProjectModal}>คัดลอกโครงการ</button>
                        </div>
                        <table className='table table-custom table-striped table-bordered table-hover'>
                            <thead align='center'>
                                <tr>
                                    <th style={{ minWidth: '300px' }}>โครงการ</th>
                                    <th>ผลการดำเนินงาน</th>
                                    <th>ปัญหา/อุปสรรค</th>
                                    <th style={{ width: '10%' }}>ลบ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    object.FormObjectItems.map((obj, index) => {
                                        return (
                                            <tr key={'FormManage-FormObjectItems' + index}>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='mr-2'>{(index + 1) + '.'}</div>
                                                        <input
                                                            className='form-control'
                                                            value={(obj.FormObjectItemName || '')}
                                                            name='FormObjectItemName'
                                                            onChange={e => this.onChangeContentItem(e.target.name, e.target.value, index)}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        className='form-control'
                                                        value={(obj.TargetText || '')}
                                                        name='TargetText'
                                                        onChange={e => this.onChangeContentItem(e.target.name, e.target.value, index)}
                                                        disabled
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='form-control'
                                                        value={(obj.Remark || '')}
                                                        name='Remark'
                                                        onChange={e => this.onChangeContentItem(e.target.name, e.target.value, index)}
                                                        disabled
                                                    />
                                                </td>
                                                <td align='center'>
                                                    <span
                                                        style={{ cursor: 'pointer' }}
                                                        className='delete-badge badge badge-danger border border-danger'
                                                        onClick={() => this.removeFormObjectItem(index, obj.FormObjectItemID)}
                                                    >
                                                        <i className='fa fa-trash'></i>
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                )
            }
            case 6: {
                return (
                    <>
                        <div className='mb-2'>
                            <textarea
                                placeholder='คำอธิบาย'
                                className='form-control'
                                value={object.Content}
                                onChange={e => this.onChangeContent('Content', e.target.value, index)}
                                rows={4}
                            />
                        </div>
                        <div className='mb-2'>
                            <button className='btn btn-outline-primary' onClick={() => this.addFormObjectItem()}>เพิ่ม Row</button>
                        </div>
                        <div className='table-responsive-xl'>
                            <table className='table table-custom table-striped table-bordered table-hover'>
                                <thead align='center'>
                                    <tr>
                                        <th style={{ minWidth: '300px' }}>ประเด็นการประเมิน</th>
                                        <th style={{ minWidth: '75px' }}>คะแนน</th>
                                        <th style={{ minWidth: '60px' }}>มี</th>
                                        <th style={{ minWidth: '60px' }}>ไม่มี</th>
                                        <th style={{ minWidth: '80px' }}>ลบ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        object.FormObjectItems.map((obj, index) => {
                                            return (
                                                <tr key={'FormManage-FormObjectItems' + index}>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='mr-2'>{(index + 1) + '.'}</div>
                                                            <input
                                                                className='form-control flex-fill'
                                                                value={(obj.FormObjectItemName || '')}
                                                                name='FormObjectItemName'
                                                                onChange={e => this.onChangeContentItem(e.target.name, e.target.value, index)}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            className='form-control'
                                                            value={(obj.TargetValue || '')}
                                                            name='TargetValue'
                                                            onChange={e => this.onChangeContentItem(e.target.name, (parseInt(e.target.value) || 0), index)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className='form-control'
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className='form-control'
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            className='delete-badge badge badge-danger border border-danger'
                                                            onClick={() => this.removeFormObjectItem(index, obj.FormObjectItemID)}
                                                        >
                                                            <i className='fa fa-trash'></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                )
            }
            case 7: {
                return (
                    <>
                        <div className='mb-2'>
                            <button className='btn btn-outline-primary' onClick={() => this.addFormObjectItem()}>เพิ่ม Row</button>
                        </div>
                        <table className='table table-custom table-striped table-bordered table-hover'>
                            <thead align='center'>
                                <tr>
                                    <th style={{ width: '35%' }}>โครงการ</th>
                                    <th style={{ width: '20%' }}>ทั้งหมด</th>
                                    <th style={{ width: '15%' }}>ผลดำเนินการ</th>
                                    <th style={{ width: '15%' }}>ส่วนต่าง</th>
                                    <th style={{ width: '10%' }}>ร้อยละ</th>
                                    <th>ลบ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    object.FormObjectItems.map((obj, index) => {
                                        return (
                                            <tr key={'FormManage-FormObjectItems' + index}>
                                                <td>
                                                    <input
                                                        className='form-control'
                                                        value={(obj.FormObjectItemName || '')}
                                                        name='FormObjectItemName'
                                                        onChange={e => this.onChangeContentItem(e.target.name, e.target.value, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='form-control'
                                                        value={(obj.TargetValue || 0)}
                                                        name='TargetValue'
                                                        onChange={e => this.onChangeContentItem(e.target.name, (parseInt(e.target.value) || 0), index)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='form-control'
                                                        disabled
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='form-control'
                                                        disabled
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='form-control'
                                                        disabled
                                                    />
                                                </td>
                                                <td align='center'>
                                                    <span
                                                        style={{ cursor: 'pointer' }}
                                                        className='delete-badge badge badge-danger border border-danger'
                                                        onClick={() => this.removeFormObjectItem(index, obj.FormObjectItemID)}
                                                    >
                                                        <i className='fa fa-trash'></i>
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                )
            }
            default: return null;
        }
    }

    renderFormObject = (object, index) => {
        if (object === undefined) return;
        return (
            <div className='mt-2 p-3 overflow-div'>
                <div className='row align-items-end'>
                    <div className='col-12 col-lg-6'>
                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i><label className='form-label mr-2'>Header : </label>
                            <input
                                placeholder='Header'
                                className={'form-control d-inline-block ' + this.checkFormValid(object.Header)}
                                value={(object.Header || '')}
                                onChange={(e) => this.onChangeContent('Header', e.target.value, index)}
                            />
                            <div className="invalid-feedback">
                                กรุณากรอกชื่อขั้นตอนย่อย
                            </div>
                        </div>
                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i><label className='form-label mr-2'>Content : </label>
                            <select
                                className={'form-control d-inline-block ' + this.checkFormValid(parseInt(object.FormTypeID))}
                                value={object.FormTypeID}
                                onChange={(e) => this.onChangeContent('FormTypeID', parseInt(e.target.value), index)}
                                disabled={(object.FormObjectID) ? true : false}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                {
                                    this.state.FormType.map((obj, index) => {
                                        return (
                                            <option value={obj.FormTypeID} key={'FormType' + obj.FormTypeID}>
                                                {(index + 1) + '. ' + obj.FormTypeName}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                            <div className="invalid-feedback">
                                กรุณาเลือกชนิดของ Content
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <div className='form-group'>
                            <div className='checkbox'>
                                <input
                                    type="checkbox"
                                    className="form-check d-inline-block ml-2"
                                    checked={object.HasResult}
                                    disabled
                                    readOnly
                                />
                                <label className="form-check-label d-inline-block noselect">ผู้รายงานสามารถแก้ไขได้</label>
                            </div>
                            <div className='checkbox'>
                                <input
                                    type="checkbox"
                                    className="form-check d-inline-block ml-2"
                                    checked={object.Computable}
                                    disabled
                                    readOnly
                                />
                                <label className="form-check-label d-inline-block noselect">ข้อมูลสามารถคำนวณได้</label>
                            </div>
                            <div className='checkbox'>
                                <input
                                    type="checkbox"
                                    className="form-check d-inline-block ml-2"
                                    checked={(object.FormTypeID === 2 || object.FormTypeID === 3)}
                                    disabled
                                    readOnly
                                />
                                <label className="form-check-label d-inline-block noselect">ข้อมูลสามารถนำไปสรุปผลได้</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.checkEditableContent(object.FormTypeID)}>
                    {this.renderEditor(object, index)}
                </div>
                <div className='form-group'>
                    <label className='form-label mt-2'>Footer : </label>
                    <textarea
                        className='form-control d-inline-block'
                        rows='3'
                        value={(object.Footer || '')}
                        onChange={(e) => this.onChangeContent('Footer', e.target.value, index)}
                    />
                </div>
            </div>
        )
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='xl' onEnter={this.onEnterModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className='d-flex'>
                            {
                                (this.props.currentFormID === null) ?
                                    'เพิ่มฟอร์มการประเมิน' :
                                    'แก้ไขฟอร์มการประเมิน'
                            }

                        </Modal.Title>
                        {
                            (this.props.currentFormID) ?
                                null :
                                <div className='d-flex flex-fill justify-content-end mr-3'>
                                    <button className='btn btn-outline-primary' onClick={this.hideModal}>คัดลอกแบบฟอร์ม</button>
                                </div>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='form form-group align-center col-12 col-lg-6'>
                                <i className='text-danger mr-1'>*</i><label className='form-label'>ชื่อโปรไฟล์</label>
                                <input
                                    className='form-control'
                                    placeholder='ชื่อโปรไฟล์'
                                    name='ProfileName'
                                    value={this.state.ProfileName}
                                    onChange={(e) => this.onChangeProfileName(e.target.name, e.target.value)}
                                />
                            </div>
                            <div className='form form-group align-center col-12 col-lg-6'>
                                <label className='form-label'>คำอธิบาย</label>
                                <input
                                    className='form-control'
                                    name='Remark'
                                    placeholder='คำอธิบาย'
                                    value={this.state.Remark}
                                    onChange={(e) => this.onChangeProfileName(e.target.name, e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='status-bar-wrapper'>
                            <ul className={"status-bar form-manage-modal-status-bar " + ((this.state.Sections.length > 4) ? 'expanded4' : ((this.state.Sections.length > 3) ? 'expanded3' : ''))}>
                                {
                                    this.state.Sections.map((obj, index) => {
                                        var el = [];
                                        if (this.state.manageSectionName === (index))
                                            el.push(
                                                <input
                                                    key={'FormManage-modal-section-name' + index}
                                                    style={{ width: '7rem', height: '25px' }}
                                                    className='d-inline-block mr-2 form-control'
                                                    value={obj.SectionName} onBlur={(e) => this.onBlurSectionName(e, index)}
                                                    placeholder='ชื่อ section' onKeyDown={this.onKeyDownSectionName}
                                                    onChange={(e) => this.onChangeSectionName(e, index)}
                                                    autoFocus
                                                />
                                            );
                                        else
                                            el.push(
                                                <div
                                                    key={'FormManage-modal-section-name' + index}
                                                    onClick={() => this.onClickSectionName(index)}
                                                    className='d-inline-block mr-2'
                                                >{
                                                        obj.SectionName}
                                                </div>
                                            );
                                        if (this.state.Sections.length !== 1)
                                            // el.push(
                                            //     <span
                                            //         key={'FormManage-modal-section-delete' + index}
                                            //         style={{ cursor: 'pointer' }}
                                            //         className='delete-badge badge badge-danger border border-danger'
                                            //         onClick={() => this.confirmRemoveSection(index, obj.SectionID, true)}
                                            //     >
                                            //         <i className='fa fa-trash'></i>
                                            //     </span>
                                            // )
                                            return (
                                                <li key={'FormMange-modal-section-li' + index} className={this.checkStepProgressbar(index)}>
                                                    <div className={'status-bar-event-catcher ' + ((index === 0) ? 'first-catcher' : '') + ((index === 5) ? 'last-catcher' : '')} onClick={() => this.managecurrentSection(index)} />
                                                    <span className='status-bar-text'>
                                                        {el}
                                                    </span>
                                                </li>
                                            );
                                    })

                                }
                                {
                                    (this.state.Sections.length !== 6) ?
                                        <li className='is-active plus noselect' onClick={this.addSection}>
                                            <span className='status-bar-text'>
                                                <div>เพิ่มขั้นตอนหลัก</div>
                                            </span>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>
                        <div className='pl-4 mt-2'>
                            <button className='btn btn-outline-primary d-inline-block mr-4' onClick={this.addForm}>เพิ่มขั้นตอนย่อย</button>
                            <div className='d-inline-block text-danger'>* ข้อมูลจะสามารถนำไปสรุปผลได้ก็ต่อเมื่อมีฟอร์มประเภท 2 หรือ 3</div>
                            <div className='mt-3' style={{ height: '65vh', overflowY: 'auto', overflowX: 'hidden' }}>
                                {

                                    this.state.Sections[this.state.currentSection].FormObjects.map((obj, index) => {
                                        return (
                                            <div className='KPI-modal-card mr-2 input-transition' key={'FormNamage-form' + index}>
                                                <div>
                                                    <div className='float-right button-div-modal-card'>
                                                        <button className='mr-3 btn btn-outline-secondary btn-sm' onClick={() => this.changeFormOrder(index, 'up')}><i className='fa fa-arrow-up'></i></button>
                                                        <button className='mr-3 btn btn-outline-secondary btn-sm' onClick={() => this.changeFormOrder(index, 'down')}><i className='fa fa-arrow-down'></i></button>
                                                        <button className='mr-3 btn btn-outline-danger btn-sm' onClick={() => this.removeForm(index, obj.FormObjectID)}><i className='fa fa-trash'></i></button>
                                                    </div>
                                                    <div className='KPI-modal-card-title' onClick={() => this.managecurrentForm(index)}>
                                                        <h5 className='d-inline-block mr-4'>
                                                            <span className="badge badge-secondary">{index + 1}</span>
                                                        </h5>
                                                        {(obj.Header || '')}
                                                        <div className='float-right position-relative mt-1 mr-2'>
                                                            <i className={'text-secondary fa fa-chevron-left pr-1 ' + ((this.state.currentForm === index) ? 'hide' : '')} />
                                                            <i className={'text-secondary fa fa-chevron-down ' + ((this.state.currentForm === index) ? '' : 'hide')} />
                                                        </div>
                                                    </div>
                                                    <Collapse in={(this.state.currentForm === index)}>
                                                        <div>
                                                            {this.renderFormObject(obj, index)}
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </div>

                                        );
                                    })
                                }
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                            <button
                                className={'btn btn-outline-' + ((!this.state.Editable) ? 'secondary' : 'primary')}
                                onClick={this.onSave}
                                disabled={((!this.state.Editable) || false)}
                            >
                                บันทึก
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <FormManageCopyModal
                    show={this.state.show}
                    hide={this.hideModal}
                    copyForm={this.copyForm}
                />

                <FormManageCopyProject
                    show={this.state.showProjectModal}
                    hide={this.hideProjectModal}
                    copyProject={this.copyProject}
                />
            </>
        );
    }
}

export default FormManageModal;