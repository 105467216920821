import React from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';

class RoleManage extends React.Component {

    render() {
        return (
            <>
                <div className='custom-block mb-4'>
                    <div className='row align-items-end'>
                        <Form.Group className='col-12 col-lg-6'>
                            <Form.Label>สิทธิ์การใช้งาน</Form.Label>
                            <Form.Control type='text' placeholder='ชื่อโปรไฟล์' />
                        </Form.Group>

                        <div className='col-12 col-lg-6 form-group text-center'>
                            <button type='button' className='mr-2 btn btn-outline-primary'>ค้นหา</button>
                            <button type='button' className='mr-2 btn btn-outline-primary'>ยกเลิก</button>
                        </div>
                    </div>
                </div>

                <div className='custom-block mb-4'>
                    <Card className="card-custom-table">
                        <Card.Header>
                            <div className="float-right">
                                <div className="d-inline-block mr-1">แสดง </div>
                                <select className="form-control d-inline-block" onChange={this.changeOffset} style={{ width: '75px' }}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                                <div className="d-inline-block ml-1">Entries</div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover className="mb-0">
                                <thead>
                                    <tr style={{ textAlign: 'center' }}>
                                        <th style={{ width: '10%' }}>ลำดับ</th>
                                        <th style={{ width: '20%' }}>สิทธิ์การใช้งาน</th>
                                        <th style={{ width: '15%' }}>จำนวนผู้ใช้งาน</th>
                                        <th style={{ width: '10%' }}>แก้ไข/ลบ</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    <tr>
                                        <td>1</td>
                                        <td>Global Admin</td>
                                        <td>5</td>
                                        <td align='center'>
                                            <button onClick={this.showModalFunction} className='btn btn-outline-secondary mr-1'><i className='fa fa-pencil'></i></button>
                                            <button onClick={this.showModalFunction} className='btn btn-outline-danger'><i className='fa fa-trash'></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Admin</td>
                                        <td>10</td>
                                        <td align='center'>
                                            <button onClick={this.showModalFunction} className='btn btn-outline-secondary mr-1'><i className='fa fa-pencil'></i></button>
                                            <button onClick={this.showModalFunction} className='btn btn-outline-danger'><i className='fa fa-trash'></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>ผู้บริหาร</td>
                                        <td>3</td>
                                        <td align='center'>
                                            <button onClick={this.showModalFunction} className='btn btn-outline-secondary mr-1'><i className='fa fa-pencil'></i></button>
                                            <button onClick={this.showModalFunction} className='btn btn-outline-danger'><i className='fa fa-trash'></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>ผู้รับการประเมิน</td>
                                        <td>12</td>
                                        <td align='center'>
                                            <button onClick={this.showModalFunction} className='btn btn-outline-secondary mr-1'><i className='fa fa-pencil'></i></button>
                                            <button onClick={this.showModalFunction} className='btn btn-outline-danger'><i className='fa fa-trash'></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>ผู้ประเมิน</td>
                                        <td>6</td>
                                        <td align='center'>
                                            <button onClick={this.showModalFunction} className='btn btn-outline-secondary mr-1'><i className='fa fa-pencil'></i></button>
                                            <button onClick={this.showModalFunction} className='btn btn-outline-danger'><i className='fa fa-trash'></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <div className='d-inline-block'>
                                รายการที่แสดง : 1 - 5
                    </div>
                            <Pagination className="mb-0 float-right">
                                <Pagination.Item >1</Pagination.Item>
                                <Pagination.Item >2</Pagination.Item>
                                <Pagination.Next />
                                <Pagination.Last />
                            </Pagination>
                        </Card.Footer>
                    </Card>
                </div>
            </>
        );
    }
}

export default RoleManage;