import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import Autocomplete from '../../../Utilities/Autocomplete';

class OrganizationManageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSection: 0,
            OrganizationName: '',
            Description: '',
            OrganizationShortName: '',
            OrganizationCode: '',
            OrganizationLevel: 0,
            ParentOrganization: {},
            searchOrganization: {},
            Address: '',
            ZipCode: '',
            Tel: '',
            Fax: '',
            Email: '',
            Lat: 0,
            Lng: 0,
            canUpdate: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onHideModal = () => {
        var title = (this.props.currentOrganizationID) ? 'ยกเลิกการแก้ไขหน่วยงาน' : 'ยกเลิกการเพิ่มหน่วยงาน'
        var text = 'ต้องการ' + title + 'หรือไม่ ?'
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    this.props.hide(this.resetState);
                }
            })
    }

    onEnterModal = () => {
        if (!this.props.currentOrganizationID) {
            this.setState({ canUpdate: true });
            return
        }

        this.props.getOrganizationDetail(this.props.currentOrganizationID)
            .then(result => {
                console.log(result);
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: result.errors[0].Message,
                        type: 'error'
                    });
                    this.setState({ canUpdate: true });
                    return;
                }
                result = result.data.SearchOrganization[0];
                console.log(result);
                this.setState({
                    OrganizationName: result.OrganizationName,
                    Description: result.Description,
                    OrganizationShortName: result.OrganizationShortName,
                    OrganizationCode: result.OrganizationCode,
                    OrganizationLevel: result.OrganizationLevel,
                    ParentOrganization: {
                        OrganizationName: result.ParentOrganizationName,
                        OrganizationID: result.ParentOrganizationID
                    },
                    Address: result.Address,
                    ZipCode: result.ZipCode,
                    Tel: result.Tel,
                    Fax: result.Fax,
                    Email: result.Email,
                    Lat: result.Lat,
                    Lng: result.Lng,
                    canUpdate: true
                });
            })
            .catch(err => {
                console.log(err)
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                })
                this.setState({ canUpdate: true }, () => {
                    this.props.hide(this.resetState);
                })
            })
    }

    resetState = () => {
        this.setState({
            currentSection: 0,
            OrganizationName: '',
            Description: '',
            OrganizationShortName: '',
            OrganizationCode: '',
            OrganizationLevel: 0,
            ParentOrganization: {},
            searchOrganization: {},
            Address: '',
            ZipCode: '',
            Tel: '',
            Fax: '',
            Email: '',
            Lat: 0,
            Lng: 0,
            canUpdate: false
        })
    }

    onChange = (name, value) => {
        console.log(value)
        this.setState({ [name]: value });
    }

    checkUpdate = () => {
        var title = 'เกิดข้อผิดพลาด';
        var text = 'เกิดข้อผิดพลาด';
        var type = null;
        if (this.state.OrganizationName === '' || this.state.OrganizationName.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกชื่อหน่วยงาน';
            type = 'warning';
        }
        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    onSave = () => {
        if (!this.checkUpdate())
            return;
        var organizationService;
        console.log(this.props.currentOrganizationID)
        if (this.props.currentOrganizationID) {
            organizationService = this.props.updateOrganization(
                this.props.currentOrganizationID,
                this.state.OrganizationCode,
                this.state.OrganizationName,
                this.state.ParentOrganization.OrganizationID,
                this.state.OrganizationShortName,
                this.state.OrganizationLevel,
                this.state.Address,
                this.state.ZipCode,
                this.state.Tel,
                this.state.Fax,
                this.state.Email,
                this.state.Lat,
                this.state.Lng,
                this.state.Description
            )
        }
        else {
            organizationService = this.props.createOrganization(
                this.state.OrganizationCode,
                this.state.OrganizationName,
                this.state.ParentOrganization.OrganizationID,
                this.state.OrganizationShortName,
                this.state.OrganizationLevel,
                this.state.Address,
                this.state.ZipCode,
                this.state.Tel,
                this.state.Fax,
                this.state.Email,
                this.state.Lat,
                this.state.Lng,
                this.state.Description
            )
        }

        organizationService
            .then(result => {
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: result.errors[0].Message,
                        type: 'error'
                    })
                }
                else {
                    Swal.fire({
                        title: 'บันทึกข้อมูลสำเร็จ',
                        type: 'success'
                    })
                    if (this.props.currentOrganizationID === null)
                        this.props.hide(this.resetState);
                }
            })
            .catch(err => {
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            });
    }

    checkActiveTab = (index) => {
        if (this.state.currentSection === index) {
            return 'nav-link active';
        }
        return 'nav-link badge-secondary';
    }

    manageCurrentSection = (index) => {
        if (this.state.currentSection !== index)
            this.setState({ currentSection: index });
    }

    renderModalContent = (index) => {
        switch (index) {
            case 0: return this.renderSectionDescription();
            case 1: return this.renderSectionAddress();
            default: return null;
        }
    }

    renderSectionDescription = () => {
        return (
            <>
                <div className='form-group'>
                    <i className='text-danger mr-1'>*</i>
                    <label className='form-label'>ชื่อหน่วยงาน</label>
                    <input
                        className='form-control'
                        name='OrganizationName'
                        placeholder='ชื่อหน่วยงาน'
                        value={(this.state.OrganizationName || '')}
                        onChange={e => this.onChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className='form-group'>
                    <label className='form-label'>คำอธิบาย</label>
                    <textarea
                        className='form-control'
                        name='Description'
                        placeholder='คำอธิบาย'
                        rows='2'
                        value={(this.state.Description || '')}
                        onChange={e => this.onChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className='row'>
                    <div className='form-group col-12 col-lg-6'>
                        <label className='form-label'>ชื่อย่อหน่วยงาน</label>
                        <input
                            className='form-control'
                            name='OrganizationShortName'
                            placeholder='ชื่อย่อหน่วยงาน'
                            value={(this.state.OrganizationShortName || '')}
                            onChange={e => this.onChange(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className='form-group col-12 col-lg-6'>
                        <label className='form-label'>รหัสหน่วยงาน</label>
                        <input
                            className='form-control'
                            name='OrganizationCode'
                            placeholder='รหัสหน่วยงาน'
                            value={(this.state.OrganizationCode || '')}
                            onChange={e => this.onChange(e.target.name, e.target.value)}
                        />
                    </div>
                </div>
                <div className='form-group'>
                    <label className='form-label'>หน่วยต้นสังกัด</label>
                    <div className='text-primary mb-3 pt-2 custom-block '>
                        หน่วยงานตันสังกัดปัจจุบัน : {this.state.ParentOrganization.OrganizationName}
                        {
                            (this.state.ParentOrganization.OrganizationID) ?
                                <span
                                    className='delete-badge badge badge-danger border border-danger ml-2'
                                    onClick={() => this.onChange('ParentOrganization', {})}
                                >
                                    <i className='fa fa-times' />
                                </span>
                                : null
                        }
                    </div>
                    <div className='d-flex'>
                        <div className='flex-fill'>
                            <Autocomplete
                                getData={this.props.searchOrganization}
                                name='searchOrganization'
                                labelName='หน่วยงาน'
                                dataName='Organization'
                                onChangeSelect={this.onChange}
                                selectValue={this.state.searchOrganization}
                            />
                        </div>
                        <button
                            className='btn btn-outline-primary ml-2'
                            onClick={() => this.onChange('ParentOrganization', this.state.searchOrganization)}
                        >
                            เลือก
                        </button>
                    </div>
                </div>
            </>
        );
    }

    renderSectionAddress = () => {
        return (
            <>
                <div className='form-group'>
                    <label className='form-label'>ที่อยู่</label>
                    <input
                        className='form-control'
                        name='Address'
                        placeholder='ที่อยู่'
                        value={(this.state.Address || '')}
                        onChange={e => this.onChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className='row'>
                    <div className='form-group col-6 col-lg-4'>
                        <label className='form-label'>รหัสไปรษณีย์</label>
                        <input
                            className='form-control'
                            name='ZipCode'
                            placeholder='รหัสไปรษณีย์'
                            value={(this.state.ZipCode || '')}
                            onChange={e => this.onChange(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className='form-group col-6 col-lg-4'>
                        <label className='form-label'>โทรศัพท์</label>
                        <input
                            className='form-control'
                            name='Tel'
                            placeholder='โทรศัพท์'
                            value={(this.state.Tel || '')}
                            onChange={e => this.onChange(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className='form-group col-6 col-lg-4'>
                        <label className='form-label'>Fax</label>
                        <input
                            className='form-control'
                            name='Fax'
                            placeholder='Fax'
                            value={(this.state.Fax || '')}
                            onChange={e => this.onChange(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className='form-group col-6 col-lg-12'>
                        <label className='form-label'>Email</label>
                        <input
                            className='form-control'
                            name='Email'
                            placeholder='Email'
                            value={(this.state.Email || '')}
                            onChange={e => this.onChange(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className='form-group col-6'>
                        <label className='form-label'>Lat</label>
                        <input
                            className='form-control'
                            name='Lat'
                            placeholder='Lat'
                            value={(this.state.Lat || '')}
                            onChange={e => this.onChange(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className='form-group col-6'>
                        <label className='form-label'>Lng</label>
                        <input
                            className='form-control'
                            name='Lng'
                            placeholder='Lng'
                            value={(this.state.Lng || '')}
                            onChange={e => this.onChange(e.target.name, e.target.value)}
                        />
                    </div>
                </div>
            </>
        );
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='lg' onEnter={this.onEnterModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                (this.props.currentOrganizationID === null) ?
                                    'เพิ่มหน่วยงาน' : 'แก้ไขหน่วยงาน'
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <ul className="nav nav-pills overflow-nav">
                                <li className="nav-item custom-nav-item" >
                                    <div className={'mr-2 ' + this.checkActiveTab(0)} onClick={() => this.manageCurrentSection(0)}>
                                        ข้อมูลหน่วยงาน
                                    </div>
                                </li>
                                <li className="nav-item custom-nav-item" >
                                    <div className={this.checkActiveTab(1)} onClick={() => this.manageCurrentSection(1)}>
                                        รายละเอียดหน่วยงาน
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='mt-3'>
                            {this.renderModalContent(this.state.currentSection)}
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                            <button
                                className='btn btn-outline-primary'
                                onClick={this.onSave}
                            >
                                บันทึก
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default OrganizationManageModal;
