
import Axios from './BaseService';

class PersonService {

    searchPerson(PersonName, OrganizationID) {
        PersonName = (!PersonName) ? null : PersonName.trim();
        OrganizationID = (!OrganizationID) ? null : OrganizationID;
        const SEARCHPERSON = `
            query SearchPerson($PersonName: String, $OrganizationID: Int){
                SearchPerson(PersonName: $PersonName, OrganizationID: $OrganizationID){
                    PersonID
                    PersonName
                    Phone
                    OrganizationID
                    OrganizationShortName
                    OrganizationName
                    Remark
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: SEARCHPERSON,
            variables: {
                PersonName: PersonName,
                OrganizationID: OrganizationID
            },
            errorPolicy: 'all'
        })
    }

    createPerson(PersonName, OrganizationID, Phone, Remark) {
        PersonName = (!PersonName) ? null : PersonName.trim();
        Remark = (!Remark) ? null : Remark.trim();
        Phone = (!Phone) ? null : Phone.trim();
        const CREATEPERSON = `
            mutation CreatePerson($PersonName: String!, $OrganizationID: Int!, $Phone: String, $Remark: String){
                CreatePerson(PersonName: $PersonName, OrganizationID: $OrganizationID, Phone: $Phone, Remark: $Remark) {
                    PersonID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: CREATEPERSON,
            variables: {
                PersonName: PersonName,
                OrganizationID: parseInt(OrganizationID),
                Phone: Phone,
                Remark: Remark
            },
            errorPolicy: 'all'
        })
    }

    updatePerson(PersonID, PersonName, OrganizationID, Phone, Remark) {
        PersonName = (!PersonName) ? null : PersonName.trim();
        Remark = (!Remark) ? null : Remark.trim();
        Phone = (!Phone) ? null : Phone.trim();
        const UPDATEPERSON = `
            mutation UpdatePerson($PersonID: Int!, $PersonName: String!, $OrganizationID: Int!, $Phone: String, $Remark: String){
                UpdatePerson(PersonID: $PersonID, PersonName: $PersonName, OrganizationID: $OrganizationID, Phone: $Phone, Remark: $Remark) {
                    PersonID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: UPDATEPERSON,
            variables: {
                PersonID: parseInt(PersonID),
                PersonName: PersonName,
                OrganizationID: parseInt(OrganizationID),
                Phone: Phone,
                Remark: Remark
            },
            errorPolicy: 'all'
        })
    }

    deletePerson(PersonID) {
        const DELETEPERSON = `
            mutation DeletePerson($PersonID : Int!) {
                DeletePerson(PersonID: $PersonID) {
                    PersonID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: DELETEPERSON,
            variables: {
                PersonID: PersonID
            },
            errorPolicy: 'all'
        });
    }
}

export default new PersonService();