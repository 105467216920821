import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import Autocomplete from '../../../Utilities/Autocomplete';
import { validateEmail } from '../../../Utilities/Email';

class UserManageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Username: '',
            RoleID: 0,
            Organization: {},
            searchOrganization: {},
            Email: '',
            FirstName: '',
            LastName: '',
            Password: '',
            showPassword: false,
            checkUpdate: {
                Username: false,
                RoleID: false,
                Organization: false,
                Email: false,
                FirstName: false,
                LastName: false,
            },
            canUpdate: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onHideModal = () => {
        var title = (this.props.currentAccountID) ? 'ยกเลิกการแก้ไขผู้ใช้งาน' : 'ยกเลิกการเพิ่มผู้ใช้งาน'
        var text = 'ต้องการ' + title + 'หรือไม่ ?'
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    this.props.hide(this.resetState);
                }
            })

    }

    onEnterModal = () => {
        if (this.props.currentAccountID) {
            this.props.getAccountDetail(this.props.currentAccountID)
                .then(result => {
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            type: 'error'
                        });
                        this.setState({ canUpdate: true });
                        return;
                    }
                    result = result.data.GetAccountDetail;
                    console.log(result)
                    this.setState({
                        Username: result.Username,
                        RoleID: result.RoleID,
                        Organization: {
                            OrganizationID: result.OrganizationID,
                            OrganizationName: result.OrganizationName
                        },
                        Email: result.Email,
                        FirstName: result.FirstName,
                        LastName: result.LastName,
                        Password: '',
                        canUpdate: true
                    });
                })
                .catch(err => {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    });
                    this.setState({ canUpdate: true }, () => {
                        this.props.hide(this.resetState);
                    })
                })
        }
        else {
            this.setState({ canUpdate: true });
        }
    }

    checkUpdate = () => {
        var title = '';
        var text = '';
        var type = null;
        var newCheckUpdate = {
            Username: false,
            RoleID: false,
            Organization: false,
            Email: false,
            FirstName: false,
            LastName: false,
        };

        if (this.state.Username === '' || this.state.Username.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกชื่อผู้ใช้งาน';
            type = 'warning';
            newCheckUpdate.Username = true;
        }
        if (this.state.Password && this.state.Password.length < 8 && !this.props.currentAccountID) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'รหัสผ่านต้องมีความยาวมากกว่า 8 ตัวอักษร';
            type = 'warning';
            newCheckUpdate.Password = true;
        }
        if (this.state.Password === '' && !this.props.currentAccountID) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกรหัสผ่าน';
            type = 'warning';
            newCheckUpdate.Password = true;
        }
        if (this.state.Email === '' || this.state.Email.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกอีเมล';
            type = 'warning';
            newCheckUpdate.Email = true;
        }
        if (!validateEmail(this.state.Email)) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกอีเมลให้ถูกต้อง';
            type = 'warning';
            newCheckUpdate.Email = true;
        }
        if (this.state.FirstName === '' || this.state.FirstName.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกชื่อบุคคล';
            type = 'warning';
            newCheckUpdate.FirstName = true;
        }
        if (this.state.LastName === '' || this.state.LastName.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกนามสกุลบุคคล';
            type = 'warning';
            newCheckUpdate.LastName = true;
        }
        if (this.state.RoleID === 0) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาระบุสิทธิ์การใช้งาน';
            type = 'warning';
            newCheckUpdate.RoleID = true;
        }
        if (this.state.Organization.OrganizationID === 0 || this.state.Organization.OrganizationID === undefined) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาระบุหน่วยงาน';
            type = 'warning';
            newCheckUpdate.Organization = true;
        }

        this.setState({
            checkUpdate: newCheckUpdate
        });
        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    onSave = () => {
        if (!this.checkUpdate())
            return;
        var accountService;
        if (this.props.currentAccountID)
            accountService = this.props.updateAccount(
                this.props.currentAccountID,
                this.state.RoleID,
                this.state.Organization.OrganizationID,
                this.state.Email,
                this.state.Username,
                this.state.FirstName,
                this.state.LastName,
                (this.state.Password || null)
            );
        else
            accountService = this.props.createAccount(
                this.state.RoleID,
                this.state.Organization.OrganizationID,
                this.state.Email,
                this.state.Password,
                this.state.Username,
                this.state.FirstName,
                this.state.LastName
            )
        console.log('test')
        accountService
            .then(result => {
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: result.errors[0].Message,
                        type: 'error'
                    })
                }
                else {
                    Swal.fire({
                        title: 'บันทึกข้อมูลสำเร็จ',
                        type: 'success'
                    })
                    if (!this.props.currentAccountID)
                        this.props.hide(this.resetState);
                }
            })
            .catch(err => {
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            });
    }

    resetState = () => {
        this.setState({
            Username: '',
            RoleID: 0,
            Organization: {},
            searchOrganization: {},
            Email: '',
            FirstName: '',
            LastName: '',
            Password: '',
            showPassword: false,
            checkUpdate: {
                Username: false,
                RoleID: false,
                Organization: false,
                Email: false,
                FirstName: false,
                LastName: false,
            },
            canUpdate: false
        })
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    checkInvalid = (name) => {
        if (this.state.checkUpdate[name] === true)
            return 'is-invalid';
    }

    generatePassword = () => {
        var length = 8,
            charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
            newPassword = '';
        for (var i = 0, n = charset.length; i < length; ++i) {
            newPassword += charset.charAt(Math.floor(Math.random() * n));
        }
        this.setState({
            Password: newPassword
        });
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='lg' onEnter={this.onEnterModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {(this.props.currentAccountID === null) ? 'เพิ่มผู้ใช้งาน' : 'แก้ไขผู้ใช้งาน'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i>
                            <label className='form-label'>ชื่อผู้ใช้งาน</label>
                            <input
                                className={'form-control ' + this.checkInvalid('Username')}
                                name='Username'
                                placeholder='ชื่อผู้ใช้งาน'
                                value={(this.state.Username || '')}
                                onChange={e => this.onChange(e.target.name, e.target.value.trim())}
                            />
                        </div>
                        <div className='form-group'>
                            {
                                //(this.props.currentAccountID) ? null : <i className='text-danger mr-1'>*</i>
                            }
                            <i className='text-danger mr-1'>*</i>
                            <label className='form-label'>อีเมล</label>
                            <input
                                type='email'
                                className={'form-control ' + this.checkInvalid('Email')}
                                name='Email'
                                placeholder='อีเมล'
                                value={(this.state.Email || '')}
                                onChange={e => this.onChange(e.target.name, e.target.value.trim())}
                            />
                        </div>
                        <div className='row'>
                            <div className='form-group col-12 col-lg-6'>
                                <i className='text-danger mr-1'>*</i>
                                <label className='form-label'>ชื่อ</label>
                                <input
                                    className={'form-control ' + this.checkInvalid('FirstName')}
                                    name='FirstName'
                                    placeholder='ชื่อ'
                                    value={(this.state.FirstName || '')}
                                    onChange={e => this.onChange(e.target.name, e.target.value)}
                                />
                            </div>
                            <div className='form-group col-12 col-lg-6'>
                                <i className='text-danger mr-1'>*</i>
                                <label className='form-label'>นามสกุล</label>
                                <input
                                    className={'form-control ' + this.checkInvalid('LastName')}
                                    name='LastName'
                                    placeholder='นามสกุล'
                                    value={(this.state.LastName || '')}
                                    onChange={e => this.onChange(e.target.name, e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i>
                            <label className='form-label'>สิทธิ์การใช้งาน</label>
                            <select
                                className={'form-control ' + this.checkInvalid('RoleID')}
                                name='RoleID'
                                value={(this.state.RoleID || 0)}
                                onChange={e => this.onChange(e.target.name, parseInt(e.target.value))}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                {
                                    this.props.Role.map((obj, index) => {
                                        return (
                                            <option key={'UserManage-role-option' + index} value={obj.RoleID}>
                                                {obj.RoleName}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </div>

                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i>
                            <label className='form-label'>หน่วยงาน</label>
                            <div className='text-primary mb-3 pt-2 custom-block '>
                                หน่วยงานปัจจุบัน : {this.state.Organization.OrganizationName}
                                {
                                    (this.state.Organization.OrganizationID) ?
                                        <span
                                            className='delete-badge badge badge-danger border border-danger ml-2'
                                            onClick={() => this.onChange('Organization', {})}
                                        >
                                            <i className='fa fa-times' />
                                        </span>
                                        : null
                                }
                            </div>
                            <div className='d-flex'>
                                <div className='flex-fill'>
                                    <Autocomplete
                                        getData={this.props.getData}
                                        name='searchOrganization'
                                        labelName='หน่วยงาน'
                                        dataName='Organization'
                                        onChangeSelect={this.onChange}
                                        selectValue={this.state.searchOrganization}
                                    />
                                </div>
                                <button className='btn btn-outline-primary ml-2' onClick={() => this.onChange('Organization', this.state.searchOrganization)}>เลือก</button>
                            </div>
                        </div>
                        <div className='form-group'>
                            {
                                (this.props.currentAccountID) ?
                                    null :
                                    <i className='text-danger mr-1'>*</i>
                            }
                            <label className='form-label'>รหัสผ่าน</label>
                            <div className='d-flex'>
                                {/* <button className='btn btn-outline-primary mr-3' onClick={this.generatePassword}>กำหนดรหัสผ่าน</button>
                                        {this.state.Password} */}
                                <div className='flex-fill'>
                                    <input
                                        value={this.state.Password}
                                        name='Password'
                                        className={'form-control eye-input ' + this.checkInvalid('Password')}
                                        placeholder='รหัสผ่าน'
                                        onChange={e => this.onChange(e.target.name, e.target.value.trim())}
                                        type={(this.state.showPassword) ? 'text' : 'password'}
                                    />
                                </div>
                                <button className='btn btn-outline-secondary eye-button' onClick={() => this.setState({ showPassword: !(this.state.showPassword) })}>
                                    <i className={'fa fa-eye' + ((this.state.showPassword) ? '-slash' : '')}></i>
                                </button>
                                {/* <div className='col-4 col-lg-3'>
                                            <button className='btn btn-outline-primary'>แสดงรหัสผ่าน</button>
                                        </div> */}
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                            <button
                                className='btn btn-outline-primary'
                                onClick={this.onSave}
                            >
                                บันทึก
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default UserManageModal;
