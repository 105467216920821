import Axios from './BaseService';

class HistoryService {

    searchHistory(StartDate, EndDate, Username, Action, Module, IP, Offset, Limit) {
        Username = (!Username) ? null : Username.trim();
        Module = (!Module) ? null : Module.trim();
        Action = (!Action) ? null : Action.trim();
        IP = (!IP) ? null : IP.trim();
        const SEARCHHISTORY = `
        query SearchHistory(
                $StartDate: String,
                $EndDate: String, 
                $Username: String, 
                $Action: String, 
                $Module: String, 
                $IP: String,
                $Offset: Int, 
                $Limit: Int
            ) {
            SearchHistory(
                StartDate: $StartDate,
                EndDate: $EndDate, 
                Username: $Username, 
                Action: $Action, 
                Module: $Module, 
                IP: $IP,
                Offset: $Offset, 
                Limit: $Limit
            ){
                ListHistory {
                    Username
                    IP
                    DatetimeAction
                    Module
                    Action
                    ItemName
                }
                Count
            }
        }
        `;

        return Axios.post('/pmqaservice', {
            query: SEARCHHISTORY,
            variables: {
                StartDate: StartDate,
                EndDate: EndDate,
                Username: Username,
                Action: Action,
                Module: Module,
                IP: IP,
                Offset: Offset,
                Limit: Limit
            },
            errorPolicy: 'all'
        });
    }
}

export default new HistoryService();