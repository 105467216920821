import React from 'react';
import Modal from 'react-bootstrap/Modal';
import FroalaEditor from '../../../Utilities/FroalaEditor';
import Swal from 'sweetalert2';

class RemarkRejectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            RemarkReject: '',
            Loading: false,
            canUpdate: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    resetState = () => {
        this.setState({
            RemarkReject: '',
            Loading: false,
            canUpdate: false
        })
    }

    onEnterModal = () => {
        var newRemarkReject = this.props.getDefaultRemarkReject();
        this.setState({ canUpdate: true, RemarkReject: ((newRemarkReject) ? newRemarkReject : '') });
    }

    onHideModal = () => {
        Swal.fire({
            type: 'warning',
            title: 'ยกเลิกการให้เหตุผล',
            text: 'ท่านต้องการยกเลิกการให้เหตุผลที่ข้อมูลไม่สมบูรณ์หรือไม่ ?',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง',
            reverseButtons: true
        })
            .then(res => {
                if (res.value)
                    this.props.hide(this.resetState);
            })
            .catch(err => {
                console.log(err);
                this.props.hide(this.resetState);
            })
    }

    onChangeContent = (name, value) => {
        console.log(name, value)
        this.setState({ [name]: value });
    }

    onSave = () => {
        Swal.fire({
            type: 'warning',
            title: 'ยืนยันการให้เหตุผล',
            text: 'ท่านต้องการยืนยันการให้เหตุผลที่ข้อมูลไม่สมบูรณ์หรือไม่ ?',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    this.setState({ Loading: true }, () => {
                        this.props.approveEvaluationResultReject(0, ((this.state.RemarkReject) ? this.state.RemarkReject : ' '))
                            .then(result => {
                                console.log(result);
                                this.setState({ Loading: false }, () => { this.props.hide(this.resetState) });
                            })
                            .catch(err => {
                                console.log(err);
                                this.props.hide(this.resetState);
                            })
                    })
                }
            })
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={(this.state.loading) ? null : this.onHideModal}
                onEnter={this.onEnterModal}
                size='lg'
                backdropClassName='backdrop-formcopy'
                className='dialog-formcopy'
                centered
            >
                <Modal.Header>
                    <Modal.Title>เหตุผลที่ข้อมูลไม่สมบูรณ์</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='p-2'>
                        {
                            (this.state.canUpdate) ?
                                <FroalaEditor
                                    model={this.state.RemarkReject}
                                    onModelChange={this.onChangeContent}
                                    onchange1='RemarkReject'
                                />
                                : null
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='float-right'>
                        {
                            (this.state.loading) ?
                                <button className='btn btn-outline-primary' disabled>
                                    <i className='fa fa-circle-o-notch fa-spin mr-2' />
                                    กำลังทำรายการ
                                    </button>
                                :
                                <>
                                    <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                                    <button
                                        className='btn btn-outline-primary'
                                        onClick={this.onSave}
                                    >
                                        ตกลง
                                    </button>
                                </>
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default RemarkRejectModal;