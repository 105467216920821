import React from 'react';

class PageNotFound extends React.Component {

    render() {
        return (
            <div>
                <div align='center'>404</div>
                <div align='center'>Page Not Found</div>
            </div>
        )
    }
}

export default PageNotFound;