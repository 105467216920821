import React from 'react';
import Chartjs from 'chart.js';
import { connect } from 'react-redux';
import ReportService from '../../Service/ReportService';
import { checkMonth, renderMonth, renderYear } from '../../Utilities/Date';
import Autocomplete from '../../Utilities/Autocomplete';
import OrganizationService from '../../Service/OrganizationService';
import EvaluationService from '../../Service/EvaluationService';
import KPIManageService from '../../Service/KPIManageService';
import Swal from 'sweetalert2';
import { showLoading, hideLoading } from '../../Redux/Action';
import ChartjsLabels from 'chartjs-plugin-labels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReactTooltip from 'react-tooltip';
import PersonService from '../../Service/PersonService';

Chartjs.plugins.unregister(ChartDataLabels);
Chartjs.Legend.prototype.afterFit = function () {
    this.height = this.height + 25;
};

const colors = [
    'rgba(249, 93, 106, ',
    'rgba(255, 124, 67, ',
    'rgba(255, 166, 0, ',
    'rgba(235, 224, 56, ',
    'rgba(70, 190, 162, ',
    'rgba(61, 77, 183, ',
    'rgba(0, 123, 193, ',
    'rgba(105, 8, 32, ',
    'rgba(59, 206, 222, ',
    'rgba(103, 134, 216, ',
    'rgba(161, 213, 85, ',
    'rgba(138, 93, 51, ',
    'rgba(167, 178, 200, ',
    'rgba(255, 63, 84, ',
    'rgba(198, 20, 96, ',
    'rgba(92, 72, 74, ',
    'rgba(6, 129, 126, ',
    'rgba(0, 63, 92, ',
    'rgba(47, 75, 124, ',
    'rgba(102, 81, 145, ',
    'rgba(160, 81, 149, ',
    'rgba(212, 80, 135, ',
];

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        var date = new Date();
        date = date.getMonth() + 1;
        this.state = {
            show: false,
            zoom: 0,
            displayType: 0,
            EvaluationTypes: [],
            donutChartStatus: null,
            donutChartResult: null,
            stackedBar1: null,
            stackedBar2: null,
            StatusReportMonth: date,
            ResultReportMonth: date,
            StatusReportByPolicyMonth: date,
            searchYear: 0,
            searchOrganization: {},
            searchPerson: {},
            searchKPI: {},
            searchEvaluationType: 6,
            searched: {
                searched: false,
                searchedYear: 0,
                searchedOrganization: {
                    OrganizationID: 0,
                    OrganizationName: ''
                },
                searchedEvaluationType: 0,
                searchedKPI: {
                    KPIID: 0,
                    KPIName: ''
                },
                searchedPerson: {
                    PersonID: 0,
                    PersonName: ''
                }
            },
            stackedbarData: {},
            stackedBarDisplayType: 0,
            selectedReportProfile: {},
            LineData: [],
            LineStep: 0,
            LineChartVar: null,
            LineHistory: {
                step0: null,
                step1: null
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(showLoading());
        this.getEvaluationType();
        this.getReportProfile();
        var date = new Date();
        date = date.getFullYear() + '-' + ((date.getMonth() + 1 < 10) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1));
        console.log(date);
        ReportService.getChartData(this.state.searchEvaluationType, new Date().getFullYear(), date)
            .then(result => { // Sent by Month Donut
                if (result.errors) {
                    return;
                }
                result = result.data.SearchReport;
                console.log(result);
                var donutChart1 = new Chartjs(this.donutChart1, {
                    type: 'doughnut',
                    data: {
                        labels: result.StatusReport.map((obj) => obj.StatusName),
                        datasets: [{
                            data: result.StatusReport.map((obj) => obj.Total),
                            backgroundColor: [
                                'rgba(6,179,143,0.8)',
                                'rgba(245, 39, 62, 0.8)',
                                'rgba(255, 226, 86, 0.8)',
                                'rgba(255, 156, 76, 0.8)',
                            ],
                            borderColor: [
                                'rgba(6,179,143,0.8)',
                                'rgba(245, 39, 62, 1)',
                                'rgba(255, 226, 86, 1)',
                                'rgba(255, 156, 76, 1)',
                            ],
                            borderWidth: 1
                        }]
                    },
                    options: {
                        plugins: {
                            labels: {
                                render: (args) => (args.label + ": " + args.value),
                                position: 'outside',
                                textMargin: 8
                            }
                        }
                    }
                });
                this.changeSummaryDisplay(result.StatusReport);

                this.setState({
                    donutChartStatus: donutChart1
                });
            })
            .then(() => { // Pass notPass Donut
                return ReportService.getReportEvaluationCriteria(this.state.searchEvaluationType, new Date().getFullYear(), date)
                    .then(result => {
                        if (result.errors) {
                            return;
                        }
                        console.log(result);
                        result = result.data.GetReportEvaluationCriteria;

                        var donutChart2 = new Chartjs(this.donutChart2, {
                            type: 'doughnut',
                            data: {
                                labels: result.map(x => x.ResultName),
                                datasets: [{
                                    data: result.map(x => x.COUNT),
                                    backgroundColor: [
                                        'rgba(20,230,163,0.8)',
                                        'rgba(6,200,153,0.8)',
                                        'rgba(6,169,123,0.8)',
                                        'rgba(245, 39, 62, 0.8)',
                                        'rgba(255, 226, 86, 0.8)'
                                    ],
                                    borderColor: [
                                        'rgba(20,230,163,1)',
                                        'rgba(6,200,153,1)',
                                        'rgba(6,169,123,1)',
                                        'rgba(245, 39, 62, 1)',
                                        'rgba(255, 226, 86, 1)'
                                    ],
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                plugins: {
                                    labels: {
                                        render: (args) => (args.label + ": " + args.value),
                                        position: 'outside',
                                        textMargin: 10,
                                    }
                                }
                            }
                        });

                        this.setState({
                            donutChartResult: donutChart2
                        });
                    })
                    .catch(err => {
                        console.log(err);
                        return Promise.reject();
                    });
            })
            .then(() => { // ListSent BarChart
                return ReportService.getReportResult(this.state.searchEvaluationType, new Date().getFullYear())
                    .then(result => {
                        console.log(result)
                        if (result.errors) {
                            return;
                        }
                        result = result.data.GetReportResult;
                        //var randomIndex = Math.floor(Math.random() * (colors.length - 1));
                        var ColorBorder = result.ListSent.map((obj, index) => {
                            return colors[index % colors.length] + '1)';
                        });
                        this.chartStackedBar1Text.innerText = 'การส่งรายงานตามตัวชี้วัด ' + (result.EvaluationTypeName || '') + ' (จำนวนการประเมินตลอดทั้งปี)';
                        console.log(result.ListSent.map(obj => this.formatLabel(obj.KPIName)))
                        if (result && result.ListSent && result.ListSent.length > 10) {
                            this.barChartDiv1.style.height = '50vh';
                            this.barChartDiv1.style.minWidth = (result.ListSent.length * 75) + 'px';
                        }
                        else {
                            this.barChartDiv1.style.height = '';
                            this.barChartDiv1.style.minWidth = '';
                            this.chartStackedBarCanvas1.style.width = '100%';
                        }
                        var barChart = new Chartjs(this.chartStackedBarCanvas1, {
                            type: 'bar',
                            data: {
                                labels: result.ListSent.map(obj => this.formatLabel(obj.KPIName)),
                                datasets: [{
                                    label: 'ประเมินแล้ว',
                                    data: result.ListSent.map(obj => obj.COUNT),
                                    backgroundColor: result.ListSent.map((obj, index) => {
                                        return colors[index % colors.length] + '0.6)';
                                    }),
                                    borderColor: ColorBorder,
                                    borderWidth: 1
                                },
                                {
                                    label: 'ทั้งหมด',
                                    data: result.ListSent.map(() => result.Total),
                                    backgroundColor: result.ListSent.map((obj, index) => {
                                        return colors[index % colors.length] + '0.2)';
                                    }),
                                    borderColor: ColorBorder,
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                plugins: {
                                    labels: {
                                        render: (args) => {
                                            if (args.dataset.data.length > 15)
                                                return '';
                                            if (args.dataset.label === 'ทั้งหมด')
                                                return barChart.config.data.datasets[0].data[args.index] + ' / ' + args.value
                                        }
                                    }
                                },
                                scales: {
                                    xAxes: [{
                                        stacked: true,
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'ตัวชี้วัด'
                                        }
                                    }],
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        },
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'จำนวน (ครั้ง)'
                                        }
                                    }]
                                },
                                maintainAspectRatio: ((result && result.ListSent && result.ListSent.length > 10) ? false : true),
                            }
                        });
                        this.setState({
                            stackedBar1: barChart,
                            stackedbarData: result
                        });
                    })
                    .catch(e => {
                        console.log(e);
                        return Promise.reject();
                    })
            })
            .then(() => { //Organization BarChart && hideLoading Role 4, 5
                return ReportService.getReportResultByOrganization(this.state.searchEvaluationType, new Date().getFullYear())
                    .then(result => {
                        console.log(result)
                        if (result.errors) {
                            return;
                        }
                        result = result.data.GetReportResultByOrganization;
                        if (result.ListOrganization && result.ListOrganization.length)
                            result.ListOrganization = result.ListOrganization.filter(x => x.OrganizationName !== null);
                        else
                            result.ListOrganization = [];
                        console.log(result);
                        if (!result.ListOrganization) result.ListOrganization = [];
                        //var randomIndex = Math.floor(Math.random() * (colors.length - 1));
                        var ColorBorder = result.ListOrganization.map((obj, index) => {
                            return colors[index % colors.length] + '1)';
                        });
                        if (result && result.ListOrganization && result.ListOrganization.length > 10) {
                            this.barChartDiv2.style.height = '50vh';
                            this.barChartDiv2.style.minWidth = (result.ListOrganization.length * 75) + 'px';
                        }
                        else {
                            this.barChartDiv2.style.height = '';
                            this.barChartDiv2.style.minWidth = '';
                            this.chartStackedBarCanvas2.style.width = '100%';
                        }
                        this.chartStackedBar2Text.innerText = 'การส่งรายงานตามหน่วยงาน ' + (result.EvaluationTypeName || '') + ' (จำนวนการประเมินตลอดทั้งปี)';
                        var barChart = new Chartjs(this.chartStackedBarCanvas2, {
                            type: 'bar',
                            data: {
                                labels: result.ListOrganization.map(
                                    obj =>
                                        (obj.OrganizationShortName) ? obj.OrganizationShortName : this.formatLabel(obj.OrganizationName)
                                ),
                                datasets: [{
                                    label: 'ส่งรายงานแล้ว',
                                    data: result.ListOrganization.map(obj => obj.COUNTSent),
                                    backgroundColor: result.ListOrganization.map((obj, index) => {
                                        return colors[index % colors.length] + '0.6)';
                                    }),
                                    borderColor: ColorBorder,
                                    borderWidth: 1,
                                },
                                {
                                    label: 'ทั้งหมด',
                                    data: result.ListOrganization.map(obj => obj.COUNTSent + obj.COUNTNoSent),
                                    backgroundColor: result.ListOrganization.map((obj, index) => {
                                        return colors[index % colors.length] + '0.2)';
                                    }),
                                    borderColor: ColorBorder,
                                    borderWidth: 1,
                                }]
                            },
                            options: {
                                plugins: {
                                    labels: {
                                        render: (args) => {
                                            if (args.dataset.data.length > 15)
                                                return '';
                                            else if (args.dataset.label === 'ทั้งหมด')
                                                return barChart.config.data.datasets[0].data[args.index] + ' / ' + args.value
                                        },

                                    }
                                },
                                scales: {
                                    xAxes: [{
                                        stacked: true,
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'หน่วยงาน'
                                        }
                                    }],
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        },
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'จำนวน (ครั้ง)'
                                        }
                                    }],
                                    padding: 25
                                },
                                maintainAspectRatio: ((result && result.ListOrganization && result.ListOrganization.length > 10) ? false : true),
                            }
                        });
                        console.log(result);
                        this.setState({
                            stackedBar2: barChart
                        }, () => {
                            if (this.props.roleID === 4 || this.props.roleID === 5) {
                                this.props.dispatch(hideLoading());
                            }
                        });
                    })
                    .catch(e => {
                        console.log(e);
                        return Promise.reject();
                    })
            })
            .then(() => { //LineChart & hideLoading 1, 2, 3
                if (this.props.roleID === 4 || this.props.roleID === 5) {
                    return;
                }
                return ReportService.reportTrendResult(this.state.searchEvaluationType, new Date().getFullYear())
                    .then(result => {
                        if (result.errors) {
                            return;
                        }
                        result = result.data.GetReportTrendsResultByYear;
                        result = result.filter(x => (x.SumResultScoreByKPIMain));
                        console.log(result);

                        var myLineChart = new Chartjs(this.LineChart, {
                            type: 'line',
                            data: {
                                labels: result.map((obj) => {
                                    if (obj.Year) return obj.Year + 543;
                                    else return '';
                                }),
                                datasets: [{
                                    label: 'ผล',
                                    data: result.map(obj => parseFloat(obj.SumResultScoreByKPIMain).toFixed(2)),
                                    fill: false,
                                    borderColor: 'rgba(245, 39, 62, 1)',
                                    backgroundColor: 'rgba(245, 39, 62, 0.8)',
                                    pointRadius: 7,
                                    pointHoverRadius: 10
                                },
                                {
                                    label: 'แผน',
                                    data: result.map(obj => parseFloat(obj.SumResultPlanByKPIMain).toFixed(2)),
                                    fill: false,
                                    borderColor: 'rgba(20,160,230,1)',
                                    backgroundColor: 'rgba(20,160,230,0.8)',
                                    pointRadius: 7,
                                    pointHoverRadius: 10
                                }]
                            },
                            plugins: [ChartDataLabels],
                            options: {
                                scales: {
                                    xAxes: [{
                                        offset: true,
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'ปีงบประมาณ'
                                        }
                                    }],
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        },
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'ร้อยละ'
                                        }
                                    }]
                                },
                                plugins: {
                                    datalabels: {
                                        anchor: 'end',
                                        align: 'end',
                                        offset: 0
                                    },
                                    labels: { render: () => { } }
                                }
                            }
                        });

                        this.LineChartText.innerText = 'ผลการดำเนินงานต่อแผน ' + ((result[0]) ? (result[0].EvaluationTypeName || '') : '') + ' (ตามปีงบประมาณ)';

                        this.setState({
                            LineChartVar: myLineChart,
                            LineData: result
                        }, () => {
                            this.props.dispatch(hideLoading());
                        });
                    })
                    .catch(e => {
                        console.log(e);
                        return Promise.reject();
                    });
            })
            .catch(err => {
                if (!this.state.donutChartStatus) {
                    var donutChart1 = new Chartjs(this.donutChart1, {
                        type: 'doughnut',
                        data: {
                            labels: [],
                            datasets: [{
                                data: [],
                                backgroundColor: [
                                    'rgba(6,179,143,0.8)',
                                    'rgba(245, 39, 62, 0.8)',
                                    'rgba(255, 226, 86, 0.8)',
                                    'rgba(255, 156, 76, 0.8)',
                                ],
                                borderColor: [
                                    'rgba(6,179,143,0.8)',
                                    'rgba(245, 39, 62, 1)',
                                    'rgba(255, 226, 86, 1)',
                                    'rgba(255, 156, 76, 1)',
                                ],
                                borderWidth: 1
                            }]
                        },
                        options: {
                            plugins: {
                                labels: {
                                    render: (args) => (args.label + ": " + args.value),
                                    position: 'outside',
                                    textMargin: 8
                                }
                            }
                        }
                    });

                    this.setState({
                        donutChartStatus: donutChart1
                    });
                }
                if (!this.state.stackedBar1) {
                    var barChart = new Chartjs(this.chartStackedBarCanvas1, {
                        type: 'bar',
                        data: {
                            labels: [],
                            datasets: [{
                                label: 'ประเมินแล้ว',
                                data: [],
                                backgroundColor: [],
                                borderColor: [],
                                borderWidth: 1
                            },
                            {
                                label: 'ทั้งหมด',
                                data: [],
                                backgroundColor: [],
                                borderColor: [],
                                borderWidth: 1
                            }]
                        },
                        options: {
                            plugins: {
                                labels: {
                                    render: (args) => {
                                        if (args.dataset.data.length > 15)
                                            return '';
                                        if (args.dataset.label === 'ทั้งหมด')
                                            return barChart.config.data.datasets[0].data[args.index] + ' / ' + args.value
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'ตัวชี้วัด'
                                    }
                                }],
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'จำนวน (ครั้ง)'
                                    }
                                }]
                            },
                            maintainAspectRatio: true
                        }
                    });
                    this.setState({
                        stackedBar1: barChart,
                        stackedbarData: {}
                    });
                }
                if (!this.state.donutChartResult) {
                    var donutChart2 = new Chartjs(this.donutChart2, {
                        type: 'doughnut',
                        data: {
                            labels: [],
                            datasets: [{
                                data: [],
                                backgroundColor: [
                                    'rgba(20,230,163,0.8)',
                                    'rgba(6,200,153,0.8)',
                                    'rgba(6,169,123,0.8)',
                                    'rgba(245, 39, 62, 0.8)',
                                    'rgba(255, 226, 86, 0.8)'
                                ],
                                borderColor: [
                                    'rgba(20,230,163,1)',
                                    'rgba(6,200,153,1)',
                                    'rgba(6,169,123,1)',
                                    'rgba(245, 39, 62, 1)',
                                    'rgba(255, 226, 86, 1)'
                                ],
                                borderWidth: 1
                            }]
                        },
                        options: {
                            plugins: {
                                labels: {
                                    render: (args) => (args.label + ": " + args.value),
                                    position: 'outside',
                                    textMargin: 10,
                                }
                            }
                        }
                    });

                    this.setState({
                        donutChartResult: donutChart2
                    });
                }
                if (!this.state.stackedBar2) {
                    var barChart = new Chartjs(this.chartStackedBarCanvas2, {
                        type: 'bar',
                        data: {
                            labels: [],
                            datasets: [{
                                label: 'ส่งรายงานแล้ว',
                                data: [],
                                borderColor: [],
                                borderWidth: 1,
                            },
                            {
                                label: 'ทั้งหมด',
                                data: [],
                                borderColor: [],
                                borderWidth: 1,
                            }]
                        },
                        options: {
                            plugins: {
                                labels: {
                                    render: (args) => {
                                        if (args.dataset.data.length > 15)
                                            return '';
                                        else if (args.dataset.label === 'ทั้งหมด')
                                            return barChart.config.data.datasets[0].data[args.index] + ' / ' + args.value
                                    },

                                }
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'หน่วยงาน'
                                    }
                                }],
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'จำนวน (ครั้ง)'
                                    }
                                }],
                                padding: 25
                            },
                            maintainAspectRatio: true,
                        }
                    });
                    this.setState({
                        stackedBar2: barChart
                    });
                }
                if (!this.state.LineChartVar) {
                    var myLineChart = new Chartjs(this.LineChart, {
                        type: 'line',
                        data: {
                            labels: [],
                            datasets: [{
                                label: 'ผล',
                                data: [],
                                fill: false,
                                borderColor: 'rgba(245, 39, 62, 1)',
                                backgroundColor: 'rgba(245, 39, 62, 0.8)',
                                pointRadius: 7,
                                pointHoverRadius: 10
                            },
                            {
                                label: 'แผน',
                                data: [],
                                fill: false,
                                borderColor: 'rgba(20,160,230,1)',
                                backgroundColor: 'rgba(20,160,230,0.8)',
                                pointRadius: 7,
                                pointHoverRadius: 10
                            }]
                        },
                        plugins: [ChartDataLabels],
                        options: {
                            scales: {
                                xAxes: [{
                                    offset: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'ปีงบประมาณ'
                                    }
                                }],
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'ร้อยละ'
                                    }
                                }]
                            },
                            plugins: {
                                datalabels: {
                                    anchor: 'end',
                                    align: 'end',
                                    offset: 0
                                },
                                labels: { render: () => { } }
                            }
                        }
                    });
                    this.setState({
                        LineChartVar: myLineChart,
                        LineData: []
                    });
                }
                this.props.dispatch(hideLoading());
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                })
            });
    }

    resizeWindow = () => {
        window.dispatchEvent(new Event("resize"));
    };

    formatLabel = (str) => {
        var sections = [];
        var temp = ''
        var round = 1;

        if (!str) return '';
        for (var i = 0; i < str.length; i++) {
            var obj = str[i];
            temp += obj;
            if (temp.length >= 21 || i === (str.length - 1)) {
                if (round === 2 && temp.length >= 21) {
                    sections.push(temp + '...');
                    return sections;
                }
                sections.push(temp);
                temp = ''
                round++;
            }
        }

        return sections;
    }

    hideModal = (callback) => {
        (this.state.show === false) ?
            this.setState({
                show: true
            })
            : this.setState({
                show: false
            }, () => {
                if (typeof callback === 'function')
                    callback()
            });
    }

    getEvaluationType = () => {
        EvaluationService.getEvaluationType()
            .then(result => {
                if (result.errors) {
                    return;
                }
                this.setState({ EvaluationTypes: result.data.GetEvaluationType })
            })
            .catch(err => {
                console.log(err);
            })
    }

    getReportProfile = () => {
        return ReportService.getReportProfile();
    }

    searchOrganization = (Name) => {
        return OrganizationService.searchOrganization(Name);
    }

    searchKPI = (Name) => {
        return KPIManageService.searchKPI(null, Name, null, null, 0, 25);
    }

    searchPerson = (Name) => {
        return PersonService.searchPerson(Name);
    }

    getDateSearch = (value) => {
        var date;
        var Year;

        if (this.state.searched.searched && this.state.searched.searchedYear !== 0) {
            date = (value > 9) ?
                (this.state.searched.searchedYear - 1) + '-' + ((value < 10) ? ('0' + value) : value)
                :
                this.state.searched.searchedYear + '-' + ((value < 10) ? ('0' + value) : value);

            Year = this.state.searched.searchedYear;
        }
        else {
            date = (value > 9) ?
                (new Date().getFullYear() - 1) + '-' + ((value < 10) ? ('0' + value) : value)
                :
                new Date().getFullYear() + '-' + ((value < 10) ? ('0' + value) : value);

            Year = new Date().getFullYear();
        }

        return {
            date: date,
            Year: Year
        }
    }

    renderNewDonutChart1 = (EvaluationTypeID, chartVar, value, Organization, KPI, Person) => {

        var dateYear = this.getDateSearch(value);

        console.log(dateYear);

        ReportService.getChartData(EvaluationTypeID, dateYear.Year, dateYear.date, Organization, KPI, Person)
            .then(result => {
                result = result.data.SearchReport;
                console.log(result);
                chartVar.data.datasets[0].data = result.StatusReport.map(obj => obj.Total);
                chartVar.data.labels = result.StatusReport.map(obj => obj.StatusName);
                this.changeSummaryDisplay(result.StatusReport);
                chartVar.update();
            })
            .catch(err => {
                Swal.fire({
                    type: 'error',
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                })
            });
    }

    renderNewDonutChart2 = (EvaluationTypeID, chartVar, value, Organization, KPI, Person) => {

        var dateYear = this.getDateSearch(value);

        console.log(dateYear);

        ReportService.getReportEvaluationCriteria(EvaluationTypeID, dateYear.Year, dateYear.date, Organization, KPI, Person)
            .then(result => {
                console.log(result)
                result = result.data.GetReportEvaluationCriteria;
                chartVar.data.datasets[0].data = result.map(x => x.COUNT);
                chartVar.data.labels = result.map(x => x.ResultName);
                chartVar.update();
            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                    type: 'error',
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                })
            });
    }

    renderNewBarChart1 = (EvaluationType, Year, Organization, KPI, Person) => {
        Year = (Year) ? Year : new Date().getFullYear();
        var chartVar = this.state.stackedBar1;
        ReportService.getReportResult(EvaluationType, Year, Organization, KPI, Person)
            .then(result => {
                console.log(result)
                if (result.errors) {
                    return;
                }
                result = result.data.GetReportResult;
                var ColorBorder = result.ListSent.map((obj, index) => {
                    return colors[index % colors.length] + '1)';
                });
                this.chartStackedBar1Text.innerText = 'การส่งรายงานตามตัวชี้วัด ' + (result.EvaluationTypeName || '') + ' (จำนวนการประเมินตลอดทั้งปี)';
                if (result && result.ListSent && result.ListSent.length > 20) {
                    this.barChartDiv1.style.height = '50vh';
                    if (result.ListSent && result.ListSent.length > 10)
                        this.barChartDiv1.style.minWidth = (result.ListSent.length * 75) + 'px';
                }
                else {
                    this.barChartDiv1.style.height = '';
                    this.barChartDiv1.style.minWidth = '';
                    this.chartStackedBarCanvas1.style.width = '100%';
                }
                chartVar.options.maintainAspectRatio = ((result && result.ListSent && result.ListSent.length > 10) ? false : true);
                chartVar.config.type = 'bar';
                chartVar.data.labels = result.ListSent.map(obj => this.formatLabel(obj.KPIName));
                chartVar.data.datasets = [{
                    label: 'ประเมินแล้ว',
                    data: result.ListSent.map(obj => obj.COUNT),
                    backgroundColor: result.ListSent.map((obj, index) => {
                        return colors[index % colors.length] + '0.6)';
                    }),
                    borderColor: ColorBorder,
                    borderWidth: 1
                },
                {
                    label: 'ทั้งหมด',
                    data: result.ListSent.map(() => result.Total),
                    backgroundColor: result.ListSent.map((obj, index) => {
                        return colors[index % colors.length] + '0.2)';
                    }),
                    borderColor: ColorBorder,
                    borderWidth: 1
                }];
                chartVar.update();
                chartVar.resize();
                this.setState({
                    stackedbarData: result
                });
            })
            .catch(e => {
                console.log(e);
            })
    }

    renderNewBarChart2 = (EvaluationType, Year, Organization, KPI, Person) => {
        Year = (Year) ? Year : new Date().getFullYear();
        console.log(EvaluationType, Year);
        var chartVar = this.state.stackedBar2;
        ReportService.getReportResultByOrganization(EvaluationType, Year, Organization, KPI, Person)
            .then(result => {
                console.log(result)
                if (result.errors) {
                    return;
                }
                result = result.data.GetReportResultByOrganization;
                if (result.ListOrganization && result.ListOrganization.length)
                    result.ListOrganization = result.ListOrganization.filter(x => x.OrganizationName !== null);
                else
                    result.ListOrganization = [];
                var ColorBorder = result.ListOrganization.map((obj, index) => {
                    return colors[index % colors.length] + '1)';
                });
                this.chartStackedBar2Text.innerText = 'การส่งรายงานตามหน่วยงาน ' + (result.EvaluationTypeName || '') + ' (จำนวนการประเมินตลอดทั้งปี)';
                if (result && result.ListOrganization && result.ListOrganization.length > 10) {
                    this.barChartDiv2.style.height = '50vh';
                    if (result.ListOrganization && result.ListOrganization.length > 10)
                        this.barChartDiv2.style.minWidth = (result.ListOrganization.length * 75) + 'px';
                }
                else {
                    this.barChartDiv2.style.height = '';
                    this.barChartDiv2.style.minWidth = '';
                    this.chartStackedBarCanvas2.style.width = '100%';
                }
                chartVar.options.maintainAspectRatio = ((result && result.ListOrganization && result.ListOrganization.length > 10) ? false : true)
                chartVar.config.type = 'bar';
                chartVar.data.labels = result.ListOrganization.map(obj => (obj.OrganizationShortName) ? obj.OrganizationShortName : this.formatLabel(obj.OrganizationName));
                chartVar.data.datasets = [{
                    label: 'ส่งรายงานแล้ว',
                    data: result.ListOrganization.map(obj => obj.COUNTSent),
                    backgroundColor: ColorBorder = result.ListOrganization.map((obj, index) => {
                        return colors[index % colors.length] + '0.6)';
                    }),
                    borderColor: ColorBorder,
                    borderWidth: 1,
                },
                {
                    label: 'ทั้งหมด',
                    data: result.ListOrganization.map(obj => obj.COUNTSent + obj.COUNTNoSent),
                    backgroundColor: ColorBorder = result.ListOrganization.map((obj, index) => {
                        return colors[index % colors.length] + '0.2)';
                    }),
                    borderColor: ColorBorder,
                    borderWidth: 1,
                }];
                chartVar.update();
                chartVar.resize();
            })
            .catch(e => {
                console.log(e);
            })
    }

    renderNewLineChart = (EvaluationTypeID, Year, OrgID, KPIID, Person) => {
        if (this.props.roleID === 4 || this.props.roleID === 5) return;
        Year = (Year) ? Year : new Date().getFullYear();
        ReportService.reportTrendResult(EvaluationTypeID, Year, null, OrgID, KPIID, Person)
            .then(result => {
                this.LineChartDiv.style.height = '';
                this.LineChartDiv.style.minWidth = '';
                this.LineChart.style.width = '100%';
                result = result.data.GetReportTrendsResultByYear;
                result = result.filter(x => (x.SumResultScoreByKPI));
                var chartVar = this.state.LineChartVar;
                chartVar.data.labels = result.map((obj) => {
                    if (obj.Year) return obj.Year + 543;
                    else return '';
                });
                chartVar.config.type = 'line';
                chartVar.data.datasets = [
                    {
                        label: 'ผล',
                        data: result.map(obj => parseFloat(obj.SumResultScoreByKPIMain).toFixed(2)),
                        fill: false,
                        borderColor: 'rgba(245, 39, 62, 1)',
                        backgroundColor: 'rgba(245, 39, 62, 0.8)',
                        pointRadius: 7,
                        pointHoverRadius: 10
                    },
                    {
                        label: 'แผน',
                        data: result.map(obj => parseFloat(obj.SumResultPlanByKPIMain).toFixed(2)),
                        fill: false,
                        borderColor: 'rgba(20,160,230,1)',
                        backgroundColor: 'rgba(20,160,230,0.8)',
                        pointRadius: 7,
                        pointHoverRadius: 10
                    }
                ];
                chartVar.update();
                chartVar.resize();
                this.setState({
                    LineData: result,
                    LineStep: 0,
                    LineHistory: {
                        step0: null,
                        step1: null
                    }
                });
                return;
            })
            .catch(e => {
                console.log(e);
            })
    }

    onChange = (name, value) => {
        console.log(value)
        this.setState({ [name]: value });
    }

    convertToImage = (canvas, name, month) => {
        canvas.toBlob((blob) => {
            var tempUrl = window.URL.createObjectURL(blob);
            var tempLink = document.createElement('a');
            tempLink.href = tempUrl;
            tempLink.setAttribute('download', name + '_' + ((new Date().getFullYear()) + 543) + '_เดือน' + checkMonth(month) + '.png');
            tempLink.click();
        })
    }

    checkZoom = (index) => {
        if (this.state.zoom === index) return '12';
        return '6';
    }

    changeZoom = (index) => {
        if (this.state.zoom === index)
            index = 0;
        this.setState({ zoom: index });
    }

    onClickSearch = () => {
        if (
            this.state.searchEvaluationType === 0 &&
            this.state.searchYear === 0 &&
            !this.state.searchOrganization.OrganizationID &&
            !this.state.searchKPI.KPIID &&
            !this.state.searchPerson.PersonID
        ) {
            this.clearSearch(true);
        }
        else {
            this.setState({
                searched: {
                    searched: true,
                    searchedYear: this.state.searchYear,
                    searchedOrganization: this.state.searchOrganization,
                    searchedEvaluationType: this.state.searchEvaluationType,
                    searchedKPI: this.state.searchKPI,
                    searchedPerson: this.state.searchPerson
                }
            }, () => {
                this.renderNewDonutChart1(
                    this.state.searchEvaluationType,
                    this.state.donutChartStatus,
                    this.state.StatusReportMonth,
                    this.state.searched.searchedOrganization,
                    this.state.searched.searchedKPI,
                    this.state.searched.searchedPerson
                );
                this.renderNewDonutChart2(
                    this.state.searchEvaluationType,
                    this.state.donutChartResult,
                    this.state.ResultReportMonth,
                    this.state.searched.searchedOrganization,
                    this.state.searched.searchedKPI,
                    this.state.searched.searchedPerson
                );
                this.renderNewBarChart1(
                    this.state.searchEvaluationType,
                    this.state.searched.searchedYear,
                    this.state.searched.searchedOrganization,
                    this.state.searched.searchedKPI,
                    this.state.searched.searchedPerson
                );
                this.renderNewBarChart2(
                    this.state.searchEvaluationType,
                    this.state.searched.searchedYear,
                    this.state.searched.searchedOrganization,
                    this.state.searched.searchedKPI,
                    this.state.searched.searchedPerson
                );
                this.renderNewLineChart(
                    this.state.searchEvaluationType,
                    this.state.searched.searchedYear,
                    this.state.searched.searchedOrganization,
                    this.state.searched.searchedKPI,
                    this.state.searched.searchedPerson
                );
            });
        }
    }

    clearSearch = (value) => {
        this.setState({
            StatusReportMonth: new Date().getMonth() + 1,
            ResultReportMonth: new Date().getMonth() + 1,
            searchYear: 0,
            searchOrganization: {},
            searchPerson: {},
            searchEvaluationType: 6,
            searchKPI: {},
            searched: {
                searched: false,
                searchedYear: 0,
                searchedOrganization: {
                    OrganizationID: 0,
                    OrganizationName: ''
                },
                searchedEvaluationType: 0,
                searchedKPI: {
                    KPIID: 0,
                    KPIName: ''
                },
                searchedPerson: {
                    PersonID: 0,
                    PersonName: ''
                }
            }
        }, () => {
            if (value) {
                this.renderNewDonutChart1(this.state.searchEvaluationType, this.state.donutChartStatus, (new Date().getMonth() + 1));
                this.renderNewDonutChart2(this.state.searchEvaluationType, this.state.donutChartResult, (new Date().getMonth() + 1));
                this.renderNewBarChart1(this.state.searchEvaluationType, new Date().getFullYear());
                this.renderNewBarChart2(this.state.searchEvaluationType, new Date().getFullYear());
                this.renderNewLineChart(this.state.searchEvaluationType, new Date().getFullYear());
                this.setState({
                    StatusReportMonth: (new Date().getMonth() + 1),
                    ResultReportMonth: (new Date().getMonth() + 1),
                });
            }
        });
    }

    renderdonutChart1 = () => {
        return (
            <div className={'col-12 mt-4 col-lg-' + this.checkZoom(1)}>
                <div className='p-4 custom-block'>
                    <button
                        className='btn btn-outline-primary btn-sm float-right btn-zoom'
                        onClick={() => this.changeZoom(1)}
                    >
                        <i className={'fa fa-search-' + ((this.checkZoom(1) === '12') ? 'minus' : 'plus')} />
                    </button>
                    การส่งรายงาน เดือน
                    <select
                        className='form-control mt-2'
                        name='donutChartStatus'
                        onChange={e => {
                            this.renderNewDonutChart1(
                                this.state.searched.searchedEvaluationType,
                                this.state[e.target.name],
                                e.target.value,
                                this.state.searched.searchedOrganization,
                                this.state.searched.searchedKPI,
                                this.state.searched.searchedPerson
                            )
                            this.onChange('StatusReportMonth', e.target.value)
                        }
                        }
                        value={this.state.StatusReportMonth}
                    >
                        {
                            renderMonth('Dashboard')
                        }
                    </select>
                    <canvas ref={el => this.donutChart1 = el} className='mt-2' />
                    <div className='d-flex justify-content-end'>
                        <button
                            className='btn btn-sm btn-outline-success'
                            onClick={() => this.convertToImage(this.donutChart1, 'การส่งรายงาน', this.state.StatusReportMonth)}
                            data-for='DashboardTooltip'
                            data-tip='Export PNG'
                        >
                            <i className='fa fa-file-image-o' />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    renderdonutChart2 = () => {
        return (
            <div className={'mt-4 col-12 col-lg-' + this.checkZoom(2)}>
                <div className='p-4 custom-block h-100'>
                    <button
                        className='btn btn-outline-primary btn-sm float-right btn-zoom'
                        onClick={() => this.changeZoom(2)}
                    >
                        <i className={'fa fa-search-' + ((this.checkZoom(2) === '12') ? 'minus' : 'plus')} />
                    </button>
                    ผลการประเมิน เดือน
                            <select
                        className='form-control mt-2'
                        name='donutChartResult'
                        onChange={e => {
                            if (!this.state.searched.searched)
                                this.renderNewDonutChart2(
                                    this.state.searchEvaluationType,
                                    this.state[e.target.name],
                                    e.target.value,
                                    this.state.searchOrganization,
                                    this.state.searchKPI,
                                    this.state.searchPerson
                                )
                            else
                                this.renderNewDonutChart2(
                                    this.state.searched.searchedEvaluationType,
                                    this.state[e.target.name],
                                    e.target.value,
                                    this.state.searched.searchedOrganization,
                                    this.state.searched.searchedKPI,
                                    this.state.searched.searchedPerson
                                )
                            this.onChange('ResultReportMonth', e.target.value)
                        }
                        }
                        value={this.state.ResultReportMonth}
                    >
                        {
                            renderMonth('Dashboard')
                        }
                    </select>
                    <canvas ref={el => this.donutChart2 = el} className='mt-2' />
                    <div className='d-flex justify-content-end'>
                        <button
                            className='btn btn-sm btn-outline-success'
                            onClick={() => this.convertToImage(this.donutChart2, 'ผลการประเมิน', this.state.ResultReportMonth)}
                            data-for='DashboardTooltip'
                            data-tip='Export PNG'
                        >
                            <i className='fa fa-file-image-o' />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    renderLine = () => {
        return (
            <div className={'mt-4 col-12 col-lg-' + this.checkZoom(5)}>
                <div className='p-4 custom-block h-100'>
                    <div className='float-right'>
                        {
                            (this.state.LineStep !== 0) ?
                                <button
                                    className='btn btn-outline-secondary btn-sm mr-2'
                                    onClick={() => this.clickBackLineChart(this.state.LineStep)}
                                >
                                    <i className='fa fa-arrow-left' />
                                </button>
                                : null
                        }
                        <button
                            className='btn btn-outline-primary btn-sm btn-zoom'
                            onClick={() => this.changeZoom(5)}
                        >
                            <i className={'fa fa-search-' + ((this.checkZoom(5) === '12') ? 'minus' : 'plus')} />
                        </button>
                    </div>
                    <div ref={el => this.LineChartText = el}></div>

                    <div className='overflow-div w-100' style={{ overflowY: 'hidden' }}>
                        <div ref={el => this.LineChartDiv = el}>
                            <canvas ref={el => this.LineChart = el} className='mt-2' onClick={e => this.clickLineChart(e, this.state.LineStep)} />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button
                            className='btn btn-sm btn-outline-success'
                            onClick={() => this.convertToImage(this.LineChart, 'ผลรายเดือน', '')}
                            data-for='DashboardTooltip'
                            data-tip='Export PNG'
                        >
                            <i className='fa fa-file-image-o' />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    clickLineChart = (e, index) => {
        var chartVar = this.state.LineChartVar;
        if (!chartVar || !e) return;
        if (index === 0) {
            if (!chartVar.getElementAtEvent(e)[0]) return;
            var index = chartVar.getElementAtEvent(e)[0]._index;
            var newData = this.state.LineData[index];
            console.log(newData);
            if (!newData.Policy) return;
            var newLabel = [];
            newData.Policy.forEach(x => x.KPIMain.forEach(z => newLabel.push(this.formatLabel(z.KPIMainName))));
            var newData1 = [];
            newData.Policy.forEach(x => x.KPIMain.forEach(z => newData1.push(z.ResultScore)));
            var newData2 = [];
            newData.Policy.forEach(x => x.KPIMain.forEach(z => newData2.push(z.ResultPlan)));
            chartVar.data.labels = newLabel;
            chartVar.config.type = 'bar';
            chartVar.data.datasets = [
                {
                    label: 'แผน',
                    data: newData2,
                    fill: false,
                    borderColor: 'rgba(20,160,230,1)',
                    backgroundColor: 'rgba(20,160,230,0.8)',
                    borderWidth: 1
                },
                {
                    label: 'ผล',
                    data: newData1,
                    fill: false,
                    borderColor:
                        'rgba(245, 39, 62, 1)',
                    backgroundColor:
                        'rgba(245, 39, 62, 0.8)',
                    borderWidth: 1
                }
            ];
            chartVar.options.scales.xAxes[0].scaleLabel.labelString = 'ตัวชี้วัดหลัก';
            chartVar.options.maintainAspectRatio = ((newData1 && newData1.length > 10) ? false : true);
            if (newData1.length > 10) {
                this.LineChartDiv.style.height = '50vh';
                this.LineChartDiv.style.minWidth = (newData1.length * 75) + 'px';
            }
            else {
                this.LineChartDiv.style.height = '';
                this.LineChartDiv.style.minWidth = '';
                this.LineChart.style.width = '100%';
            }
            var newText = this.LineChartText.innerText.split("(");
            newText = newText[0] + "(ตามตัวชี้วัดหลัก)";
            this.LineChartText.innerText = newText;
            this.setState({ LineStep: 1, LineHistory: { step0: index, step1: null } });
        }
        else if (index === 1) {
            if (!chartVar.getElementAtEvent(e)[0]) return;
            var index = chartVar.getElementAtEvent(e)[0]._index;
            var lineData = this.state.LineData[this.state.LineHistory.step0];
            if (!lineData.Policy) return;
            console.log(lineData);
            var newData = [];
            lineData.Policy.forEach(x => x.KPIMain.forEach(z => newData.push(z.KPI)));
            if (!newData[index] || typeof newData[index] !== 'object') return;
            newData = newData[index];
            chartVar.data.labels = newData.map(x => this.formatLabel(x.KPIName));
            chartVar.config.type = 'bar';
            var newDataPlan = newData.map(x => x.ResultPlan);
            var newDataScore = newData.map(x => x.ResultScore)
            chartVar.data.datasets = [
                {
                    label: 'แผน',
                    data: newDataPlan,
                    fill: false,
                    borderColor: 'rgba(20,160,230,1)',
                    backgroundColor: 'rgba(20,160,230,0.8)',
                    borderWidth: 1
                },
                {
                    label: 'ผล',
                    data: newDataScore,
                    fill: false,
                    borderColor:
                        'rgba(245, 39, 62, 1)',
                    backgroundColor:
                        'rgba(245, 39, 62, 0.8)',
                    borderWidth: 1
                }
            ];
            chartVar.options.scales.xAxes[0].scaleLabel.labelString = 'ตัวชี้วัด';
            chartVar.options.maintainAspectRatio = ((newDataPlan && newDataPlan.length > 10) ? false : true);
            if (newDataPlan.length > 10) {
                this.LineChartDiv.style.height = '50vh';
                this.LineChartDiv.style.minWidth = (newDataPlan.length * 75) + 'px';
            }
            else {
                this.LineChartDiv.style.height = '';
                this.LineChartDiv.style.minWidth = '';
                this.LineChart.style.width = '100%';
            }
            var newText = this.LineChartText.innerText.split("(");
            newText = newText[0] + "(ตามตัวชี้วัด)";
            this.LineChartText.innerText = newText;
            this.setState({ LineStep: 2, LineHistory: { ...this.state.LineHistory, step1: index } });
        }
        chartVar.update();
    }

    clickBackLineChart = (index) => {
        var chartVar = this.state.LineChartVar;
        if (!chartVar) return;
        switch (index) {
            case 1: {
                var newData = this.state.LineData;
                chartVar.data.labels = newData.map((obj) => {
                    if (obj.Year) return obj.Year + 543;
                    else return '';
                });
                chartVar.config.type = 'line';
                chartVar.data.datasets = [
                    {
                        label: 'ผล',
                        data: newData.map(obj => parseFloat(obj.SumResultScoreByKPIMain).toFixed(2)),
                        fill: false,
                        borderColor: 'rgba(245, 39, 62, 1)',
                        backgroundColor: 'rgba(245, 39, 62, 0.8)',
                        pointRadius: 7,
                        pointHoverRadius: 10
                    },
                    {
                        label: 'แผน',
                        data: newData.map(obj => parseFloat(obj.SumResultPlanByKPIMain).toFixed(2)),
                        fill: false,
                        borderColor: 'rgba(20,160,230,1)',
                        backgroundColor: 'rgba(20,160,230,0.8)',
                        pointRadius: 7,
                        pointHoverRadius: 10
                    }
                ];
                chartVar.options.scales.xAxes[0].scaleLabel.labelString = 'ปีงบประมาณ';
                chartVar.options.maintainAspectRatio = true;
                this.LineChartDiv.style.height = '';
                this.LineChartDiv.style.minWidth = '';
                this.LineChart.style.width = '100%';
                var newText = this.LineChartText.innerText.split("(");
                newText = newText[0] + "(ตามปีงบประมาณ)";
                this.LineChartText.innerText = newText;
                chartVar.update();
                this.setState({
                    LineStep: 0,
                    LineHistory: {
                        step0: null,
                        step1: null
                    }
                });
                return;
            }
            case 2: {
                var newData = this.state.LineData[this.state.LineHistory.step0];
                if (!newData.Policy) return;
                var newLabel = [];
                newData.Policy.forEach(x => x.KPIMain.forEach(z => newLabel.push(this.formatLabel(z.KPIMainName))));
                var newData1 = [];
                newData.Policy.forEach(x => x.KPIMain.forEach(z => newData1.push(z.ResultScore)));
                var newData2 = [];
                newData.Policy.forEach(x => x.KPIMain.forEach(z => newData2.push(z.ResultPlan)));
                console.log(newLabel)
                chartVar.data.labels = newLabel;
                chartVar.config.type = 'bar';
                chartVar.data.datasets = [
                    {
                        label: 'แผน',
                        data: newData2,
                        fill: false,
                        borderColor: 'rgba(20,160,230,1)',
                        backgroundColor: 'rgba(20,160,230,0.8)',
                        borderWidth: 1
                    },
                    {
                        label: 'ผล',
                        data: newData1,
                        fill: false,
                        borderColor:
                            'rgba(245, 39, 62, 1)',
                        backgroundColor:
                            'rgba(245, 39, 62, 0.8)',
                        borderWidth: 1
                    }
                ];
                chartVar.options.scales.xAxes[0].scaleLabel.labelString = 'ตัวชี้วัดหลัก';
                chartVar.options.maintainAspectRatio = ((newData1 && newData1.length > 10) ? false : true);
                if (newData1.length > 10) {
                    this.LineChartDiv.style.height = '50vh';
                    this.LineChartDiv.style.minWidth = (newData1.length * 75) + 'px';
                }
                else {
                    this.LineChartDiv.style.height = '';
                    this.LineChartDiv.style.minWidth = '';
                    this.LineChart.style.width = '100%';
                }
                var newText = this.LineChartText.innerText.split("(");
                newText = newText[0] + "(ตามตัวชี้วัดหลัก)";
                this.LineChartText.innerText = newText;
                chartVar.update();
                this.setState({
                    LineStep: 1,
                    LineHistory: {
                        ...this.state.LineHistory,
                        step1: null
                    }
                });
                return;
            }
            default: return;
        }
    }

    changeSummaryDisplay = (data) => {
        this.SummaryPass.innerText = data.find(x => x.StatusID === 1).Total;
        this.SummaryNoReport.innerText = data.find(x => x.StatusID === 2).Total;
        this.SummaryWait.innerText = data.find(x => x.StatusID === 3).Total;
        this.SummaryNotPass.innerText = data.find(x => x.StatusID === 4).Total;
    }

    onClickBarChart = (e, chartVar, stackedBarDisplayType) => {
        if (!chartVar || !this.state.stackedbarData.Total) return;
        if (!e) {
            var result = this.state.stackedbarData;
            chartVar.data.labels = result.ListSent.map(obj => obj.KPIName);
            chartVar.data.datasets = [{
                label: 'ประเมินแล้ว',
                data: result.ListSent.map(obj => obj.COUNT),
                backgroundColor: [
                    "rgba(255, 205, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(157, 149, 164, 0.8)"
                ],
                borderColor: [
                    "rgb(255, 205, 86)",
                    "rgb(75, 192, 192)",
                    "rgb(54, 162, 235)",
                    "rgb(153, 102, 255)",
                    "rgb(157, 149, 164)"
                ],
                borderWidth: 1
            },
            {
                label: 'ทั้งหมด',
                data: result.ListSent.map(() => result.Total),
                backgroundColor: [
                    "rgba(255, 205, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(201, 203, 207, 0.2)"
                ],
                borderColor: [
                    "rgb(255, 205, 86)",
                    "rgb(75, 192, 192)",
                    "rgb(54, 162, 235)",
                    "rgb(153, 102, 255)",
                    "rgb(201, 203, 207)"
                ],
                borderWidth: 1
            }];
            this.setState({ stackedBarDisplayType: 0 });
        }
        else {
            if (!chartVar.getElementAtEvent(e)[0] || this.state.stackedBarDisplayType === 1) return;
            var index = chartVar.getElementAtEvent(e)[0]._index;
            var newData = this.state.stackedbarData.ListNoSent[index];
            chartVar.data.labels = newData.ListOrganization.map(obj =>
                (obj.OrganizationShortName) ? obj.OrganizationShortName : obj.OrganizationName
            );
            chartVar.data.datasets = [{
                label: 'จำนวนครั้งที่ไม่ส่ง',
                data: newData.ListOrganization.map(obj => obj.COUNTReport),
                backgroundColor: [
                    "rgba(255, 205, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(157, 149, 164, 0.8)"
                ],
                borderColor: [
                    "rgb(255, 205, 86)",
                    "rgb(75, 192, 192)",
                    "rgb(54, 162, 235)",
                    "rgb(153, 102, 255)",
                    "rgb(157, 149, 164)"
                ],
                borderWidth: 1
            }];
            this.setState({ stackedBarDisplayType: 1 });
        }
        chartVar.update();
    }

    saveProfile = () => {
        if (!this.state.searched.searched) {
            Swal.fire({
                title: 'กรุณาค้นหาก่อนสร้างโปรไฟล์',
                text: 'กรุณาค้นหาก่อนสร้างโปรไฟล์',
                type: 'warning'
            });
            return;
        }
        Swal.fire({
            title: 'ระบุชื่อโปรไฟล์',
            input: 'text',
            inputValue: '',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง',
            reverseButtons: true,
            showLoaderOnConfirm: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'กรุณาระบุชื่อโปรไฟล์'
                }
            }
        })
            .then(res => {
                if (!res.value) return;
                return ReportService.createReportProfile(res.value, this.state.searched);
            })
            .then(res => {
                console.log(res);
                if (!res) return;
                if (res.errors) {
                    Swal.fire({
                        type: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                    });
                    return
                }
                Swal.fire({
                    type: 'success',
                    title: 'บันทึกข้อมูลสำเร็จ'
                });
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    type: 'error',
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                });
            })
    }

    showProfile = () => {
        if (!this.state.selectedReportProfile.ProfileID) return;
        try {
            var newSearched = JSON.parse(this.state.selectedReportProfile.ProfileData);

            console.log(newSearched);
            this.setState({
                searchYear: (newSearched.searchedYear || 0),
                searchOrganization: (newSearched.searchedOrganization || {}),
                searchEvaluationType: (newSearched.searchedEvaluationType || 0),
                searchKPI: (newSearched.searchedKPI || {}),
                searchedPerson: (newSearched.searchedPerson || {}),
                StatusReportMonth: new Date().getMonth() + 1,
                ResultReportMonth: new Date().getMonth() + 1
            }, () => {
                this.onClickSearch();
            });
        }
        catch (e) {
            console.log(e);
        }
    }

    deleteProfile = () => {
        if (!this.state.selectedReportProfile.ProfileID) return;
        ReportService.deleteReportProfile(this.state.selectedReportProfile.ProfileID)
            .then(result => {
                if (result.errors) {
                    Swal.fire({
                        type: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                    });
                    return
                }
                Swal.fire({
                    type: 'success',
                    title: 'ลบข้อมูลสำเร็จ'
                });
                this.setState({ selectedReportProfile: {} });
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    type: 'error',
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                });
            })
    }

    checkEvaluationType = (TypeID) => {
        TypeID = parseInt(TypeID);
        for (var i = 0; i < this.state.EvaluationTypes.length; i++) {
            if (TypeID === parseInt(this.state.EvaluationTypes[i].EvaluationTypeID)) {
                return this.state.EvaluationTypes[i].EvaluationTypeName;
            }
        }
    }

    renderSearched = (searched) => {
        console.log(searched);
        var el = [];
        if (searched.searchedYear !== 0) {
            el.push(
                <div className='search-pill' key='Dashboard-searched1'>
                    ปีงบประมาณ : {searched.searchedYear + 543}
                </div>
            );
        }
        if (searched.searchedEvaluationType !== 0) {
            el.push(
                <div className='search-pill' key='Dashboard-searched2'>
                    ประเภทการประเมิน: {this.checkEvaluationType(searched.searchedEvaluationType)}
                </div>
            );
        }

        if (searched.searchedOrganization && searched.searchedOrganization.OrganizationID !== undefined) {
            el.push(
                <div className='search-pill' key='Dashboard-searched3'>
                    หน่วยงาน: {searched.searchedOrganization.OrganizationName}
                </div>
            );
        }
        if (searched.searchedKPI && searched.searchedKPI.KPIID !== undefined) {
            el.push(
                <div className='search-pill' key='Dashboard-searched4'>
                    ตัวชี้วัด: {searched.searchedKPI.KPIName}
                </div>
            );
        }
        if (searched.searchedPerson && searched.searchedPerson.PersonID !== undefined) {
            el.push(
                <div className='search-pill' key='Dashboard-searched5'>
                    บุคคล: {searched.searchedPerson.PersonName}
                </div>
            );
        }
        return el;
    }

    render() {
        return (
            <>
                <div className='custom-block p-4 '>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-12 col-md-6 form-group'>
                            <label className='form-label'>รูปแบบการรายงาน</label>
                            <Autocomplete
                                getData={this.getReportProfile}
                                name='selectedReportProfile'
                                labelName='โปรไฟล์'
                                getDataName='GetReportProfile'
                                dataName='Profile'
                                onChangeSelect={this.onChange}
                                selectValue={this.state.selectedReportProfile}
                                searchOnChange
                            />
                        </div>
                        <div className='col-12 col-md-6 mt-3'>
                            <button className='btn btn-outline-primary mr-2' onClick={this.showProfile}>แสดง</button>
                            <button className='btn btn-outline-primary mr-2' onClick={this.saveProfile}>บันทึก</button>
                            <button className='btn btn-outline-primary mr-2' onClick={this.deleteProfile}>ลบ</button>
                        </div>
                    </div>
                </div>

                <div className='custom-block p-4 mt-4'>
                    <div className="row align-items-end">
                        <div className='col-12 form-group col-md-6 col-lg-4'>

                            <label className='form-label'>ประเภทการประเมิน</label>
                            <select
                                className='form-control'
                                value={this.state.searchEvaluationType}
                                name='searchEvaluationType'
                                onChange={e => this.onChange(e.target.name, parseInt(e.target.value))}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                {
                                    this.state.EvaluationTypes.map((obj, index) => {
                                        if (obj.EvaluationTypeID !== 3 && obj.EvaluationTypeID !== 6) return null;
                                        return (
                                            <option value={obj.EvaluationTypeID} key={'Dashboard-EvaluationType-option' + index}>
                                                {obj.EvaluationTypeName}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 form-group'>
                            <label className='form-label'>ปีงบประมาณ</label>
                            <select
                                className='form-control'
                                value={this.state.searchYear}
                                name='searchYear'
                                onChange={e => this.onChange(e.target.name, parseInt(e.target.value))}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                {renderYear('Dashboard-searchYear')}
                            </select>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 form-group'>
                            <label className='form-label'>หน่วยงาน</label>
                            <Autocomplete
                                getData={this.searchOrganization}
                                name='searchOrganization'
                                labelName='หน่วยงาน'
                                dataName='Organization'
                                onChangeSelect={this.onChange}
                                selectValue={this.state.searchOrganization}
                            />
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 form-group'>
                            <label className='form-label'>ตัวชี้วัด</label>
                            <Autocomplete
                                getData={this.searchKPI}
                                name='searchKPI'
                                labelName='ตัวชี้วัด'
                                dataName='KPI'
                                listName='ListKPI'
                                onChangeSelect={this.onChange}
                                selectValue={this.state.searchKPI}
                                searchOnChange
                            />
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 form-group'>
                            <label className='form-label'>ผู้รับผิดชอบตัวชี้วัด</label>
                            <Autocomplete
                                getData={this.searchPerson}
                                name='searchPerson'
                                labelName='ผู้รับผิดชอบตัวชี้วัด'
                                dataName='Person'
                                onChangeSelect={this.onChange}
                                selectValue={this.state.searchPerson}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button className='btn btn-outline-primary mr-1' onClick={() => { this.clearSearch(true); }}>ยกเลิก</button>
                        <button className='btn btn-outline-primary' onClick={this.onClickSearch}>ค้นหา</button>
                    </div>
                    {
                        (this.state.searched.searched !== false) ?
                            <div>
                                ผลการค้นหา : {this.renderSearched(this.state.searched)}
                            </div>
                            : null
                    }
                </div>

                {/* <div className='mt-4 text-right'>
                    <button
                        className='btn btn-outline-primary btn-sm'
                        onClick={() => { this.setState({ displayType: (this.state.displayType === 0) ? 1 : 0 }) }}
                    >
                        {
                            (this.state.displayType === 0) ?
                                'แสดงกราฟสรุป' : 'แสดงผลสรุป'
                        }
                    </button>
                </div> */}

                <div
                    className={'mt-4 row col-zoom overflow-div Evaluation-div '
                        + (!(this.state.displayType === 1) ? '' : 'Evaluation-div-disable')}
                >
                    {/* <div className='col-12'>
                        <div className='custom-block d-inline-block p-2 pl-3 pr-3'>
                            <div className='d-inline-block' >การส่งรายงาน เดือน</div>
                            <div className='d-inline-block ml-2' style={{ maxWidth: '300px' }}>
                                <select
                                    className='form-control mt-2'
                                    name='donutChartStatus'
                                    onChange={e => {
                                        this.renderNewDonutChart1(
                                            this.state[e.target.name],
                                            e.target.value,
                                            this.state.searched.searchedOrganization,
                                            this.state.searched.searchedKPI,
                                            this.state.searched.searchedPerson
                                        )
                                        this.onChange('StatusReportMonth', e.target.value)
                                    }
                                    }
                                    value={this.state.StatusReportMonth}
                                >
                                    {
                                        renderMonth('Dashboard')
                                    }
                                </select>
                            </div>
                        </div>
                    </div> */}
                    <div className='col-xl-3 col-md-6 col-12 mt-3'>
                        <div className='text-right custom-block pb-3 h-100'>
                            <div className='card-icon card-icon-success text-white d-flex align-items-center justify-content-center'>
                                <i className='fa fa-check fa-2x' />
                            </div>
                            <div className='font-title-card'>รายงานเรียบร้อย</div>
                            <hr className='m-2' />
                            <div ref={el => this.SummaryPass = el}>0</div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-6 col-12 mt-3'>
                        <div className='text-right custom-block pb-3 h-100'>
                            <div className='card-icon card-icon-info text-white d-flex align-items-center justify-content-center'>
                                <i className='fa fa-hourglass-o fa-2x' />
                            </div>
                            <div className='font-title-card'>รอการอนุมัติ</div>
                            <hr className='m-2' />
                            <div ref={el => this.SummaryWait = el}>0</div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-6 col-12 mt-3'>
                        <div className='text-right custom-block pb-3 h-100'>
                            <div className='card-icon card-icon-warning text-white d-flex align-items-center justify-content-center'>
                                <i className='fa fa-warning fa-2x' />
                            </div>
                            <div className='font-title-card'>รายงานไม่เรียบร้อย</div>
                            <hr className='m-2' />
                            <div ref={el => this.SummaryNotPass = el}>0</div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-6 col-12 mt-3'>
                        <div className='text-right custom-block pb-3 h-100'>
                            <div className='card-icon card-icon-danger text-white d-flex align-items-center justify-content-center'>
                                <i className='fa fa-times fa-2x' />
                            </div>
                            <div className='font-title-card'>ยังไม่รายงาน</div>
                            <hr className='m-2' />
                            <div ref={el => this.SummaryNoReport = el}>0</div>
                        </div>
                    </div>
                </div>

                <div
                    className={'row col-zoom overflow-div Evaluation-div '
                        + (!(this.state.displayType === 0) ? '' : 'Evaluation-div-disable')}
                >
                    {this.renderdonutChart1()}
                </div>

                {
                    (this.props.roleID !== 4 && this.props.roleID !== 5) ?

                        <div className='row col-zoom'>
                            {this.renderLine()}
                            {this.renderdonutChart2()}
                        </div>

                        : null
                }


                <div className='row col-zoom'>

                    <div className={'col-12 mt-4 col-lg-' + this.checkZoom(3)}>
                        <div className='p-4 custom-block h-100'>
                            <div className='float-right'>
                                <button
                                    className='btn btn-outline-primary btn-sm btn-zoom'
                                    onClick={() => this.changeZoom(3)}
                                >
                                    <i className={'fa fa-search-' + ((this.checkZoom(3) === '12') ? 'minus' : 'plus')} />
                                </button>
                            </div>
                            <div ref={el => this.chartStackedBar1Text = el}></div>
                            <div className='overflow-div w-100' style={{ overflowY: 'hidden' }}>
                                <div ref={el => this.barChartDiv1 = el}>
                                    <canvas
                                        ref={el => this.chartStackedBarCanvas1 = el}
                                        className='mt-3'
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <button
                                    className='btn btn-sm btn-outline-success'
                                    onClick={() => this.convertToImage(this.chartStackedBarCanvas1, 'การส่งรายงานตามองค์ประกอบ', this.state.StatusReportByPolicyMonth)}
                                    data-for='DashboardTooltip'
                                    data-tip='Export PNG'
                                >
                                    <i className='fa fa-file-image-o' />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className={'col-12 mt-4 col-lg-' + this.checkZoom(4)}>
                        <div className='p-4 custom-block h-100'>
                            <button
                                className='btn btn-outline-primary btn-sm float-right btn-zoom'
                                onClick={() => this.changeZoom(4)}
                            >
                                <i className={'fa fa-search-' + ((this.checkZoom(4) === '12') ? 'minus' : 'plus')} />
                            </button>
                            <div ref={el => this.chartStackedBar2Text = el}></div>
                            <div className='overflow-div w-100' style={{ overflowY: 'hidden' }}>
                                <div ref={el => this.barChartDiv2 = el}>
                                    <canvas ref={el => this.chartStackedBarCanvas2 = el} className='mt-3' />
                                </div>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <button
                                    className='btn btn-sm btn-outline-success'
                                    onClick={() => this.convertToImage(this.chartStackedBarCanvas2, 'การส่งรายงานตามองค์ประกอบ', this.state.StatusReportByPolicyMonth)}
                                    data-for='DashboardTooltip'
                                    data-tip='Export PNG'
                                >
                                    <i className='fa fa-file-image-o' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    (this.props.roleID === 4 || this.props.roleID === 5) ?
                        <div className='row col-zoom'>
                            {this.renderdonutChart2()}
                        </div>
                        : null
                }
                <div className='mb-4' />

                <ReactTooltip
                    id='DashboardTooltip'
                    delayShow={400}
                />

                {/* <button
                    className='btn btn-outline-secondary btn-sm btn-zoom mr-2'
                    onClick={() => this.onClickBarChart(null, this.state.stackedBar1, this.state.stackedBarDisplayType)}
                >
                    <i className='fa fa-arrow-left' />
                </button>
                
                onClick={e => this.onClickBarChart(e, this.state.stackedBar1)} */}

            </>
        );
    }
}

export default connect(null)(Dashboard);