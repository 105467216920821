import React from 'react';
import { showLoading, hideLoading } from '../../Redux/Action';
import Swal from 'sweetalert2'
import HistoryTable from './HIstory_table';
import HistoryService from '../../Service/HistoryService';
import DatePicker from '../../Utilities/Datepicker';
import { connect } from 'react-redux'

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data: [],
            dataCount: 0,
            searchUsername: '',
            searchStartDate: null,
            searchEndDate: null,
            searchModule: '',
            searchIP: '',
            searchAction: '',
            searched: {
                isSearched: false,
                searchedUsername: '',
                searchedStartDate: null,
                searchedEndDate: null,
                searchedModule: '',
                searchedIP: '',
                searchedAction: '',
            },
            currentPage: 1,
            limit: 5
        }
    }

    componentDidMount() {
        this.searchHistory(null, null, null, null, null, null, null, null);
    }

    searchHistory = (StartDate, EndDate, Username, Action, Module, IP, Offset, Limit) => {
        this.props.dispatch(showLoading());
        HistoryService.searchHistory(StartDate, EndDate, Username, Action, Module, IP, Offset, Limit)
            .then(result => {
                this.props.dispatch(hideLoading());
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    });
                    return;
                }
                console.log(result);
                this.setState({
                    data: result.data.SearchHistory.ListHistory,
                    dataCount: result.data.SearchHistory.Count
                })
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            })
    }

    formatDate = (date) => {
        if (!date) return null;
        return date.getFullYear() + '-' +
            (((date.getMonth() + 1) < 10) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1)) + '-' +
            (((date.getDate() + 1) <= 10) ? ('0' + (date.getDate())) : (date.getDate()))
    }

    searchHistoryAfterSetState = () => {
        if (this.state.searched.isSearched === true) {
            this.searchHistory(
                this.formatDate(this.state.searched.searchedStartDate),
                this.formatDate(this.state.searched.searchedEndDate),
                this.state.searched.searchedUsername,
                this.state.searched.searchedAction,
                this.state.searched.searchedModule,
                this.state.searched.searchedIP,
                (this.state.currentPage - 1) * this.state.limit,
                this.state.limit
            )
        }
        else {
            this.searchHistory(
                null,
                null,
                null,
                null,
                null,
                null,
                (this.state.currentPage - 1) * this.state.limit,
                this.state.limit
            )
        }
    }

    onChangeTableState = (name, value, value2) => {
        if (name === '')
            this.setState({
                currentPage: value,
                limit: value2
            }, () => {
                this.searchHistoryAfterSetState()
            });
        else
            this.setState({ [name]: value }, () => {
                this.searchHistoryAfterSetState()
            });
    }

    onChangeSearch = (name, value) => {
        this.setState({ [name]: value });
    }

    clickSearch = () => {
        if (
            this.state.searchUsername === '' &&
            this.state.searchStartDate === null &&
            this.state.searchEndDate === null &&
            this.state.searchModule === '' &&
            this.state.searchAction === '' &&
            this.state.searchIP === ''
        ) {
            this.clearSearch();
            return;
        }
        this.setState({
            // searchUsername: '',
            // searchStartDate: null,
            // searchEndDate: null,
            // searchModule: '',
            // searchAction: '',
            // searchIP: '',
            currentPage: 1,
            searched: {
                isSearched: true,
                searchedUsername: this.state.searchUsername,
                searchedStartDate: this.state.searchStartDate,
                searchedEndDate: this.state.searchEndDate,
                searchedModule: this.state.searchModule,
                searchedAction: this.state.searchAction,
                searchedIP: this.state.searchIP
            }
        }, () => {
            this.searchHistoryAfterSetState();
        });
    }

    clearSearch = () => {
        this.setState({
            searchUsername: '',
            searchStartDate: null,
            searchEndDate: null,
            searchModule: '',
            searchAction: '',
            searchIP: '',
            currentPage: 1,
            searched: {
                isSearched: false,
                searchedUsername: '',
                searchedStartDate: null,
                searchEndDate: null,
                searchedModule: '',
                searchedAction: '',
                searchedIP: ''
            }
        }, () => {
            this.searchHistory(
                null,
                null,
                null,
                null,
                null,
                null,
                0,
                this.state.limit
            )
        })
    }

    render() {
        return (
            <>
                <div className='custom-block mb-4'>
                    <div className='row align-items-end'>
                        <div className='form-group col-12 col-lg-4'>
                            <label className='form-label'>วันเริ่ม</label>
                            <DatePicker
                                name='searchStartDate'
                                placeholderText='วันเริ่ม'
                                dateData={this.state.searchStartDate}
                                setDate={this.onChangeSearch}
                                startDate={this.state.searchStartDate}
                                endDate={this.state.searchEndDate}
                                maxDate={this.state.searchEndDate}
                            />
                        </div>
                        <div className='form-group col-12 col-lg-4'>
                            <label className='form-label'>วันจบ</label>
                            <DatePicker
                                name='searchEndDate'
                                placeholderText='วันจบ'
                                dateData={this.state.searchEndDate}
                                setDate={this.onChangeSearch}
                                startDate={this.state.searchStartDate}
                                endDate={this.state.searchEndDate}
                                minDate={this.state.searchStartDate}
                            />
                        </div>
                        <div className='form-group col-12 col-lg-4'>
                            <label className='form-label'>ชื่อผู้ใช้งาน</label>
                            <input
                                className='form-control'
                                name='searchUsername'
                                placeholder='ชื่อผู้ใช้งาน'
                                value={this.state.searchUsername}
                                onChange={e => this.onChangeSearch(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className='form-group col-12 col-lg-4'>
                            <label className='form-label'>Module</label>
                            <input
                                className='form-control'
                                name='searchModule'
                                placeholder='Module'
                                value={this.state.searchModule}
                                onChange={e => this.onChangeSearch(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className='form-group col-12 col-lg-4'>
                            <label className='form-label'>Action</label>
                            <input
                                className='form-control'
                                name='searchAction'
                                placeholder='Action'
                                value={this.state.searchAction}
                                onChange={e => this.onChangeSearch(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className='form-group col-12 col-lg-4'>
                            <label className='form-label'>IP</label>
                            <input
                                className='form-control'
                                name='searchIP'
                                placeholder='IP'
                                value={this.state.searchIP}
                                onChange={e => this.onChangeSearch(e.target.name, e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='mr-2 btn btn-outline-danger' onClick={this.clearSearch}>ยกเลิก</button>
                        <button type='button' className='btn btn-outline-primary' onClick={this.clickSearch}>ค้นหา</button>
                    </div>
                </div>

                <div className='custom-block mb-4'>
                    <HistoryTable
                        data={this.state.data}
                        dataCount={this.state.dataCount}
                        onChangeTableState={this.onChangeTableState}
                        searched={this.state.searched}
                        currentPage={this.state.currentPage}
                        limit={this.state.limit}
                        formatDate={this.formatDate}
                    />
                </div>
            </>
        );
    }
}

export default connect(null)(History);