const initialState = {
    isAuth: false,
    email: '',
    username: '',
    accountID: 0,
    roleID: 0,
    organization: '',
    token: '',
    showLoading: 'none',
    profileImg: '',
    notification: false,
    dataLoaded: false
}

const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOWLOADING': {
            return {
                ...state,
                showLoading: 'loading'
            }
        }
        case 'HIDELOADING': {
            return {
                ...state,
                showLoading: ''
            }
        }
        case 'LOGIN': {
            return {
                ...state,
                email: action.payload.email,
                username: action.payload.username,
                accountID: action.payload.accountID,
                roleID: ((action.payload.roleID) ? action.payload.roleID : state.roleID),
                token: action.payload.token,
                isAuth: true
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                email: '',
                username: '',
                accountID: 0,
                roleID: 0,
                token: '',
                isAuth: false
            }
        }
        case 'SETPROFILEIMG': {
            return {
                ...state,
                profileImg: action.payload.data
            }
        }
        case 'SETROLEID': {
            return {
                ...state,
                roleID: action.payload.roleID
            }
        }
        case 'SETNOTIFICATION': {
            return {
                ...state,
                notification: action.payload.notification
            }
        }
        case 'SETDATALOADED': {
            return {
                ...state,
                dataLoaded: (action.payload.dataLoaded) ? action.payload.dataLoaded : false
            }
        }
        default: return state;
    }
}

export default mainReducer;