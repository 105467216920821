import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import Collapse from 'react-bootstrap/Collapse';
import DatePicker from '../../../Utilities/Datepicker';
import "react-datepicker/dist/react-datepicker.css";

class EvaluationManageScheduleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentEvaluationType: 0,
            currentYear: 0,
            KPIMains: {
                ListKPIMain: [],
                ListKPI: []
            },
            ListSelectedKPI: [],
            showCollapse: null,
            step: 0,
            periodStart: null,
            periodEnd: null,
            canUpdate: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onHideModal = () => {
        var title = 'ยกเลิกการเปิดช่วงเวลาการประเมินพิเศษ';
        var text = 'ต้องการยกเลิกการเปิดช่วงเวลาการประเมินพิเศษหรือไม่ ?'
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    this.props.hide(this.resetState, true);
                }
            });
    }

    onEnterModal = () => {
        if (this.props.currentEvaluation) {
            this.setState({
                currentEvaluationType: this.props.currentEvaluation.EvaluationTypeID
            }, () => {
                this.props.getListKPIAll(this.props.currentEvaluation.Year, this.props.currentEvaluation.EvaluationTypeID)
                    .then(result => {
                        console.log(result);
                        result = result.data.GetListKPIAll;
                        this.setState({
                            KPIMains: {
                                ListKPIMain: (result.ListKPIMain || []),
                                ListKPI: (result.ListKPI || [])
                            },
                            canUpdate: true
                        })
                    })
                    .catch(err => {
                        console.log(err);
                        Swal.fire({
                            type: 'error',
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                        });
                        this.setState({
                            canUpdate: true
                        }, () => {
                            this.props.hide(this.resetState, true);
                        });
                    })
            });
        }
        else {
            this.setState({
                canUpdate: true
            });
        }
    }

    resetState = () => {
        this.setState({
            currentEvaluationType: 0,
            currentYear: 0,
            KPIMains: {
                ListKPIMain: [],
                ListKPI: []
            },
            ListSelectedKPI: [],
            showCollapse: null,
            step: 0,
            periodStart: null,
            periodEnd: null,
            canUpdate: false
        })
    }

    onChangeOption = (name, value) => {
        this.setState({ [name]: value });
    }

    checkupdate = () => {
        var title = 'เกิดข้อผิดพลาด';
        var text = 'เกิดข้อผิดพลาด';
        var type = null;
        if (!this.state.periodStart || !this.state.periodEnd) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาระบุช่วงเวลาในการประเมิน';
            type = 'warning';
        }
        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    onSave = () => {
        if (this.checkupdate()) {
            console.log(this.state.ListSelectedKPI);
            this.props.updateSpecialSchedule(
                this.state.ListSelectedKPI,
                this.state.periodStart.getFullYear() + '-' + (this.state.periodStart.getMonth() + 1) + '-' + this.state.periodStart.getDate(),
                this.state.periodEnd.getFullYear() + '-' + (this.state.periodEnd.getMonth() + 1) + '-' + this.state.periodEnd.getDate()
            )
                .then(result => {
                    console.log(result)
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        });
                        return;
                    }
                    else {
                        Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            type: 'success'
                        })
                        this.props.hide(this.resetState, true);
                    }
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire({
                        type: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                    });
                })
        }
    }

    manageShowCollapse = (index) => {
        this.setState({ showCollapse: ((index === this.state.showCollapse) ? null : index) });
    }

    manageStep = () => {
        if (this.state.ListSelectedKPI.length === 0) {
            Swal.fire({
                type: 'warning',
                title: 'กรุณาเลือกตัวชี้วัดที่ต้องการ',
                text: 'กรุณาเลือกตัวชี้วัดที่ต้องการเปิดช่วงเวลาการประเมินพิเศษอย่างน้อย 1 ตัวชี้วัด'
            })
            return;
        }
        this.setState({ step: ((this.state.step === 0) ? 1 : 0) });
    }

    checkListKPI = (id) => {
        var listKPI = this.state.ListSelectedKPI;
        for (var i = 0; i < listKPI.length; i++) {
            if (listKPI[i].KPIID === id)
                return i;
        }
        return false;
    }

    checkListKPIMain = (KPIMainID) => {
        var ListKPI = this.state.ListSelectedKPI.map(x => x.KPIID);
        var ListKPIMain = this.state.KPIMains.ListKPI.filter(x => x.KPIMainID === KPIMainID);
        var result = ListKPIMain.every(x => ListKPI.includes(x.KPIID));
        console.log(result);
        return result;
    }

    clickCheck = (id) => {
        var index = this.checkListKPI(id);
        var listKPI = [...this.state.ListSelectedKPI];
        if (index || index === 0)
            listKPI.splice(index, 1);
        else
            listKPI.push({ KPIID: id });
        this.setState({ ListSelectedKPI: listKPI });
    }

    selectAll = () => {
        if (this.state.KPIMains.ListKPI.length === 0) return;
        var ListKPI = [...this.state.KPIMains.ListKPI];
        var newListKPI = [];
        ListKPI.forEach((obj) => {
            newListKPI.push({ KPIID: obj.KPIID });
        });
        this.setState({
            ListSelectedKPI: newListKPI
        });
    }

    selectAllinKPIMain = (KPIMainID) => {
        var checked = this.checkListKPIMain(KPIMainID);
        var ListKPI = [...this.state.ListSelectedKPI];
        var newListKPI = [];
        var ListKPIMain = [];
        this.state.KPIMains.ListKPI.forEach(x => { if (x.KPIMainID === KPIMainID) { ListKPIMain.push(x.KPIID) } });
        if (checked) {
            console.log('in')
            ListKPI.forEach((obj) => {
                if (!(ListKPIMain.includes(obj.KPIID)))
                    newListKPI.push({ KPIID: obj.KPIID });
            });
        }
        else {
            console.log('not')
            newListKPI = [...ListKPI];
            ListKPI = ListKPI.map(x => x.KPIID);
            ListKPIMain.forEach((obj) => {
                if (!(ListKPI.includes(obj)))
                    newListKPI.push({ KPIID: obj });
            });
        }
        console.log(newListKPI)
        this.setState({
            ListSelectedKPI: newListKPI
        });
    }

    selectNone = () => {
        this.setState({
            ListSelectedKPI: []
        });
    }

    checkName = (EvaluationTypeID) => {
        switch (EvaluationTypeID) {
            case 1: { return 'เป้าประสงค์' }
            case 2: { return 'เป้าประสงค์' }
            case 3: { return 'ตัวชี้วัด' }
            case 4: { return 'วัตถุประสงค์' }
            case 5: { return 'ตัวชี้วัด' }
            default: return 'ตัวชี้วัด';
        }
    }

    checkEvaluationType = (EvaluationTypeID) => {
        switch (EvaluationTypeID) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 6:
                { return this.renderDataTable3(this.state.KPIMains.ListKPIMain, this.state.KPIMains.ListKPI); }
            case 5: { return this.renderDataTable5(this.state.KPIMains.ListKPI) }
            default: return null;
        }
    }

    renderDataTable3 = (KPIMains, ListKPI) => {
        return (
            <>
                {
                    KPIMains.map((obj, index) => {
                        return (
                            <tr key={'ListKPIMain' + index}>
                                <td>{index + 1}</td>
                                <td>
                                    <div>
                                        <div className='checkbox d-inline-block'>
                                            <input
                                                type="checkbox"
                                                className="form-check d-inline-block ml-2"
                                                checked={this.checkListKPIMain(obj.KPIMainID)}
                                                readOnly
                                            />
                                            <label
                                                className="form-check-label noCross d-inline-block noselect"
                                                onClick={() => this.selectAllinKPIMain(obj.KPIMainID)}
                                                style={{ top: '-20px' }}
                                            />
                                        </div>
                                        <div
                                            className='p-2 d-inline'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => this.manageShowCollapse(index)}
                                        >

                                            {obj.KPIMainName}
                                        </div>
                                    </div>
                                    <Collapse in={this.state.showCollapse === index}>
                                        <div className='ml-4'>
                                            {
                                                ListKPI.map((object, objectIndex) => {
                                                    if (obj.KPIMainID !== object.KPIMainID) return null;
                                                    return (
                                                        <div key={'ListKPI-' + index + '-' + objectIndex}>
                                                            <hr />
                                                            <div className='checkbox'>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check d-inline-block ml-2"
                                                                    checked={((this.checkListKPI(object.KPIID) !== false) ? true : false)}
                                                                    readOnly
                                                                />
                                                                <label
                                                                    className="form-check-label noCross d-inline-block noselect"
                                                                    onClick={() => this.clickCheck(object.KPIID)}
                                                                >
                                                                    {object.KPIName}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Collapse>
                                </td>
                                <td align='center'>
                                    <button className='btn btn-outline-secondary position-relative' onClick={() => this.manageShowCollapse(index)}>
                                        <i className='fa fa-arrow-left' style={{ opacity: '0' }} />
                                        <i className={'pt-1 text-secondary fa fa-chevron-left pr-1 ' + ((this.state.showCollapse === index) ? 'hide' : '')} />
                                        <i className={'pt-1 text-secondary fa fa-chevron-down ' + ((this.state.showCollapse === index) ? '' : 'hide')} />
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }
            </>
        )
    }

    renderDataTable5 = (ListKPI) => {
        return (
            <>
                {
                    ListKPI.map((object, objectIndex) => {
                        console.log(object)
                        return (
                            <tr key={'Exec-' + objectIndex}>
                                <td>{objectIndex + 1}</td>
                                <td>
                                    <div className='checkbox'>
                                        <input
                                            type="checkbox"
                                            className="form-check d-inline-block ml-2"
                                            checked={((this.checkListKPI(object.KPIID) !== false) ? true : false)}
                                            readOnly
                                        />
                                        <label
                                            className="form-check-label noCross d-inline-block noselect"
                                            onClick={() => this.clickCheck(object.KPIID)}
                                        >
                                            {object.KPIName}
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
            </>
        )
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='lg' onEnter={this.onEnterModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            ช่วงเวลาการประเมินพิเศษ
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {
                            (this.state.step === 0) ?
                                <div className='mb-3'>
                                    <button className='btn btn-outline-primary mr-2' onClick={this.selectAll}>เลือกทั้งหมด</button>
                                    <button className='btn btn-outline-secondary' onClick={this.selectNone}>ไม่เลือกทั้งหมด</button>
                                </div> : null
                        }
                        <div className={'table-responsive-xl Evaluation-div ' + ((this.state.step === 0) ? '' : 'Evaluation-div-disable')}>
                            <table className="table mb-0 table-custom table-striped table-bordered table-hover">
                                <thead>
                                    <tr align='center'>
                                        <th style={{ minWidth: '60px' }}>ลำดับ</th>
                                        <th style={{ minWidth: '275px' }}>
                                            {this.checkName(this.state.currentEvaluationType)}
                                        </th>
                                        {/* {
                                            (this.state.currentEvaluationType !== 5) ?
                                                <th style={{ minWidth: '125px' }}>แสดง
                                                    {(this.state.currentEvaluationType === 1 || this.state.currentEvaluationType === 2) ?
                                                        'ตัวบ่งชี้' : 'ตัวชี้วัด'
                                                    }
                                                </th> : null
                                        } */}
                                        {
                                            (this.state.currentEvaluationType !== 5) ?
                                                <th style={{ minWidth: '125px' }}>
                                                    แสดงตัวชี้วัด
                                                </th> : null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.checkEvaluationType(this.state.currentEvaluationType)}
                                </tbody>
                            </table>
                        </div>

                        <div className={'Evaluation-div ' + (!(this.state.step === 0) ? '' : 'Evaluation-div-disable')}>
                            <div className='row'>
                                <div className='form-group col-12 col-lg-6'>
                                    <i className='text-danger mr-1'>*</i>
                                    <label className='form-label'>ช่วงเวลาเริ่มการประเมินพิศษ</label>
                                    <div>
                                        <DatePicker
                                            name='periodStart'
                                            placeholderText='ช่วงเวลาเริ่มการประเมิน'
                                            dateData={this.state.periodStart}
                                            setDate={this.onChangeOption}
                                            startDate={this.state.periodStart}
                                            endDate={this.state.periodEnd}
                                            maxDate={this.state.periodEnd}
                                        />
                                    </div>
                                </div>
                                <div className='form-group col-12 col-lg-6'>
                                    <i className='text-danger mr-1'>*</i>
                                    <label className='form-label'>ช่วงเวลาสิ้นสุดการประเมินพิเศษ</label>
                                    <div>
                                        <DatePicker
                                            name='periodEnd'
                                            placeholderText='ช่วงเวลาสิ้นสุดการประเมิน'
                                            dateData={this.state.periodEnd}
                                            setDate={this.onChangeOption}
                                            startDate={this.state.periodStart}
                                            endDate={this.state.periodEnd}
                                            minDate={this.state.periodStart}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                            {
                                (this.state.step === 1) ?
                                    <button
                                        className='btn btn-outline-primary mr-2'
                                        onClick={this.manageStep}
                                    >
                                        ย้อนกลับ
                                </button> : null
                            }
                            <button
                                className='btn btn-outline-primary'
                                onClick={(this.state.step === 0) ? this.manageStep : this.onSave}
                            >
                                {
                                    (this.state.step === 0) ?
                                        'ถัดไป' : 'บันทึก'
                                }
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default EvaluationManageScheduleModal;