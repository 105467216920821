import Axios from './BaseService';

class OrganizationService {

    searchOrganization(OrganizationName) {
        OrganizationName = (!OrganizationName) ? null : OrganizationName.trim();
        const SEARCHORGANIZATION = `
            query SearchOrganization($OrganizationName: String) {
                SearchOrganization(OrganizationName: $OrganizationName){
                    OrganizationID
                    OrganizationName
                    OrganizationShortName
                    OrganizationCode
                    ParentOrganizationID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: SEARCHORGANIZATION,
            variables: {
                OrganizationName: OrganizationName
            },
            errorPolicy: 'all'
        });
    }

    getOrganizationDetail = (OrganizationID) => {
        const GETORGANIZATIONDETAIL = `
            query SearchOrganization($OrganizationID: Int!) {
                SearchOrganization(OrganizationID: $OrganizationID){
                    OrganizationID
                    OrganizationName
                    OrganizationShortName
                    OrganizationCode
                    OrganizationLevel
                    ParentOrganizationID
                    ParentOrganizationName
                    Address
                    ZipCode
                    Tel
                    Fax
                    Email
                    Lat
                    Lng
                    Description
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: GETORGANIZATIONDETAIL,
            variables: {
                OrganizationID: OrganizationID
            },
            errorPolicy: 'all'
        });
    }

    createOrganization(
        OrganizationCode,
        OrganizationName,
        ParentOrganizationID,
        OrganizationShortName,
        OrganizationLevel,
        Address,
        ZipCode,
        Tel,
        Fax,
        Email,
        Lat,
        Lng,
        Description
    ) {
        console.log(
            OrganizationCode,
            OrganizationName,
            ParentOrganizationID,
            OrganizationShortName,
            OrganizationLevel,
            Address,
            ZipCode,
            Tel,
            Fax,
            Email,
            Lat,
            Lng,
            Description
        )
        OrganizationCode = (!OrganizationCode) ? null : OrganizationCode.trim();
        OrganizationName = (!OrganizationName) ? null : OrganizationName.trim();
        OrganizationShortName = (!OrganizationShortName) ? null : OrganizationShortName.trim();
        const CREATEORGANIZATION = `
            mutation CreateOrganization(
                $OrganizationCode: String,
                $OrganizationName: String!,
                $ParentOrganizationID: Int,
                $OrganizationShortName: String,
                $OrganizationLevel: Int,
                $Address: String,
                $ZipCode: String,
                $Tel: String,
                $Fax: String,
                $Email: String,
                $Lat: Float,
                $Lng: Float,
                $Description: String
            ){
                CreateOrganization(
                    OrganizationCode: $OrganizationCode,
                    OrganizationName: $OrganizationName,
                    ParentOrganizationID: $ParentOrganizationID,
                    OrganizationShortName: $OrganizationShortName,
                    OrganizationLevel: $OrganizationLevel,
                    Address: $Address,
                    ZipCode: $ZipCode,
                    Tel: $Tel,
                    Fax: $Fax,
                    Email: $Email,
                    Lat: $Lat,
                    Lng: $Lng,
                    Description: $Description
                ){
                    OrganizationID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: CREATEORGANIZATION,
            variables: {
                OrganizationCode: OrganizationCode,
                OrganizationName: OrganizationName,
                ParentOrganizationID: parseInt(ParentOrganizationID),
                OrganizationShortName: OrganizationShortName,
                OrganizationLevel: OrganizationLevel,
                Address: Address,
                ZipCode: ZipCode,
                Tel: Tel,
                Fax: Fax,
                Email: Email,
                Lat: parseFloat(Lat),
                Lng: parseFloat(Lng),
                Description: Description
            },
            errorPolicy: 'all'
        })
    }

    updateOrganization(
        OrganizationID,
        OrganizationCode,
        OrganizationName,
        ParentOrganizationID,
        OrganizationShortName,
        OrganizationLevel,
        Address,
        ZipCode,
        Tel,
        Fax,
        Email,
        Lat,
        Lng,
        Description
    ) {
        console.log(
            ParentOrganizationID)

        OrganizationCode = (!OrganizationCode) ? null : OrganizationCode.trim();
        OrganizationName = (!OrganizationName) ? null : OrganizationName.trim();
        OrganizationShortName = (!OrganizationShortName) ? null : OrganizationShortName.trim();
        const UPDATEORGANIZATION = `
            mutation UpdateOrganization(
                $OrganizationID: Int!
                $OrganizationCode: String,
                $OrganizationName: String!,
                $ParentOrganizationID: Int,
                $OrganizationShortName: String,
                $OrganizationLevel: Int,
                $Address: String,
                $ZipCode: String,
                $Tel: String,
                $Fax: String,
                $Email: String,
                $Lat: Float,
                $Lng: Float,
                $Description: String
            ){
                UpdateOrganization(
                    OrganizationID: $OrganizationID,
                    OrganizationCode: $OrganizationCode,
                    OrganizationName: $OrganizationName,
                    ParentOrganizationID: $ParentOrganizationID,
                    OrganizationShortName: $OrganizationShortName,
                    OrganizationLevel: $OrganizationLevel,
                    Address: $Address,
                    ZipCode: $ZipCode,
                    Tel: $Tel,
                    Fax: $Fax,
                    Email: $Email,
                    Lat: $Lat,
                    Lng: $Lng,
                    Description: $Description
                ){
                    OrganizationID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: UPDATEORGANIZATION,
            variables: {
                OrganizationID: OrganizationID,
                OrganizationCode: OrganizationCode,
                OrganizationName: OrganizationName,
                ParentOrganizationID: parseInt(ParentOrganizationID),
                OrganizationShortName: OrganizationShortName,
                OrganizationLevel: OrganizationLevel,
                Address: Address,
                ZipCode: ZipCode,
                Tel: Tel,
                Fax: Fax,
                Email: Email,
                Lat: parseFloat(Lat),
                Lng: parseFloat(Lng),
                Description: Description
            },
            errorPolicy: 'all'
        })
    }

    deleteOrganization(OrganizationID) {
        const DELETEORGANIZATION = `
            mutation DeleteOrganization($OrganizationID: Int!) {
                DeleteOrganization(OrganizationID: $OrganizationID) {
                    OrganizationID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: DELETEORGANIZATION,
            variables: {
                OrganizationID: parseInt(OrganizationID)
            },
            errorPolicy: 'all'
        });
    }
}

export default new OrganizationService();