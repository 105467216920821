import React from 'react';
import { Card, Pagination } from 'react-bootstrap';


class HistoryTable extends React.Component {
    componentDidMount() {
        console.log('didmount')
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.data !== nextProps.data) {
            return true;
        }
        return false;
    }

    renderData = (data) => {
        return data.map((obj, index) => {
            if (index >= this.props.limit || obj === undefined) return false;
            return (
                <tr key={index}>
                    <td>{((this.props.currentPage - 1) * this.props.limit) + (index + 1)}</td>
                    <td>{obj.Username}</td>
                    <td>{obj.IP}</td>
                    <td>{obj.DatetimeAction}</td>
                    <td>{obj.ItemName}</td>
                    <td>{obj.Module}</td>
                    <td>{obj.Action}</td>
                </tr>
            );
        })
    }

    showCurrentItem = () => {
        var number = ((this.props.currentPage - 1) * this.props.limit) + 1;
        var numberlast = (number + this.props.limit) - 1;
        if (numberlast > this.props.dataCount) numberlast = this.props.dataCount;
        if (this.props.dataCount === 0) {
            number = '0';
            numberlast = '0';
        }
        return (
            <div style={{ display: 'inline-block' }}>
                {'รายการที่แสดง : ' + number + ' - ' + numberlast + ' จาก ' + this.props.dataCount + ' รายการ'}
            </div>
        );
    }

    changeCurrentpage = (index) => {
        var pagenum = Math.floor(this.props.dataCount / this.props.limit) + 1;
        if (this.props.dataCount % this.props.limit === 0) pagenum -= 1;
        if (index === 0 || index > pagenum || index === this.props.currentPage) return
        this.props.onChangeTableState('currentPage', index);
    }

    createPagination = () => {
        var item = [];
        console.log(this.props.dataCount)
        var pagenum = Math.floor(this.props.dataCount / this.props.limit) + 1;
        console.log(pagenum)
        if (this.props.dataCount % this.props.limit === 0) pagenum -= 1;
        if (this.props.currentPage !== 1) {
            item.push(
                <React.Fragment key='PageStart'>
                    <Pagination.First onClick={() => { this.changeCurrentpage(1) }} />
                    <Pagination.Prev onClick={() => { this.changeCurrentpage(this.props.currentPage - 1) }} />
                </React.Fragment>
            );
            if (this.props.currentPage > 3) {
                item.push(
                    <Pagination.Ellipsis key={'ellipsisStart'} />
                );
            }
        }
        for (let i = 1; i <= pagenum; i++) {
            console.log(this.props.currentPage)
            if (i >= this.props.currentPage - 2)
                item.push(
                    <Pagination.Item key={i} active={i === this.props.currentPage} onClick={() => { this.changeCurrentpage(i) }}>
                        {i}
                    </Pagination.Item>
                );
            if (i === this.props.currentPage + 2) {
                item.push(<Pagination.Ellipsis key={'ellipsisEnd'} />);
                break;
            }
        }
        if (pagenum !== 1 && pagenum !== this.props.currentPage)
            item.push(
                <React.Fragment key={'PageEnd'}>
                    <Pagination.Next onClick={() => { this.changeCurrentpage(this.props.currentPage + 1) }} />
                    <Pagination.Last onClick={() => { this.changeCurrentpage(pagenum) }} />
                </React.Fragment>
            );
        return item;
    }

    changeLimit = (e) => {
        var newLimit = parseInt(e.target.value);
        var oldLimit = this.props.limit;
        var newCurrentPage = Math.floor(((this.props.currentPage - 1) * oldLimit) / newLimit) + 1;
        this.props.onChangeTableState('', newCurrentPage, newLimit);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.data !== this.props.data) {
            return true;
        }
        else return false;
    }

    renderSearched = (searched) => {
        var el = [];
        console.log(searched)
        if (searched.searchedUsername !== '') {
            el.push(
                <div className='search-pill' key='History-searched1'>
                    ชื่อผู้ใช้งาน : {searched.searchedUsername}
                </div>
            );
        }
        if (searched.searchedStartDate !== null) {
            el.push(
                <div className='search-pill' key='History-searched2'>
                    วันเริ่ม : {this.props.formatDate(searched.searchedStartDate)}
                </div>
            );
        }
        if (searched.searchedEndDate !== null) {
            el.push(
                <div className='search-pill' key='History-searched3'>
                    วันจบ : {this.props.formatDate(searched.searchedEndDate)}
                </div>
            );
        }
        if (searched.searchedModule !== '') {
            el.push(
                <div className='search-pill' key='History-searched4'>
                    Module : {searched.searchedModule}
                </div>
            );
        }
        if (searched.searchedAction !== '') {
            el.push(
                <div className='search-pill' key='History-searched5'>
                    Action : {searched.searchedAction}
                </div>
            );
        }
        if (searched.searchedIP !== '') {
            el.push(
                <div className='search-pill' key='History-searched6'>
                    IP : {searched.searchedIP}
                </div>
            );
        }

        return el;
    }

    render() {
        return (
            <>
                {
                    (this.props.searched.isSearched !== false) ?
                        <div>
                            ผลการค้นหา : {this.renderSearched(this.props.searched)}
                        </div>
                        : null
                }
                <Card className="card-custom-table">
                    <Card.Header>
                        <div className="float-right">
                            <div className="d-inline-block mr-1">แสดง </div>
                            <select className="form-control d-inline-block" onChange={this.changeLimit} style={{ width: '75px' }}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                            <div className="d-inline-block ml-1">รายการ</div>
                        </div>
                    </Card.Header>
                    <Card.Body className='table-responsive-xl mb-2'>
                        <table className="table mb-0 table-custom table-striped table-bordered table-hover">
                            <thead>
                                <tr align='center'>
                                    <th style={{ minWidth: '60px' }}>ลำดับ</th>
                                    <th style={{ minWidth: '150px' }}>ชื่อผู้ใช้งาน</th>
                                    <th style={{ minWidth: '100px' }}>IP</th>
                                    <th style={{ minWidth: '120px' }}>วันที่</th>
                                    <th style={{ minWidth: '170px' }}>การกระทำ</th>
                                    <th style={{ minWidth: '120px' }}>Module</th>
                                    <th style={{ minWidth: '120px' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderData(this.props.data)}
                            </tbody>
                        </table>
                    </Card.Body>
                    <Card.Footer>
                        {this.showCurrentItem()}
                        <Pagination className="mb-0 float-right">
                            {this.createPagination()}
                        </Pagination>
                    </Card.Footer>
                </Card>
            </>
        );
    }
}

export default HistoryTable;