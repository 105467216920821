import Axios from './BaseService';

class AccountService {

    searchAccount(AccountID, RoleID, OrganizationID, Username, FirstName, LastName, Email) {
        AccountID = (AccountID === 0 || AccountID === undefined) ? null : AccountID;
        RoleID = (RoleID === 0 || RoleID === undefined) ? null : RoleID;
        OrganizationID = (OrganizationID === 0 || OrganizationID === undefined) ? null : OrganizationID;
        Username = (!Username) ? null : Username.trim();
        FirstName = (!FirstName) ? null : FirstName.trim();
        LastName = (!LastName) ? null : LastName.trim();
        Email = (!Email) ? null : Email.trim();
        const SEARCHACCOUNT = `
        query SearchAccount(
                $AccountID: Int,
                $RoleID: Int, 
                $OrganizationID: Int, 
                $Username: String, 
                $FirstName: String, 
                $LastName: String, 
                $Email: String
            ){
            SearchAccount(
                AccountID: $AccountID
                RoleID: $RoleID
                OrganizationID: $OrganizationID
                Username: $Username
                FirstName: $FirstName
                LastName: $LastName
                Email: $Email
            ){
                AccountID
                RoleID
                RoleName
                Username
                FirstName
                LastName
                Email
                OrganizationID
                OrganizationShortName
                OrganizationName
            }
        }
        `;

        return Axios.post('/pmqaservice', {
            query: SEARCHACCOUNT,
            variables: {
                AccountID: parseInt(AccountID),
                RoleID: parseInt(RoleID),
                OrganizationID: parseInt(OrganizationID),
                Username: Username,
                FirstName: FirstName,
                LastName: LastName,
                Email: Email
            },
            errorPolicy: 'all'
        });
    }

    getAccountDetail(AccountID) {
        const GETACCOUNTDETAIL = `
        query GetAccountDetail($AccountID: Int!){
            GetAccountDetail(
                AccountID: $AccountID
            ){
                AccountID
                RoleID
                RoleName
                Username
                FirstName
                LastName
                Email
                OrganizationID
                OrganizationShortName
                OrganizationName
            }
        }
        `;

        return Axios.post('/pmqaservice', {
            query: GETACCOUNTDETAIL,
            variables: {
                AccountID: parseInt(AccountID)
            },
            errorPolicy: 'all'
        });
    }

    createAccount(RoleID, OrganizationID, Email, Password, Username, FirstName, LastName) {
        Password = (!Password) ? null : Password.trim();
        Username = (!Username) ? null : Username.trim();
        FirstName = (!FirstName) ? null : FirstName.trim();
        LastName = (!LastName) ? null : LastName.trim();
        Email = (!Email) ? null : Email.trim();

        const CREATEACCOUNT = `
            mutation CreateAccount(
                $RoleID: Int!, 
                $OrganizationID: Int!, 
                $Email: String!,
                $Password: String!,
                $Username: String, 
                $FirstName: String!, 
                $LastName: String!
            ){
                CreateAccount(
                    RoleID: $RoleID, 
                    OrganizationID: $OrganizationID, 
                    Email: $Email,
                    Password: $Password,
                    Username: $Username, 
                    FirstName: $FirstName, 
                    LastName: $LastName
                ) {
                    AccountID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: CREATEACCOUNT,
            variables: {
                RoleID: parseInt(RoleID),
                OrganizationID: parseInt(OrganizationID),
                Email: Email,
                Password: Password,
                Username: Username,
                FirstName: FirstName,
                LastName: LastName
            }
        });
    }

    updateAccount(AccountID, RoleID, OrganizationID, Email, NewPassword, OldPassword, Username, FirstName, LastName) {
        OldPassword = (!OldPassword) ? null : OldPassword.trim();
        NewPassword = (!NewPassword) ? null : NewPassword.trim();
        Username = (!Username) ? null : Username.trim();
        FirstName = (!FirstName) ? null : FirstName.trim();
        LastName = (!LastName) ? null : LastName.trim();
        Email = (!Email) ? null : Email.trim();

        const UPDATEACCOUNT = `
            mutation UpdateAccount(
                $AccountID: Int!, 
                $RoleID: Int, 
                $OrganizationID: Int, 
                $Email: String, 
                $NewPassword: String,
                $OldPassword: String, 
                $Username: String, 
                $FirstName: String, 
                $LastName: String,
            ){
                UpdateAccount(
                    AccountID: $AccountID, 
                    RoleID: $RoleID, 
                    OrganizationID: $OrganizationID, 
                    Email: $Email, 
                    NewPassword: $NewPassword,
                    OldPassword: $OldPassword, 
                    Username: $Username, 
                    FirstName: $FirstName, 
                    LastName: $LastName
                ){
                    AccountID
                }
            }  
        `;

        return Axios.post('/pmqaservice', {
            query: UPDATEACCOUNT,
            variables: {
                AccountID: parseInt(AccountID),
                RoleID: parseInt(RoleID),
                OrganizationID: parseInt(OrganizationID),
                Email: Email,
                NewPassword: NewPassword,
                OldPassword: OldPassword,
                Username: Username,
                FirstName: FirstName,
                LastName: LastName
            },
            errorPolicy: 'all'
        });
    }

    deleteAccount(AccountID) {
        const DELETEACCOUNT = `
            mutation DeleteAccount($AccountID: Int!) {
                DeleteAccount(AccountID: $AccountID) {
                    AccountID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: DELETEACCOUNT,
            variables: {
                AccountID: parseInt(AccountID)
            },
            errorPolicy: 'all'
        });
    }

    getProfileAccount(AccountID) {
        const GETPROFILEACCOUNT = `
            query GetProfileAccount($AccountID: Int!){
                GetProfileAccount(AccountID: $AccountID) {
                    FileData
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: GETPROFILEACCOUNT,
            variables: {
                AccountID: parseInt(AccountID)
            },
            errorPolicy: 'all'
        })
    }

    updateProfileAccount(AccountID, FileData) {
        const UPDATEPROFILEACCOUNT = `
            mutation UpdateProfileAccount($AccountID: Int!, $FileData: String!) {
                UpdateProfileAccount(AccountID: $AccountID, FileData: $FileData) {
                    AccountID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: UPDATEPROFILEACCOUNT,
            variables: {
                AccountID: parseInt(AccountID),
                FileData: FileData
            },
            errorPolicy: 'all'
        })
    }

    getRoleID(AccountID) {
        const GETROLEID = `
            query GetAccountDetail($AccountID: Int!) {
                GetAccountDetail(AccountID: $AccountID) {
                    RoleID
                } 
            }
        `;

        return Axios.post('/pmqaservice', {
            query: GETROLEID,
            variables: {
                AccountID: parseInt(AccountID)
            },
            errorPolicy: 'all'
        });
    }

    getNotification(AccountID) {
        const GETNOTIFICATION = `
            query Getnotification($AccountID : Int!){
                Getnotification(AccountID: $AccountID){
                    Count
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: GETNOTIFICATION,
            variables: {
                AccountID: parseInt(AccountID)
            },
            errorPolicy: 'all'
        });
    }

    forgetPassword(Email) {
        if (!Email) return Promise.reject();
        Email = Email.trim();

        return Axios.post('/forgetpassword', {
            Email: Email
        });
    }

    resetPassword(token, NewPassword) {
        if (!token || !NewPassword) return Promise.reject();
        NewPassword = NewPassword.trim();

        return Axios.post('/resetpassword', {
            resetToken: token,
            newPassword: NewPassword
        });
    }

    getVersion() {
        const GETVERSION = 'query GetVersion{ GetVersion {VersionName, VersionDescription}}'

        return Axios.post('/pmqaservice', {
            query: GETVERSION,
            errorPolicy: 'all'
        });
    }
}

export default new AccountService();