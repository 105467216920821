import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { getDaysOfWeek } from '../../../Utilities/Date';

class ScheduleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ScheduleName: '',
            Mode: 0,
            clickDayStart: false,
            DayOfWeekStart: 0,
            DayOfWeekEnd: 0,
            WeekOfMonthStart: 0,
            WeekOfMonthEnd: 0,
            DayOfMonthStart: 0,
            DayOfMonthEnd: 0,
            canUpdate: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onHideModal = () => {
        var title = (this.props.currentScheduleID) ? 'ยกเลิกการแก้ไขช่วงเวลาการประเมิน' : 'ยกเลิกการเพิ่มช่วงเวลาการประเมิน'
        var text = 'ต้องการ' + title + 'หรือไม่ ?'
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    this.props.hide(this.resetState);
                }
            })

    }

    onEnterModal = () => {
        if (this.props.currentScheduleID) {
            var result = this.props.getScheduleDetail(this.props.currentScheduleID);
            console.log(result);
            this.setState({
                ScheduleName: result.ScheduleName,
                Mode: result.Mode,
                DayOfWeekStart: result.DayOfWeekStart,
                DayOfWeekEnd: result.DayOfWeekEnd,
                WeekOfMonthStart: result.WeekOfMonthStart,
                WeekOfMonthEnd: result.WeekOfMonthEnd,
                DayOfMonthStart: result.DayOfMonthStart,
                DayOfMonthEnd: result.DayOfMonthEnd,
                canUpdate: true
            })
        }
        else
            this.setState({ canUpdate: true });
    }

    resetState = () => {
        this.setState({
            ScheduleName: '',
            Mode: 0,
            DayOfWeekStart: 0,
            DayOfWeekEnd: 0,
            WeekOfMonthStart: 0,
            WeekOfMonthEnd: 0,
            DayOfMonthStart: 0,
            DayOfMonthEnd: 0,
            clickDayStart: false,
            canUpdate: false
        })
    }

    checkUpdate = () => {
        console.log((this.state.Mode === 2 || this.state.Mode === 3) && (this.state.DayOfWeekStart === null || this.state.DayOfWeekEnd === null))
        var title = '';
        var text = '';
        var type = null;
        if (this.state.ScheduleName === '' || this.state.ScheduleName.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกชื่อโปรไฟล์';
            type = 'warning';
        }
        else if (this.state.Mode === 0) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาระบุประเภทการใช้งาน';
            type = 'warning';
        }
        else if ((this.state.Mode === 2) && (this.state.DayOfWeekStart <= 0 || this.state.DayOfWeekEnd <= 0)) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาระบุวันเริ่มและวันสิ้นสุดของสัปดาห์';
            type = 'warning';
        }
        else if ((this.state.Mode === 2) && (this.state.DayOfWeekStart === null || this.state.DayOfWeekEnd === null)) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาระบุวันเริ่มและวันสิ้นสุดของสัปดาห์';
            type = 'warning';
        }
        else if (this.state.Mode === 3 && (this.state.DayOfMonthStart === 0 || this.state.DayOfMonthEnd === 0)) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาระบุวันเริ่มและวันสิ้นสุดของเดือน';
            type = 'warning';
        }
        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    onSave = () => {
        if (!this.checkUpdate()) {
            return;
        }
        var ScheduleService;
        if (this.props.currentScheduleID) {
            ScheduleService = this.props.updateSchedule(
                this.props.currentScheduleID,
                this.state.ScheduleName,
                this.state.Mode,
                this.state.DayOfWeekStart,
                this.state.DayOfWeekEnd,
                this.state.DayOfMonthStart,
                this.state.DayOfMonthEnd
            );
        }
        else {
            ScheduleService = this.props.createSchedule(
                this.state.ScheduleName,
                this.state.Mode,
                this.state.DayOfWeekStart,
                this.state.DayOfWeekEnd,
                this.state.DayOfMonthStart,
                this.state.DayOfMonthEnd
            );
        }

        ScheduleService
            .then(result => {
                console.log(result);
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: result.errors[0].Message,
                        type: 'error'
                    })
                }
                else {
                    Swal.fire({
                        title: 'บันทึกข้อมูลสำเร็จ',
                        type: 'success'
                    })
                    if (!this.props.currentScheduleID)
                        this.props.hide(this.resetState);
                }
            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            })
    }

    onChange = (name, value) => {
        if (name === 'Mode') {
            this.setState({
                Mode: value,
                DayOfWeekStart: 0,
                DayOfWeekEnd: 0,
                WeekOfMonthStart: 0,
                WeekOfMonthEnd: 0,
                DayOfMonthStart: 0,
                DayOfMonthEnd: 0,
                clickDayStart: false
            })
            return;
        }
        if (name === 'WeekOfMonthEnd') {
            if (value < this.state.WeekOfMonthStart) {
                Swal.fire({
                    title: 'กรุณาเลือกสัปดาห์สิ้นสุดของเดือนให้ถูกต้อง',
                    text: 'กรุณาเลือกสัปดาห์สิ้นสุดของเดือนให้ถูกต้อง',
                    type: 'warning'
                })
                return;
            }
        }
        if (name === 'WeekOfMonthStart') {
            if (value > this.state.WeekOfMonthEnd) {
                this.setState({
                    [name]: value,
                    WeekOfMonthEnd: value
                });
            }
        }
        this.setState({ [name]: value });
    }

    checkDaysOfWeek = (index) => {
        if (this.state.DayOfWeekStart === 0 && this.state.DayOfWeekEnd === 0 && this.state.clickDayStart === false) {
            return 'secondary';
        }
        else if (index >= this.state.DayOfWeekStart && index <= this.state.DayOfWeekEnd) {
            return 'primary';
        }
        else if (index === this.state.DayOfWeekStart && this.state.DayOfWeekEnd === 0) {
            return 'primary';
        }
        return 'secondary';
    }

    onClickDaysOfWeek = (index) => {
        if (this.state.clickDayStart === false) {
            this.setState({
                DayOfWeekStart: index,
                DayOfWeekEnd: 0,
                clickDayStart: true
            });
        }
        else {
            if (index === this.state.DayOfWeekStart) {
                this.setState({
                    DayOfWeekStart: 0,
                    DayOfWeekEnd: 0,
                    clickDayStart: false
                });
                return;
            }
            if (index <= this.state.DayOfWeekStart) {
                this.setState({
                    DayOfWeekStart: index,
                    DayOfWeekEnd: 0,
                    clickDayStart: true
                });
                return;
            }
            this.setState({
                DayOfWeekEnd: index,
                clickDayStart: false
            });
        }
    }

    onClickDaysOfMonth = (index) => {
        if (this.state.clickDayStart === false) {
            this.setState({
                DayOfMonthStart: index,
                DayOfMonthEnd: 0,
                clickDayStart: true
            });
        }
        else {
            if (index === this.state.DayOfMonthStart) {
                this.setState({
                    DayOfMonthStart: 0,
                    DayOfMonthEnd: 0,
                    clickDayStart: false
                });
                return;
            }
            if (index <= this.state.DayOfMonthStart) {
                this.setState({
                    DayOfMonthStart: index,
                    DayOfMonthEnd: 0,
                    clickDayStart: true
                });
                return;
            }
            this.setState({
                DayOfMonthEnd: index,
                clickDayStart: false
            });
        }
    }

    checkDayOfMonth = (index) => {
        if (this.state.DayOfMonthStart === 0 && this.state.DayOfMonthEnd === 0 && this.state.clickDayStart === false) {
            return ' border';
        }
        else if (index >= this.state.DayOfMonthStart && index <= this.state.DayOfMonthEnd) {
            return 'primary';
        }
        else if (index === this.state.DayOfMonthStart && this.state.DayOfMonthEnd === 0) {
            return 'primary';
        }
        return ' border';
    }

    renderDayOfMonth = (x) => {
        var arr = Array.from({ length: 6 }, (val, i) => x + i);
        return (
            <div className='row mb-2 mt-1' key={'DayOfMonth-row-' + x}>
                {
                    arr.map((obj, index) => {
                        if (obj > 31) return null;
                        return (
                            <div className='col-2' align='center' key={'DayOfMonth-' + obj}>
                                <span
                                    className={'noselect date-select rounded-circle d-inline-block mr-3 badge badge-' + (this.checkDayOfMonth(obj))}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.onClickDaysOfMonth(obj)}
                                >
                                    <h5 style={{ marginBottom: '0' }}>{obj}</h5>
                                </span>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderMonth = () => {
        return [
            this.renderDayOfMonth(1),
            this.renderDayOfMonth(7),
            this.renderDayOfMonth(13),
            this.renderDayOfMonth(19),
            this.renderDayOfMonth(25),
            this.renderDayOfMonth(31),
        ];
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='lg' onEnter={this.onEnterModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {(this.props.currentScheduleID === null) ? 'เพิ่มช่วงเวลา' : 'แก้ไขช่วงเวลา'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i>
                            <label className='form-label'>ชื่อโปรไฟล์</label>
                            <input
                                name='ScheduleName'
                                className='form-control'
                                placeholder='ชื่อโปรไฟล์'
                                value={(this.state.ScheduleName || '')}
                                onChange={e => this.onChange(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i>
                            <label className='form-label'>ประเภทการใช้งาน</label>
                            <select
                                name='Mode'
                                className='form-control'
                                value={(this.state.Mode || 0)}
                                onChange={e => this.onChange(e.target.name, (parseInt(e.target.value) || 0))}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                {/* <option value={1}>รายวัน</option>
                                <option value={2}>รายสัปดาห์</option> */}
                                <option value={3}>รายเดือน</option>
                            </select>
                        </div>

                        {
                            (this.state.Mode === 2) ?
                                <div className='row'>
                                    <div className='form-group col-12 col-lg-12'>
                                        <label className='form-label'>วันเริ่มของสัปดาห์และวันสิ้นสุดของสัปดาห์</label>
                                        <div className='pl-4'>
                                            {getDaysOfWeek().map((obj, index) => {
                                                return (
                                                    <span
                                                        key={'ScheduleModal-DaysOfWeek' + index}
                                                        className={'noselect p-2 d-inline-block mr-3 badge badge-' + this.checkDaysOfWeek(index + 1)}
                                                        onClick={() => this.onClickDaysOfWeek(index + 1)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <h5 style={{ marginBottom: '0' }}>{obj}</h5>
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                        {
                            (this.state.Mode === 3) ?
                                <>
                                    <div>
                                        <label>วันเริ่มต้นและวันสิ้นสุดของเดือน</label>
                                        {this.renderMonth()}
                                    </div>
                                </>
                                : null
                        }

                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                            <button
                                className='btn btn-outline-primary'
                                onClick={this.onSave}
                            >
                                บันทึก
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default ScheduleModal;
