import React from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import { checkMonth } from '../../../Utilities/Date';

class MyWindowPortal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false,
            dataLoaded: [false, false, false, false, false, false, false],
            printed: false,
            data: {
                KPIName: '',
                KPIID: null,
                Description: '',
                Year: 0,
                Month: '',
                Status: 2,
                KPIMain: {},
                EvaluationCriteria: {},
                EvaluationType: {},
                Form: {
                    Sections: []
                },
                Policy: {},
                Schedule: {
                    Period: []
                },
                KPIPersons: [],
                KPIOrganizations: [],
                KPIFiles: [],
                FormObjectResult: {
                    KPIID: null,
                    FormObjectItemResults: []
                },
                ModifiedDateTime: '',
                ModifiedUser: '',
                ProjectKPI: [],
            }
        };
        this.containerEl = null;
        this.externalWindow = null;
    }

    componentDidMount() {
        console.log(window.location);
        this.externalWindow = window.open('');

        this.externalWindow.addEventListener('beforeunload', () => {
            this.props.closeWindowPortal();
        });

        this.containerEl = this.externalWindow.document.createElement("div")
        this.externalWindow.document.body.appendChild(this.containerEl);

        var head = this.externalWindow.document.getElementsByTagName('head')[0];

        this.appendCSS(head, window.location.origin + '/assets/css/froala_editor.pkgd.min.css', 0);
        this.appendCSS(head, window.location.origin + "/assets/css/froala_editor.min.css", 1);
        this.appendCSS(head, "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css", 2);
        this.appendCSS(head, 'https://fonts.googleapis.com/css?family=Pridi:300&display=swap&subset=thai', 3);
        this.appendCSS(head, 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css', 4);
        this.appendCSS(head, window.location.origin + "/assets/css/pdf.css", 5);

        this.setState({ isOpened: true }, () => {
            console.log('eieiza');
            this.props.getEvaluationResultDetail(this.props.currentKPIID, this.props.Month, this.props.Mode)
                .then(result => {
                    result = result.data.GetEvaluationResultDetail;
                    this.externalWindow.document.title = result.KPIName +
                        ' ปีงบประมาน พ.ศ. ' + (result.Year + 543) + ' เดือน ' + checkMonth(result.Month.split('-')[1]);
                    console.log(result);
                    this.setState({
                        data: result
                    }, () => {
                        var newState = this.state.dataLoaded;
                        newState[newState.length - 1] = true;
                        //newState = [true, true, true, true, true, true, true];
                        console.log(newState);
                        this.setState({ dataLoaded: newState });
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        type: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                    });
                    this.props.closeWindowPortal();
                    this.externalWindow.close();
                })
        });
    }

    componentWillUnmount() {
        this.props.closeWindowPortal();
        this.externalWindow.close();
    }

    componentDidUpdate() {
        if (!this.state.printed && this.state.dataLoaded.every(x => x === true)) {
            this.setState({ printed: true }, () => {
                var textareas = this.externalWindow.document.getElementsByTagName('textarea');
                if (textareas.length === 0) {
                    this.externalWindow.print();
                    return;
                }
                for (var i = 0; i < textareas.length; i++) {
                    var obj = textareas[i];
                    obj.style.height = 'auto';
                    obj.style.height = obj.scrollHeight + 'px';
                }
                setTimeout(() => { this.externalWindow.print(); }, 500);
            })
        }
    }

    appendCSS = (head, link, index) => {
        var cssnode = this.externalWindow.document.createElement('link');

        cssnode.type = 'text/css';
        cssnode.rel = 'stylesheet';
        cssnode.href = link;
        console.log(cssnode);

        head.appendChild(cssnode);

        // cssnode.onload = () => {
        //     console.log(index);
        //     var newState = this.state.dataLoaded;
        //     newState[index] = true;
        //     this.setState({ dataLoaded: newState });
        // }

        cssnode.onload = this.onloadCSS(index);
    }

    onloadCSS = (index) => {
        console.log(index)
        var newState = this.state.dataLoaded;
        newState[index] = true;
        this.setState({ dataLoaded: newState });
    }

    checkBrowser = () => {
        console.log(navigator.userAgent);
        if ((!(!!document.documentMode) && !!window.StyleMedia) || (!!document.documentMode))
            return false;
        return true;
    }

    renderPolicyName = (EvaluationTypeID) => {
        switch (EvaluationTypeID) {
            case 1:
            case 2: {
                return (
                    <div className='content'>
                        <div className='section-title'>{this.state.data.Strategy.StrategyName}</div>
                    </div>
                )
            }
            case 3: {
                return (
                    <div className='content'>
                        <div className='section-title'>{this.state.data.Policy.PolicyName}</div>
                        {this.state.data.Policy.Remark}
                    </div>
                );
            }
            default: return null;
        }
    }

    renderDescription = () => {
        return (
            <>
                {this.renderPolicyName(this.state.data.EvaluationType.EvaluationTypeID)}
                <div className='content'>
                    <div className='section-title'>{this.state.data.KPIMain.KPIMainName}</div>
                    {this.state.data.KPIMain.Remark}
                </div>

                <div className='content'>
                    <div className='section-title'>{this.state.data.KPIName}</div>
                </div>

                <div className='row content p-0'>
                    <div className='col-6 border-right p-0'>
                        <div className='pr-3 pl-3'>
                            <div className='section-title'>ผู้กำกับดูแลตัวชี้วัด :</div>
                            {this.renderKPIPerson(1, this.state.data.KPIPersons, this.state.data.KPIOrganizations)}
                        </div>
                    </div>
                    <div className='col-6 p-0'>
                        <div className='pl-3 pl-3'>
                            <div className='section-title'>ผู้จัดเก็บข้อมูล :</div>
                            {this.renderKPIPerson(2, this.state.data.KPIPersons, this.state.data.KPIOrganizations)}
                        </div>
                    </div>
                </div>

                <div className='content'>
                    <div className='section-title'>คำอธิบาย : </div>
                    {this.state.data.Description}
                </div>

            </>
        );
    }

    renderKPIPerson = (type, KPIPersons, KPIOrg) => {
        var el = [];
        var count = 0;
        if (!KPIPersons || !KPIOrg) return null;
        KPIPersons.map((obj, index) => {
            if (obj.PersonStatus === type) {
                count++;
                el.push(
                    <div key={'EvaluationManage-KPIPersons-type' + type + '/' + index}>
                        {count + '. ' + obj.PersonName}
                    </div>
                )
            }
            return null;
        });
        KPIOrg.map((obj, index) => {
            if (obj.OrganizationStatus === type) {
                count++;
                el.push(
                    <div key={'EvaluationManage-KPIOrgs-type' + type + '/' + index}>
                        {count + '. ' + obj.OrganizationName}
                    </div>
                )
            }
            return null;
        });
        return el;
    }

    renderFormObjectContent = (object) => {
        if (!object) return null;
        switch (object.FormTypeID) {
            case 1: {
                return (
                    <>
                        <div
                            className='pl-2 fr-view'
                            dangerouslySetInnerHTML={{ __html: ((object.Content === null) ? '-' : object.Content) }}
                        >
                        </div>
                    </>
                )
            }
            case 2: {
                return (
                    <>
                        <table className='table mb-0 table-custom table-striped table-bordered table-hover'>
                            <thead align='center'>
                                <tr>
                                    <th>ตัวชี้วัด</th>
                                    <th>องค์ประกอบ</th>
                                    <th>ค่าน้ำหนัก</th>
                                    <th>เป้าหมาย</th>
                                    <th><i className='text-danger mr-1'>*</i>ผลการดำเนินงาน</th>
                                    {
                                        (this.state.data.EvaluationCriteria.Type === 1) ?
                                            null :
                                            <th>หน่วย</th>
                                    }
                                    <th>คะแนน</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.state.data.KPIName}</td>
                                    <td>{this.state.data.KPIMain.KPIMainName}</td>
                                    <td>{this.state.data.EvaluationCriteria.Weight}</td>
                                    <td>
                                        {
                                            (this.state.data.EvaluationCriteria.Type === 1) ?
                                                this.state.data.EvaluationCriteria.Low.Description :
                                                this.state.data.EvaluationCriteria.Low.Target
                                        }
                                    </td>
                                    <td>
                                        {
                                            (this.state.data.EvaluationCriteria.Type === 1) ?
                                                (object.FormObjectItems[0].ResultText || '') : (object.FormObjectItems[0].ResultValue || 0)
                                        }
                                    </td>
                                    {
                                        (this.state.data.EvaluationCriteria.Type === 1) ?
                                            null :
                                            <td>
                                                {(this.state.data.EvaluationCriteria.Unit || '')}
                                            </td>
                                    }
                                    <td>
                                        {
                                            (this.state.data.Status === 1 && this.state.data.EvaluationCriteria) ?
                                                (object.FormObjectItems[0].ResultValue || 0)
                                                : null
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                )
            }
            case 3: {
                return (
                    <>
                        {
                            (this.state.data.EvaluationCriteria) ?
                                <table className='table mb-0 table-custom table-bordered table-hover'>
                                    <thead align='center'>
                                        <tr>
                                            <th rowSpan='2'>ตัวชี้วัด</th>
                                            <th rowSpan='2'>องค์ประกอบ</th>
                                            <th rowSpan='2'>ค่าน้ำหนัก</th>
                                            <th colSpan='3'>เป้าหมาย</th>
                                            <th rowSpan='2'>ผลการดำเนินงาน</th>
                                            {
                                                (this.state.data.EvaluationCriteria.Type === 1) ?
                                                    null :
                                                    <th rowSpan='2'>หน่วย</th>
                                            }
                                            <th rowSpan='2'>คะแนน</th>
                                        </tr>
                                        <tr>
                                            <th className='align-middle' style={{ minWidth: '100px' }}>
                                                ขั้นต้น
                                                {(this.state.data.EvaluationCriteria.Low.Result) ? ('(' + this.state.data.EvaluationCriteria.Low.Result + ')') : ''}
                                            </th>
                                            <th className='align-middle' style={{ minWidth: '100px' }}>
                                                มาตรฐาน
                                                {(this.state.data.EvaluationCriteria.Mid.Result) ? ('(' + this.state.data.EvaluationCriteria.Mid.Result + ')') : ''}
                                            </th>
                                            <th className='align-middle' style={{ minWidth: '100px' }}>
                                                ขั้นสูง
                                                {(this.state.data.EvaluationCriteria.High.Result) ? ('(' + this.state.data.EvaluationCriteria.High.Result + ')') : ''}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.data.KPIName}</td>
                                            <td>{this.state.data.KPIMain.KPIMainName}</td>
                                            <td>{this.state.data.EvaluationCriteria.Weight}</td>
                                            <td>
                                                {
                                                    (this.state.data.EvaluationCriteria.Type === 1) ?
                                                        this.state.data.EvaluationCriteria.Low.Description :
                                                        this.state.data.EvaluationCriteria.Low.Target
                                                }
                                            </td>
                                            <td>
                                                {
                                                    (this.state.data.EvaluationCriteria.Type === 1) ?
                                                        this.state.data.EvaluationCriteria.Mid.Description :
                                                        this.state.data.EvaluationCriteria.Mid.Target
                                                }
                                            </td>
                                            <td>
                                                {
                                                    (this.state.data.EvaluationCriteria.Type === 1) ?
                                                        this.state.data.EvaluationCriteria.High.Description :
                                                        this.state.data.EvaluationCriteria.High.Target
                                                }
                                            </td>
                                            <td>
                                                {
                                                    (this.state.data.EvaluationCriteria.Type === 1) ?
                                                        (object.FormObjectItems[0].ResultText || '') : (object.FormObjectItems[0].ResultValue || 0)
                                                }

                                            </td>
                                            {
                                                (this.state.data.EvaluationCriteria.Type === 1) ?
                                                    null :
                                                    <td>
                                                        {(this.state.data.EvaluationCriteria.Unit || '')}
                                                    </td>
                                            }
                                            <td>
                                                {
                                                    (this.state.data.Status === 1 && this.state.data.EvaluationCriteria) ?
                                                        (object.FormObjectItems[0].ResultValue || 0)
                                                        : null
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                : null
                        }
                    </>
                );
            }
            case 4: {
                return (
                    <div
                        className='pl-2'
                        dangerouslySetInnerHTML={
                            { __html: ((object.FormObjectItems[0].ResultText === null) ? '-' : object.FormObjectItems[0].ResultText) }
                        }
                    >
                    </div>
                );
            }
            case 5: {
                return (
                    <>
                        <table className='table mb-0 table-custom table-striped table-bordered table-hover'>
                            <thead align='center'>
                                <tr>
                                    <th style={{ width: '50%' }}>โครงการ</th>
                                    <th style={{ width: '25%' }}>ผลการดำเนินงาน</th>
                                    <th style={{ width: '25%' }}>ปัญหาอุปสรรค</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    object.FormObjectItems.map((obj, index) => {
                                        return (
                                            <tr key={'pdf-type5-' + index}>
                                                <td>{obj.FormObjectItemName}</td>
                                                <td>
                                                    <div>{(obj.ResultText || '')}</div>
                                                </td>
                                                <td>
                                                    <div>{(obj.Remark || '')}</div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                );
            }
            case 6: {
                return (<>
                    <div>
                        {object.Content}
                    </div>
                    <table className='table mb-0 table-custom table-bordered table-hover'>
                        <thead align='center'>
                            <tr>
                                <th style={{ width: '70%' }}>ประเด็นการประเมิน</th>
                                <th style={{ width: '10%' }}>คะแนน</th>
                                <th style={{ width: '10%' }}>มี</th>
                                <th style={{ width: '10%' }}>ไม่มี</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                object.FormObjectItems.map((obj, index) => {
                                    return (
                                        <React.Fragment key={'pdf-FormObject' + index}>
                                            <tr>
                                                <td>{(index + 1) + '. ' + (obj.FormObjectItemName || '')}</td>
                                                <td>{obj.TargetValue}</td>
                                                <td align='center'>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        {(obj.ResultValue === 0) ? <i className="fa fa-check" aria-hidden="true"></i> : null}
                                                    </div>
                                                </td>
                                                <td align='center'>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        {(obj.ResultValue === 1) ? <i className="fa fa-check" aria-hidden="true"></i> : null}
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                (obj.ResultValue === 1) ?
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <div className='ml-3'>
                                                                <label className='form-label'>หมายเหตุ :</label>
                                                                {
                                                                    (this.checkBrowser()) ?
                                                                        <textarea
                                                                            className='form-control'
                                                                            value={(obj.Remark || '')}
                                                                            disabled
                                                                            style={{
                                                                                resize: 'none',
                                                                                border: 'none',
                                                                                overflow: 'hidden',
                                                                                backgroundColor: 'white'
                                                                            }}
                                                                        />
                                                                        :
                                                                        <div>
                                                                            {(obj.Remark || '')}
                                                                        </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    : null
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </>);
            }
            case 7: {
                return (<>
                    <div>
                        {object.Content}
                    </div>
                    <table className='table mb-0 table-custom table-striped table-bordered table-hover'>
                        <thead align='center'>
                            <tr>
                                <th style={{ width: '60%' }}>โครงการ</th>
                                <th style={{ width: '10%' }}>ทั้งหมด</th>
                                <th style={{ width: '10%' }}>ผลการดำเนินการ</th>
                                <th style={{ width: '10%' }}>ส่วนต่าง</th>
                                <th style={{ width: '10%' }}>ร้อยละ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                object.FormObjectItems.map((obj, index) => {
                                    return (
                                        <tr key={'pdf-type7-' + index}>
                                            <td>{obj.FormObjectItemName}</td>
                                            <td>{obj.TargetValue}</td>
                                            <td>
                                                <div>
                                                    {(obj.ResultValue || 0)}
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    (obj.ResultValue) ?
                                                        (obj.TargetValue - obj.ResultValue) : ''
                                                }
                                            </td>
                                            <td>
                                                {
                                                    (obj.ResultValue) ?
                                                        (this.checkInfinity(parseFloat(((obj.TargetValue - obj.ResultValue) / obj.TargetValue) * 100).toFixed(3))) : ''
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </>);
            }
            case 8: {
                return (
                    <div>
                        {this.renderFiles(this.state.data.KPIFiles)}
                    </div>
                )
            }
            default: return null;
        }
    }

    checkInfinity = (value) => {
        return (isFinite(value)) ? value : null
    }

    renderFiles = (files) => {
        if (!files || files.length === 0) return (<div>-</div>);
        var count = 0;
        var el = [];
        files.forEach(obj => {
            count++;
            el.push(<div className='mb-2' key={'files-' + count}>{count + '. ' + obj.FileName}</div>)
        });
        return el;
    }

    renderFooter = () => {
        console.log(this.state.data);
        return (
            <div className='mt-4 ml-4'>
                {
                    (this.state.data.Modified) ?
                        <>
                            <div>{(this.state.data.Modified.ModifiedDateTime) ? 'รายงานล่าสุด ณ วันที่ ' + this.state.data.Modified.ModifiedDateTime : ''}</div>
                            <div className='d-inline-block'>{(this.state.data.Modified.Username) ? 'รายงานโดย ผู้ใช้งาน ' + this.state.data.Modified.Username : ''}</div>
                            <div className='ml-3 d-inline-block'>
                                {(this.state.data.Modified.FirstName || this.state.data.Modified.LastName) ? '(' : ''}
                                {(this.state.data.Modified.FirstName) ? (this.state.data.Modified.FirstName + ' ') : null}
                                {(this.state.data.Modified.LastName) ? this.state.data.Modified.LastName : null}
                                {(this.state.data.Modified.FirstName || this.state.data.Modified.LastName) ? ')' : ''}
                            </div>
                        </>
                        : null
                }

            </div>
        )
    }

    renderForm = () => {
        return (
            <>{
                this.state.data.Form.Sections.map((obj, index) => {
                    return (
                        <React.Fragment key={'section' + index}>
                            {
                                obj.FormObjects.map((object, objectIndex) => {
                                    return (
                                        <React.Fragment key={'section' + index + 'formobj' + objectIndex}>
                                            <div className='content'>
                                                <div className='section-title'>{object.Header} :</div>
                                                <div className='ml-2 mt-3 section-content'>{this.renderFormObjectContent(object)}</div>
                                                {
                                                    (object.Footer && this.checkBrowser()) ?
                                                        <div className='mt-2'>
                                                            <textarea
                                                                className='form-control'
                                                                value={(object.Footer || '')}
                                                                disabled
                                                                style={{
                                                                    resize: 'none',
                                                                    border: 'none',
                                                                    overflow: 'hidden',
                                                                    backgroundColor: 'white'
                                                                }}
                                                            />
                                                        </div>
                                                        :
                                                        <div className='mt-2'>
                                                            {(object.Footer || '')}
                                                        </div>
                                                }

                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </React.Fragment>
                    )
                })
            } </>
        )
    }

    render() {
        if (!this.containerEl) {
            return null;
        }
        return ReactDOM.createPortal(
            (
                <>
                    {
                        (this.state.dataLoaded.every(x => x === true)) ?
                            <div className='p-2'>
                                <div className='' style={{ backgroundColor: 'white' }}>
                                    <div className='hide-print mb-4'>
                                        <button className='btn btn-outline-success' onClick={() => { this.externalWindow.print(); }}>
                                            Export PDF
                                        </button>
                                    </div>
                                    {this.renderDescription()}
                                    {this.renderForm()}
                                    {this.renderFooter()}
                                </div>
                            </div>
                            : null
                    }
                    <div className={"lds-ellipsis " + ((this.state.dataLoaded.every(x => x === true)) ? 'disabled' : '')}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={'loading-backdrop ' + ((this.state.dataLoaded.every(x => x === true)) ? 'disabled' : '')} />
                </>
            )
            , this.containerEl);
    }
}

export default MyWindowPortal;