import React, { Component } from 'react'

class DragandDrop extends Component {

    constructor(props) {
        super(props)
        this.state = {
            drag: false
        }
    }

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ drag: true })
        }
    }

    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--
        if (this.dragCounter === 0) {
            this.setState({ drag: false })
        }
    }

    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ drag: false })
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files)
            e.dataTransfer.clearData()
            this.dragCounter = 0
        }
    }

    render() {
        return (
            <div
                id='drag-wrapper'
                onDragEnter={this.handleDragIn}
                onDragLeave={this.handleDragOut}
                onDragOver={this.handleDrag}
                onDrop={this.handleDrop}
            >
                {this.state.drag &&
                    <div id='drag-inside-drag-event'>
                        <div id='drag-inside-text'>
                            <div>วางไฟล์เพื่ออัพโหลด</div>
                        </div>
                    </div>
                }
                {this.props.children}
            </div>
        )
    }
}
export default DragandDrop